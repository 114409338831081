import React, {useEffect, useState} from 'react';
import {
  Button,
  Col, Divider, Row,
} from 'antd';
import {
  maskCEP, maskCPForCNPJ, maskOnlyNumbers, maskPhone,
} from 'utils/masks';
import {useFieldArray} from 'react-hook-form';
import {FiTrash} from 'react-icons/fi';
import {useTheme} from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {SelectForm} from 'components/SelectForm';
import {requestClinicTypes} from 'services/clinics/requestClinicTypes';
import {IClinic} from 'services/clinics/types';
import {brazilStates} from 'hooks/useDoctor/data';
import {
  Container, Title, DetailsContainer, ButtonsContainer, CloseButton, SubmitButton,
} from './style';
import {InputForm} from '../../../components/InputForm';

interface IBankFormProps {
  control: any,
  errors: any,
  register: any,
  setValue: any,
  clinic: IClinic | undefined,
  getLocation: any,
}

export function DetailsForm({
                              control, errors, register, setValue, getLocation,
                            }: IBankFormProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [clinicTypes, setClinicTypes] = useState<any[]>([]);
  const [searchedUnimed, setSearchedUnimed] = useState<any[]>([]);
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'terminals',
  });

  // TODO - FIX THIS
  const searchUnimed = async (name: string) => {
    //   const {data} = await getSingularByName(name);
    //   setSearchedUnimed(data.map((unimed) => ({
    //     value: unimed.singular_id,
    //     label: unimed.name,
    //     code: unimed.unimed_code,
    //   })));
  };

  useEffect(() => {
    searchUnimed('');

    requestClinicTypes().then(({data: {data}}) => {
      setClinicTypes(data.map((type) => ({value: type.id, label: type.name})));
    });
  }, []);

  return (
    <Container>
      <DetailsContainer>
        <Row>
          <Title>Informações de registro</Title>
        </Row>
        <Divider style={{marginTop: 0}}/>
        <Row
          style={{marginTop: 20}}
          gutter={{
            lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
          }}
        >
          <Col span={6}>
            <SelectForm
              name="unimed_id"
              data={searchedUnimed}
              title="Singular"
              control={control}
              error={(errors.unimed_id && errors.unimed_id.message) as string}
              onSearchValue={searchUnimed}
              onChangeValue={(value: any) => {
                setValue('code', searchedUnimed.find((unimed) => unimed.value === value).code);
              }}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={4}>
            <InputForm
              name="code"
              title="Código Unimed"
              control={control}
              error={(errors.code && errors.code.message) as string}
              rest={{
                style: {borderRadius: 0},
                disabled: true,
              }}
            />
          </Col>
        </Row>
        <Row
          style={{marginTop: 40}}
          gutter={{
            lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
          }}
        >
          <Col span={4}>
            <InputForm
              name="cnpj_cpf"
              title="Documento"
              control={control}
              error={(errors.cnpj_cpf && errors.cnpj_cpf.message) as string}
              mask={maskCPForCNPJ}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={7}>
            <InputForm
              name="name"
              title="Razão social"
              control={control}
              error={(errors.name && errors.name.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={5}>
            <SelectForm
              name="type_id"
              title="Categoria"
              control={control}
              error={(errors.type_id && errors.type_id.message) as string}
              rest={{
                style: {width: '100%'},
              }}
              data={clinicTypes}
            />
          </Col>
        </Row>
        <Row
          style={{marginTop: 10}}
          gutter={{
            lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
          }}
        >
          <Col span={4}>
            <InputForm
              name="cep"
              title="CEP"
              control={control}
              error={(errors.cep && errors.cep.message) as string}
              mask={(value: string) => {
                if (maskOnlyNumbers(value).length === 8) getLocation(maskOnlyNumbers(value));

                return maskCEP(value);
              }}
              rest={{style: {borderRadius: 0}, maxLength: 9}}
            />
          </Col>
          <Col span={6}>
            <InputForm
              name="address"
              title="Logradouro"
              control={control}
              error={(errors.address && errors.address.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={4}>
            <InputForm
              name="addressNumber"
              title="Número"
              control={control}
              error={(errors.addressNumber && errors.addressNumber.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={5}>
            <InputForm
              name="neighborhood"
              title="Bairro"
              control={control}
              error={(errors.neighborhood && errors.neighborhood.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
        </Row>
        <Row
          style={{marginTop: 10}}
          gutter={{
            lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
          }}
        >
          <Col span={6}>
            <InputForm
              name="details"
              title="Complemento"
              control={control}
              error={(errors.details && errors.details.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={4}>
            <InputForm
              name="city"
              title="Cidade"
              control={control}
              error={(errors.city && errors.city.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={3}>
            <SelectForm
              name="state"
              title="Estado"
              control={control}
              data={brazilStates}
              error={(errors.state && errors.state.message) as string}
              rest={{style: {borderRadius: 0, width: '100%'}}}
            />
          </Col>
          <Col span={3}>
            <InputForm
              name="contact_phone"
              title="Fone"
              control={control}
              mask={maskPhone}
              error={(errors.contact_phone && errors.contact_phone.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
          <Col span={5}>
            <InputForm
              name="contact_mail"
              title="Email"
              control={control}
              error={(errors.contact_mail && errors.contact_mail.message) as string}
              rest={{style: {borderRadius: 0}}}
            />
          </Col>
        </Row>

        <Row style={{marginTop: 70}}>
          <Title>Terminais</Title>
        </Row>
        <Divider style={{marginTop: 0}}/>
        {fields.map((field, index) => (
          <Row
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
            key={field.id}
            style={{marginTop: 10}}
          >
            <Col span={3}>
              <InputForm
                name="pos_id"
                title="Número de série"
                control={control}
                error={(errors?.terminals
                  && errors.terminals[index]?.pos_id?.message) as string}
                rest={{style: {borderRadius: 0}}}
                {...register(`terminals.${index}.pos_id`)}
              />
            </Col>
            <Col span={5}>
              <InputForm
                name="pos_model"
                title="Modelo"
                control={control}
                error={(errors?.terminals
                  && errors.terminals[index]?.pos_model?.message) as string}
                rest={{style: {borderRadius: 0}}}
                {...register(`terminals.${index}.pos_model`)}
              />
            </Col>

            <Col style={{marginTop: 25}}>
              <FiTrash
                size={23}
                color={theme.palette.grey}
                onClick={() => {
                  remove(index);
                }}
              />
            </Col>
          </Row>
        ))}
        <Row justify="end" style={{paddingRight: '67.5%', marginTop: 30}}>
          <Button
            onClick={() => {
              append({});
            }}
            type="default"
            style={{borderRadius: 0}}
          >
            + Adicionar
          </Button>
        </Row>

        <ButtonsContainer>
          <CloseButton type="button" onClick={() => navigate('/painel/prestadores')}>Fechar</CloseButton>
          <SubmitButton type="submit">Salvar</SubmitButton>
        </ButtonsContainer>
      </DetailsContainer>
    </Container>
  );
}
