import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 15px 21px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const DetailsContainer = styled.div`
  padding: 24px;
  margin-top: 20px;
  

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ParamsContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  margin-left: 10px;
`;

export const LabelText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  color: red;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
`;
