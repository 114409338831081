import { createContext, useMemo, useState } from 'react';
import { requestGetPermissionById } from 'services/permissions/requestPermission';
import { getPermissionsByIndex } from 'services/permissions/requestPermissionByIndex';
import { postCreatePermission } from 'services/permissions/requestCreatePermission';
import { patchUpdatePermission } from 'services/permissions/requestUpdatePermission';
import { IPermission, ICreatePermissionDataRequest } from 'services/permissions/types';
import { IPermissionContextData, IPermissionProviderProps } from './types';

export const PermissionContext = createContext({} as IPermissionContextData);

export function PermissionProvider({ children }: IPermissionProviderProps) {
  const [allPermissions, setAllPermissions] = useState<IPermission[]>([]);
  const [countAllPermissions, setCountAllPermissions] = useState(0);

  const getAllPermissions = async (
    pageSize: number,
    page: number,
    searchPermissions: string,
  ) => {
    const { data: { data: { data, total } } } = await getPermissionsByIndex(
      pageSize,
      page,
      searchPermissions,
    );

    setAllPermissions(data);
    setCountAllPermissions(total);
  };

  const getPermissionById = async (id: string) => {
    const { data: { data } } = await requestGetPermissionById(id);

    return (data);
  };

  const createPermission = async (createPermissionData: ICreatePermissionDataRequest) => {
    const { data } = await postCreatePermission(createPermissionData);

    return data;
  };

  const updatePermission = async (
    id: string,
    createPermissionData: ICreatePermissionDataRequest,
  ) => {
    const { data } = await patchUpdatePermission(id, createPermissionData);

    return data;
  };

  const value = useMemo(() => ({
    allPermissions,
    countAllPermissions,
    getAllPermissions,
    getPermissionById,
    createPermission,
    updatePermission,
  }), [allPermissions, countAllPermissions]);

  return (
    <PermissionContext.Provider
      value={value}
    >
      {children}
    </PermissionContext.Provider>
  );
}
