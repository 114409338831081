import styled from 'styled-components';

export const Container = styled.button`
    background-color: ${({ theme }) => theme.palette.primary};
    border-radius: 2px;

    outline: none;
    border: none;
    font-size: 14px;
    line-height: 22px;

    display: flex;

    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.white};

    height: 32px;
    width: 10vw;
    min-width: 150px;

    font-family: ${({ theme }) => theme.fontFamily.body};

    cursor: pointer;
`;
