import { cloneElement } from 'react';
import { GuideProvider } from 'hooks/useGuide';
import { SettlementProvider } from 'hooks/useSettlement';
import { DoctorProvider } from '../useDoctor';
import { UserProvider } from '../useUsers';
import { AuthProvider } from '../useAuth';
import { ClinicProvider } from '../useClinic';
import { ThemeProvider } from '../useTheme';
import { SingularProvider } from '../useSingular';
import { ContextProviderProps, ProviderComposerProps } from './types';
import { TransferProvider } from '../useTransfer';
import { HolidayProvider } from '../useHoliday';
import { TissProvider } from '../useTiss/TissContext';
import { RoleProvider } from '../useRoles';
import { PermissionProvider } from '../usePermissions';

const providers = [
  ThemeProvider,
  AuthProvider,
  SingularProvider,
  ClinicProvider,
  DoctorProvider,
  UserProvider,
  GuideProvider,
  TransferProvider,
  HolidayProvider,
  SettlementProvider,
  TissProvider,
  RoleProvider,
  PermissionProvider,
];

const ProviderComposer = ({ contexts, children }: ProviderComposerProps) => contexts.reduce(
  (kids, Parent) => cloneElement(<Parent />, {
    children: kids,
  }),
  children,
);

function ContextProvider({ children }: ContextProviderProps) {
  return (
    <ProviderComposer
      contexts={providers}
    >
      {children}
    </ProviderComposer>
  );
}

export default ContextProvider;
