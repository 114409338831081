import styled from 'styled-components';
import {Pagination as PaginationRaw, Select} from 'antd';
import {SearchBar} from "../../components/SearchBar";

export const Container = styled.div`
  background-color: ${({theme}) => theme.palette.white};
`;

export const Pagination = styled(PaginationRaw)`
  margin-left: 15px;
`;

export const PaginationContainer = styled.div`
  display: flex;

  padding-top: 2rem;
  padding-right: 25px;

  align-items: center;

  width: 100%;
  justify-content: flex-end;
  background-color: ${({theme}) => theme.background};
`;

export const SearchContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 25px;

  padding-top: 15px;
  background-color: ${({theme}) => theme.palette.white};
`;

export const SearchContainerTwo = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 25px 20px;

  background-color: ${({theme}) => theme.palette.white};
`;

export const SearchSingular = styled(Select)`
  width: 100%;

  .ant-select-selector {
    border-radius: 0 !important;
  }

  .ant-select-clear {
    font-size: 24px;
    color: red;
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;

export const SearchByName = styled(SearchBar)`
  width: 100%;

  border-radius: 0;
  background-color: ${({ theme }) => theme.palette.white} !important;
`;

export const TitleSearch = styled.p`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

export const SearchBySpecialty = styled(Select)`
  width: 100%;

  .ant-select-selector {
    border-radius: 0 !important;
  }

  .ant-select-clear {
    font-size: 24px;
    color: red;
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;
