import styled from 'styled-components';
import { Pagination as PaginationRaw } from 'antd';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ContainerSearch = styled.div`
  width: 50%;

  display: flex;
  flex-direction: row;
  border-radius: 2px;
  gap: 10px;

  padding: 22px 25px 26px;
`;

export const TitleSearch = styled.p`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin: 0;
`
