import { yupResolver } from '@hookform/resolvers/yup';
import { Spin, Modal } from 'antd';
import { Header } from 'components/Header';
import dayjs from 'dayjs';
import { useDoctor } from 'hooks/useDoctor';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocationByCep } from 'services/brasilApi/requestCep';
import { ICreateDoctorDataRequest, IDoctors } from 'services/doctors/types';
import { maskCPF, maskPhone } from 'utils/masks';
import { Button as ComponentButton } from "../../components/Button/ProductionWindow";
import { useAuth } from '../../hooks/useAuth';
import { DetailsForm } from './components/DetailsForm/DetailsForm';
import { schema } from './schema';
import {
  BodyContainer,
  ButtonsContainer,
  CloseButton,
  Container,
  ContantModal,
  DivButtonModal,
  HeaderContainer,
  StyledTabs,
  SubmitButton,
} from './styles';

export function DoctorForm() {
  const { id } = useParams() as any;
  const { createDoctor } = useDoctor();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [tabSelected, setTabSelected] = useState('1');
  const [doctor, setDoctor] = useState<IDoctors>();
  const [loading, setLoading] = useState<boolean>(false);
  const { verifyPermission, user } = useAuth();
  const [permissionEdit, setPermissionEdit] = useState<boolean>(false);
  const [modalClosePage, setModalClosePage] = useState<boolean>(false);

  const items = [
    {
      key: '1',
      label: 'Detalhes cooperado',
    },
    {
      key: '2',
      label: 'Lista de Guias',
      disabled: id === 'new',
    },
  ];

  useEffect(() => {
    (async () => {
      const permission = await verifyPermission('doctor.edit', user);
      if (permission !== undefined) {
        setPermissionEdit(permission);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (doctor) {
      // Registros
      setValue('name', doctor.name);
      setValue('cpf', maskCPF(doctor.cpf));
      setValue('provider_code', doctor.provider_code);
      setValue('doctor_id', doctor.doctor_id);
      setValue('crm', doctor.crm);
      setValue('cnes', doctor.cnes);
      setValue('birth_date', dayjs(doctor.birth_date));
      setValue('gender', doctor.gender);
      setValue('unimed_id', doctor.unimed_id);
      setValue('unimed_code', doctor.unimed.unimed_code);
      setValue('provider_code', doctor.code);
      setValue('provider_guide_number', doctor.provider_guide_number);

      // Endereço
      setValue('city', doctor.address.city);
      setValue('neighborhood', doctor.address.neighborhood);
      setValue('state', doctor.address.state);
      setValue('details', doctor.address.details);
      setValue('number', doctor.address.number);
      setValue('street', doctor.address.street);
      setValue('zipcode', doctor.address.zipcode);

      //Account
      setValue('number', doctor.account.number);
      setValue('code', doctor.account.code);
      setValue('branch', doctor.account.branch);
      setValue('digit', doctor.account.digit);

      // Contacts
      setValue('email', doctor.email);
      setValue('primary_phone', maskPhone(doctor.primary_phone));
      setValue('secondary_phone', doctor.secondary_phone ? maskPhone(doctor.secondary_phone) : doctor.secondary_phone);

      // Machines
      setValue('is_machine_required', doctor.is_machine_required);
      doctor.machines.forEach((machine) => {
        if (machine.machine_model === 'A8') {
          setValue('model_A8UNIMED_quantity', machine.quantity);
        }
        if (machine.machine_model === 'D19') {
          setValue('model_D19UNIMED_quantity', machine.quantity);
        }
        if (machine.machine_model === 'P2') {
          setValue('model_P2UNIMED_quantity', machine.quantity);
        }
      });

      // Especialidades
      setValue('specialties', doctor.doctorSpecialties.map((doctorSpecialty) => ({
        specialtyName: doctorSpecialty.specialty_id,
        specialtyCBO: doctorSpecialty.specialty.code,
      })));
    } else {
      setValue('specialties', []);
    }
  }, [doctor]);
  
  useEffect(() => {
    if (errors) {
      if (Object.keys(errors).length > 0) {
        Modal.error({
          content: 'Erro. Tente Novamente!',
        });
      }
    }
  }, [errors]);

  const getLocation = async (zipcode: string) => {
    const { data } = await getLocationByCep(zipcode);

    setValue('street', data.street);
    setValue('neighborhood', data.neighborhood);
    setValue('state', data.state);
    setValue('city', data.city);
  };

  const submitForm = async (data: any) => {
    setLoading(true);

    const addressData = {
      city: data.city,
      neighborhood: data.neighborhood,
      state: data.state,
      details: data.details,
      number: data.number,
      street: data.street,
      zipcode: data.zipcode,
    };

    const accountBank = {
      number: data.account,
      code: data.bankCode,
      branch: data.agency,
      digit: data.digit,
    };

    const content: ICreateDoctorDataRequest = {
      name: data.name,
      provider_guide_number: data.provider_guide_number,
      birth_date: data.birth_date.toISOString().split('T')[0],
      cnes: data.cnes.toString(),
      cpf: data.cpf.replace(/[./-]/g, ''),
      crm: data.crm,
      email: data.email,
      gender: data.gender,
      singular_id: data.unimed_id,
      primary_phone: data.primary_phone,
      secondary_phone: data.secondary_phone,
      provider_code: data.provider_code,
      specialty_ids: [data.specialties],
      address: addressData,
      account: accountBank,
    };

    async function createOrUpdateDoctor(id: string, content: ICreateDoctorDataRequest) {
      try {
        const response = await createDoctor(content);
        return response.success;
      } catch (error) {
        Modal.error({
          content: 'Erro ao Criar ou Atualizar o Médico, Tente Novamente!',
        });
        return false;
      } finally {
        setLoading(false);
        Modal.success({
          content: 'Médico Cadastrado com Sucesso!',
          onOk: () => {
            navigate('/painel/medicos');
          }
        });
      }
    }

    if (!await createOrUpdateDoctor(id, content)) {
      return;
    }

    Modal.success({
      content: 'Médico Cadastrado com Sucesso!',
      onOk: () => {
        navigate('/painel/medicos');
      }
    });
  };

  const handleTabChange = (key: any) => {
    setTabSelected(key);
  };

  const renderForm = () => {
    if (tabSelected === '1') {
      return (
        <DetailsForm
          control={control}
          errors={errors}
          setValue={setValue}
          getLocation={getLocation}
          id={id}
        />
      );
    } else if (tabSelected === '2') {
      return (
        <></>
      );
    };
  };

  return (
    <Container>
      <HeaderContainer>
        <Header
          title="Médicos cooperados"
          subtitle="Relação de clínicas e laboratórios da rede credenciada por singular"
        />
      </HeaderContainer>
      <StyledTabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
      <Spin spinning={loading}>
        <BodyContainer onSubmitCapture={handleSubmit(submitForm)}>
          {renderForm()}
          {(tabSelected === '1' || tabSelected === '2') && (
            <ButtonsContainer>
              <CloseButton
                onClick={() => setModalClosePage(true)}
                type="button"
              >
                Fechar
              </CloseButton>
              <form onSubmit={handleSubmit(submitForm)}>
                <SubmitButton type="submit">Salvar</SubmitButton>
              </form>
            </ButtonsContainer>
          )}
          <Modal
            title="Seus dados serão perdido!"
            open={modalClosePage}
            onOk={() => {
              navigate("/painel/medicos");
              navigate("/painel/singulares");
            }}
            onCancel={() => setModalClosePage(false)}
            okText="Ok"
            cancelText="Cancelar"
          >
            <p>Deseja Realmente Fechar?</p>
          </Modal>
        </BodyContainer>
      </Spin>
    </Container>
  );
}
