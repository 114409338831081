import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ILoginData } from "../../services/auth/types";
import { FirstAcessForm } from "./FirstAcessForm";
import {
  Container,
  FormContainer,
  Text,
  LeftContainer,
  RightContainer,
  FirstAcessFormContainer,
  Logo,
  LogoWhite,
  Title,
} from "./style";
import { Spin } from "antd";
import { useState } from "react";
import { INewPassword } from "./FirstAcessForm/types";

export function FirstAccess() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { signed, firstAcess, newPassword } = useAuth();

  const handleSubmitForm = async (data: INewPassword) => {
    const password = data.newPassword || '';

    const sendData = {
      session: firstAcess.session,
      username: firstAcess.username,
      password: password,
    }
    await newPassword(sendData);
  };

  if (signed) {
    navigate("/");
  }

  return (
    <Container>
      <FormContainer>
        <LeftContainer>
          <Logo />
          <Spin spinning={loading}>
            <FirstAcessFormContainer>
              <Title>Redefinição de Senha</Title>
              <Text>Para garantir a segurança da sua conta, pedimos que você redefina sua senha pela primeira vez.</Text>
              <FirstAcessForm handleSubmitForm={handleSubmitForm} />
            </FirstAcessFormContainer>
          </Spin>
        </LeftContainer>
        <RightContainer>
          <LogoWhite />
        </RightContainer>
      </FormContainer>
    </Container>
  );
}
