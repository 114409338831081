/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Header } from 'components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { message, Spin } from 'antd';
import { useClinic } from 'hooks/useClinic';
import { maskCEP, maskCPForCNPJ, maskPhone } from 'utils/masks';
import { getLocationByCep } from 'services/brasilApi/requestCep';
import { IClinic } from 'services/clinics/types';
import {
  BodyContainer,
  Container,
  HeaderContainer,
  StyledTabs,
} from './styles';
import { DoctorsForm } from './DoctorsForm';
import { schema } from './schema';
import { DetailsForm } from './DetailsForm';

export function ProviderForm() {
  const { id } = useParams() as any;
  const {
    getClinicById, createClinic, updateClinic,
  } = useClinic();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
    setValue,
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const items = [
    {
      key: '1',
      label: 'Detalhes prestador',
    },
    {
      key: '2',
      label: 'Médicos cooperados',
      disabled: id === 'new',
    },
  ];

  const [tabSelected, setTabSelected] = useState('1');
  const [clinic, setClinic] = useState<IClinic>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getClinicById(id).then((value) => setClinic(value));
  }, []);

  useEffect(() => {
    if (clinic) {
      // Endereço e registros
      setValue('cnpj_cpf', maskCPForCNPJ(clinic.cnpj_cpf));
      setValue('contact_mail', clinic.contact_mail);
      setValue('contact_phone', maskPhone(clinic.contact_phone || ''));
      setValue('name', clinic.name);
      setValue('type_id', clinic.type_id);
      setValue('unimed_id', clinic.unimed_id);
      setValue('code', clinic.unimed.unimed_code);

      // Address
      setValue('city', clinic.address.city);
      setValue('neighborhood', clinic.address.neighborhood);
      setValue('state', clinic.address.state);
      setValue('addressNumber', clinic.address.number);
      setValue('address', clinic.address.street);
      setValue('cep', maskCEP(clinic.address.zipcode));

      // Contatos
      setValue('terminals', clinic.terminals.map((terminal) => ({
        pos_id: terminal.pos_id,
        pos_model: terminal.pos_model,
      })));
    }
  }, [clinic]);

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((errorField) => {
        message.warning(errors[errorField]?.message?.toString());
      });
    }
  }, [errors]);

  const getLocation = async (cep: string) => {
    const { data } = await getLocationByCep(cep);

    setValue('address', data.street);
    setValue('neighborhood', data.neighborhood);
    setValue('state', data.state);
    setValue('city', data.city);
  };

  const submitForm = async (data: any) => {
    setLoading(true);

    const content = {
      cbo: data.cbo,
      cnpj_cpf: data.cnpj_cpf,
      contact_mail: data.contact_mail,
      contact_phone: data.contact_phone,
      name: data.name,
      terminals: data.terminals.map((terminal: any) => ({
        pos_id: terminal.pos_id,
        pos_model: terminal.pos_model,
      })),
      type_id: data.type_id,
      unimed_id: data.unimed_id,
      address: {
        city: data.city,
        neighborhood: data.neighborhood,
        state: data.state,
        number: data.addressNumber,
        street: data.address,
        zipcode: data.cep,
      },
    };

    if (id === 'new') {
      const response = await createClinic({
        ...content,
        doctors_id: [],
      }).finally(() => setLoading(false));

      if (!response.success) {
        return;
      }
    } else {
      const response = await updateClinic(id, content)
        .finally(() => setLoading(false));

      if (!response.success) {
        return;
      }
    }

    navigate('/painel/prestadores');
  };

  return (
    <Container>
      <HeaderContainer>
        <Header
          title="Clínicas, laboratórios e centros de diagnóstico"
          subtitle="Relação de clínicas e laboratórios da rede credenciada por singular"
        />
      </HeaderContainer>
      <StyledTabs defaultActiveKey="1" items={items} onChange={setTabSelected} />

      <Spin spinning={loading} size="default">
        <BodyContainer onSubmitCapture={handleSubmit(submitForm)}>
          {tabSelected === '1' && (
            <DetailsForm
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              clinic={clinic}
              getLocation={getLocation}
            />
          )}
          {tabSelected === '2' && (
            <DoctorsForm
              control={control}
              errors={errors}
              register={register}
              clinicId={clinic?.id}
            />
          )}
        </BodyContainer>
      </Spin>
    </Container>
  );
}
