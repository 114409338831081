import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup
    .string()
    .trim()
    .min(3, 'Digite um Nome válido')
    .max(255, 'Digite um Nome válido')
    .required('Nome é obrigatório'),
  email: Yup.string()
    .required('Email é obrigatório')
    .email('Digite um Email válido')
    .nullable(),
  username: Yup.string().required('CPF é obrigatório').matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
  profile: Yup.string().required('Perfil é obrigatório').oneOf(['WRITE', 'READ'], 'Perfil inválido'),
})
