import { Tabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  padding-right: 24px;
  padding-bottom: 40px;

  background-color: ${({ theme }) => theme.palette.white};
`;

export const StyledTabs = styled(Tabs)`
  padding-left: 24px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const TitleName = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.3px;

  color: ${({ theme }) => theme.palette.primary};
`;

export const BodyContainer = styled.form`
  background-color: ${({ theme }) => theme.background};
  position: relative;
`;

export const ButtonsContainer = styled.div`
  position: sticky;
  bottom: 0;

  background-color: ${({ theme }) => theme.background};

  display: flex;

  width: 100%;
  z-index: 1;

  justify-content: flex-end;

  padding: 10px 20px;

  gap: 10px;
`;

export const CloseButton = styled.button`
  border: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: solid;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0px;
  color: black;
  padding: 9px 40px;

  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const SubmitButton = styled.button`
  border: 0px;
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 0px;
  color: white;
  padding: 10px 40px;

  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const ContantModal = styled.div`
  width: 100%;
  height: 32px;
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: end;
`;

export const DivButtonModal = styled.div`
  width: 40%;
  height: 100%;
  margin-left: 10px;
`;