import { useContext } from 'react';
import { ThemeContext, ThemeProvider } from './ThemeContext';

function useTheme() {
  const theme = useContext(ThemeContext);

  return theme;
}

export { useTheme, ThemeProvider };
