import api from '../api';
import { ReturnUsers } from './types';

export async function getUsers(
  // limit?: number,
  // token?: string | null,
  name?: string,
) {
  const response = await api.get<ReturnUsers>('/api/v1/auth/backoffice', {
    params: {
      // limit,
      // token,
      name
    }
  })
  return response;
}
