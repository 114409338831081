import styled, { css } from 'styled-components';
import { Button, Divider as RawDivider, Pagination as PaginationRaw } from 'antd';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { ICircleStyle, IStyleProps } from './types';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  min-width: 80%;
  padding: 0 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 20px 0px;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Label = styled.div`
  margin-right: 12px;
`;

export const TableLabel = styled.p<IStyleProps>`
  text-align: left;

  ${({ marginLeft }) => !!marginLeft
    && css`
      margin-left: ${marginLeft}rem;
    `}

`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 40px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.tr`
  
`;

export const Content = styled.div<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ centralize }) => centralize
    && css`
      justify-content: center;
    `}
`;

export const TableHeadCell = styled.th<IStyleProps>`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: 400;

  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text};

  padding: 11px 16px;

  ${({ disableLeftPadding }) => disableLeftPadding
    && css`
      padding-left: 0px;
    `}

  ${({ disablePadding }) => disablePadding
    && css`
      padding: 0;
    `
}

  ${({ width }) => width
    && css`
      width: ${width}px;
    `}
`;

export const Divider = styled(RawDivider).attrs({
  type: 'vertical',
})<IStyleProps>`
  height: 28px;
  margin-right: 16px;
  margin-left: 0px;

  ${({ color }) => color
    && css`
      background-color: ${color};
    `}

  ${({ height }) => height
  && css`
    height: ${height}px;
  `}

  ${({ alternativeLayout }) => alternativeLayout && css`
    height: calc(100% - 50px);

    margin: auto 0;
    margin-left: 2rem;
    margin-right: 2rem;
  `}
`;

export const TableRow = styled.tr`
  
`;

export const TableCell = styled.td<IStyleProps>`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: 400;

  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text};

  padding-bottom: 5px;

  ${({ width }) => width
    && css`
      width: ${width}px;
    `}

  ${({ disableLeftPadding }) => disableLeftPadding
    && css`
      padding-left: 0px;
    `}

  ${({ disablePadding }) => disablePadding
    && css`
      padding: 0px;
    `}
`;

export const ButtonAntd = styled(Button)`
  border-radius: 2px !important;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary} !important;
    color: ${({ theme }) => theme.palette.primary} !important;
  }
`;

export const ChevronDown = styled(BiChevronDown)<IStyleProps>`
  cursor: pointer;

  margin-left: 12px;
  margin-right: 2rem;
`;

export const ChevronUp = styled(BiChevronUp)`
  cursor: pointer;
`;

export const CellContent = styled.div<IStyleProps>`
  background-color: ${({ theme }) => theme.palette.white};
  
  height: 45px;

  display: flex;
  justify-items: center;
  align-items: center;

  padding: 11px 0px; 

  ${({ disablePadding }) => !disablePadding
    && css`
      padding: 11px 16px; 
    `}

  ${({ width }) => !!width
    && css`
      width: ${width}px;
    `}

  ${({ flexEnd }) => flexEnd
    && css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      padding-right: 16px;
    `}
`;

export const LabelTb = styled.p<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ centralize }) => centralize && css`
    margin: auto;
  `}
  
  ${({ paddingLeft }) => paddingLeft && css`
    padding-left: ${paddingLeft}px;
  `}

  ${({ bold }) => bold
    && css`
      font-weight: bold;
    `}
`;

export const Circle = styled.div<ICircleStyle>`
  height: 6px;
  width: 6px;

  border-radius: 100%;
  margin-right: 8px;

  ${({active, theme, color}) => (active
    ? css`
      background-color: ${color};
    `
    : css`
      background-color: ${theme.palette.red};
    `)
  }
`;

export const SelectedCollapsedCell = styled.td`
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Typography = styled.p<IStyleProps>`
  color: ${({ theme }) => theme.palette.primary};
  margin: 0px;
  width: 16rem;

  ${({ color }) => color
    && css`
      color: ${color};
    `}

  ${({ width }) => width
    && css`
      width: ${width}rem;
    `}

  ${({ fontSize }) => fontSize
    && css`
      font-size: ${fontSize}px;
    `}

  ${({ lineHeight }) => lineHeight
    && css`
      line-height: ${lineHeight}px;
    `}

  ${({ marginTop }) => marginTop
    && css`
      margin-top: ${marginTop}px;
    `}

  ${({ fontWeight }) => fontWeight
    && css`
      font-weight: ${fontWeight};
    `}

  ${({ fontFamily }) => fontFamily
    && css`
      font-family: ${fontFamily};
    `}
`;

export const SelectedCol = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;

  ${({ centralize }) => centralize
    && css`
      align-items: center;
    `}

  ${({ width }) => !!width
  && css`
    width: ${width}px;
  `}

  ${({ paddingLeft }) => paddingLeft
    && css`
      padding-left: ${paddingLeft}px;
    `}

  ${({ marginLeft }) => marginLeft
    && css`
      margin-left: ${marginLeft}rem;
    `}


  ${({ marginTop }) => !!marginTop
    && css`
      margin-top: ${marginTop}px;
    `}
`;

export const SelectedRow = styled.div<IStyleProps>`
  display: flex;
  flex-direction: row;

  ${({ centralize }) => centralize && css`
    align-items: center;
  `}

  ${({ width }) => width
    && css`
      width: ${width}rem;
    `}

  ${({ marginTop }) => !!marginTop
    && css`
      margin-top: ${marginTop}px;
  `}

  ${({ spaceBetween }) => spaceBetween
    && css`
      justify-content: space-between;
    `}
`;

export const SelectedContainer = styled.td`
  padding-bottom: 5px;
`;

export const SelectedRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  background-color: ${({ theme }) => theme.palette.white};

  height: 172px;
  width: 100%;
`;

export const Code = styled.p`
  font-weight: bold;

  font-size: 16px;

  margin: 0;
`;

export const SelectedTitle = styled.p<IStyleProps>`
  margin: 0;

  font-size: 12px;
  line-height: 22px;

  color: ${({ theme }) => theme.palette.lightGray};

  font-weight: 300;

  ${({ marginTop }) => marginTop && css`
    margin-top: ${marginTop}px;
  `}
`;

export const SelectedLabel = styled.p`
  margin: 0;
  font-weight: bold;

  font-size: 16px;
`;

export const Address = styled.div`
  font-size: 14px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.7);
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: 400;
`;

export const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 3rem;

  margin-left: 1rem;
`;

export const CarouselContainer = styled.div`
  width: 30rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 15rem;
  margin-top: 4px;
`;

export const Badge = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.primary};

  width: max-content;
  height: 17px;

  padding-left: 5px;
  padding-right: 5px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px;

  font-size: 12px;
  line-height: 16px;
`;

export const Pagination = styled(PaginationRaw)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
`;
