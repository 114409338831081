/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {DeleteFilled, EditFilled, LeftOutlined, PlusOutlined, RightOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {Col, Popconfirm} from 'antd';
import {IProps} from './types';
import {Button} from '../../../components/Button';
import {
  Container, Header, Title, Items, Item, Table, TableHead, TableHeadCell, Content, TableLabel,
  Divider, TableCell, TableRow, CellContent, LabelTb, ChevronDown, SelectedContainer, SelectedCol,
  ChevronUp, SelectedRowContainer, SelectedTitle, SelectedRow, SelectedLabel, Address, Typography,
  Arrows, CarouselContainer, Card, ButtonAntd, Select, TitleSearch,
} from './styles';
import {useTheme} from '../../../hooks/useTheme';

export function TableContainer({
                                 title,
                                 clinics,
                                 onChangeType,
                                 onClickRemove,
                               }: IProps) {
  const navigate = useNavigate();
  const [currentSelected, setCurrentSelected] = useState<number>(-1);
  const {theme} = useTheme();
  const ref = useRef<any>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [clinicTypes, setClinicTypes] = useState<any[]>([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 0},
      items: 1,
    },
  };

  function navigationCarrousel(page: number, length: number) {
    const nextPrevPage = currentPage + (page);

    if (nextPrevPage >= length) {
      ref!.current!.goToSlide(0);
      setCurrentPage(0);
    } else if (nextPrevPage < 0) {
      ref!.current!.goToSlide(length - 1);
      setCurrentPage(length - 1);
    } else {
      setCurrentPage(currentPage + (page));
      ref!.current!.goToSlide(currentPage + (page));
    }
  }

  function handleSelect(index: number) {
    setCurrentSelected(index);
  }

  useEffect(() => {
    // requestClinicTypes().then(({ data: { data } }) => {
    //   setClinicTypes(data.map((type) => ({ value: type.id, label: type.name })));
    // });
  }, []);

  return (
    <Container>
      <Header>
        <Title>
          {title}
        </Title>
        <Items>
          <TitleSearch>Categoria</TitleSearch>
          <Item>
            <Select
              options={clinicTypes}
              placeholder="Selecione categorias de prestador"
              onChange={onChangeType}
              showSearch
            />
          </Item>
          <Button onClick={() => {
            navigate('/painel/prestadores/new');
          }}>
            <PlusOutlined style={{marginRight: 10}}/>
            Nova prestadora
          </Button>
        </Items>
      </Header>
      <Table cellPadding={0} cellSpacing={0}>
        <TableHead>
          <TableHeadCell style={{paddingLeft: 60}} width={220}>
            <Content>
              <Divider/>
              <TableLabel>
                Nome Prestador (PJ)
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={95}>
            <Content>
              <Divider/>
              <TableLabel>
                CNPJ
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={185}>
            <Content>
              <Divider/>
              <TableLabel>
                Singular
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={113}>
            <Content>
              <Divider/>
              <TableLabel>
                Cod
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={162}>
            <Content>
              <Divider/>
              <TableLabel>
                Ans
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={106}>
            <Content>
              <Divider/>
              <TableLabel>
                Terminais
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={106}>
            <Content>
              <Divider/>
              <TableLabel>
                Biometria
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={106}>
            <Content>
              <Divider/>
              <TableLabel>
                Ações
              </TableLabel>
            </Content>
          </TableHeadCell>
        </TableHead>
        {
          clinics!.map((item, index) => (
            <TableRow>
              {
                currentSelected === index
                  ? (
                    <SelectedContainer colSpan={9}>
                      <SelectedRowContainer>
                        <SelectedCol centralize marginTop={22} width={5}>
                          <ChevronUp
                            onClick={() => handleSelect(-1)}
                            size={35}
                            color={theme.palette.primary}
                          />
                        </SelectedCol>
                        <SelectedCol marginLeft={1} width={20}>
                          <Typography
                            color={theme.palette.primary}
                            lineHeight={18}
                            marginTop={22}
                            fontSize={18}
                            fontWeight={500}
                            width={16}
                            style={{minHeight: 36}}
                          >
                            {item.name}
                          </Typography>
                          <SelectedRow
                            marginTop={16}
                          >
                            <Address>
                              {`${item.address.street}, ${item.address.number}`}
                            </Address>
                          </SelectedRow>
                          <SelectedRow>
                            <Address>
                              {item.address.city}
                            </Address>
                          </SelectedRow>
                          <SelectedRow
                            marginTop={9}
                          >
                            <SelectedCol>
                              <SelectedLabel>
                                {item.contact_mail}
                              </SelectedLabel>
                              <SelectedLabel>
                                {item.contact_phone}
                              </SelectedLabel>
                            </SelectedCol>
                          </SelectedRow>
                        </SelectedCol>
                        <SelectedCol marginLeft={3} width={20}>
                          <SelectedCol marginTop={22}>
                            <SelectedTitle>
                              CNPJ
                            </SelectedTitle>
                            <SelectedLabel>
                              {item.cnpj_cpf}
                            </SelectedLabel>
                          </SelectedCol>
                          <SelectedCol marginTop={16}>
                            <SelectedTitle>
                              SINGULAR
                            </SelectedTitle>
                            <Address>
                              {item.unimed.name}
                            </Address>
                            <Address>
                              Cod Unimed:
                              {item.unimed.unimed_code}
                            </Address>
                          </SelectedCol>
                          <SelectedRow marginTop={9}>
                            <Address>
                              TERMINAIS:
                              {'  '}
                            </Address>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color={theme.palette.primary}
                              lineHeight={14}
                              width={3}
                            >
                              {item.terminals.length}
                            </Typography>
                            <Address>
                              Biometria:
                            </Address>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color={theme.palette.primary}
                              lineHeight={14}
                              width={5}
                            >
                              {item.unimed.enabled_biometry ? 'Ativo' : 'Inativo'}
                            </Typography>
                          </SelectedRow>
                        </SelectedCol>
                        <Divider alternativeLayout/>
                        <SelectedCol marginTop={22} marginLeft={1.5} width={15}>
                          <SelectedRow>
                            <SelectedTitle>
                              MÉDICOS
                              {' '}
                              {`(${item.clinicsDoctors.length})`}
                            </SelectedTitle>
                            <Arrows>
                              <LeftOutlined onClick={() => navigationCarrousel(-1, item.clinicsDoctors.length)}
                                            style={{
                                              color: currentPage === 0 ? theme.palette.lightGray : theme.palette.text,
                                              cursor: 'pointer'
                                            }}/>
                              <RightOutlined onClick={() => navigationCarrousel(+1, item.clinicsDoctors.length)}
                                             style={{
                                               color: currentPage === item.clinicsDoctors.length - 1 ? theme.palette.lightGray : theme.palette.text,
                                               cursor: 'pointer'
                                             }}/>
                            </Arrows>
                          </SelectedRow>
                          <CarouselContainer style={{width: '15vw'}}>
                            <CarouselMulti
                              responsive={responsive}
                              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                              ref={ref as any}
                              autoPlay={false}
                              draggable
                            >
                              {
                                item.clinicsDoctors.map((current, i) => (
                                  <Card>
                                    {/* {current.doctor.isCoperado && <Badge style={{ width: 70 }}>Cooperado</Badge>} */}
                                    <Typography fontFamily={theme.fontFamily.roboto} fontWeight={500}
                                                marginTop={5} color="rgba(0, 0, 0, 0.8)" fontSize={14}
                                                lineHeight={22}>{current.doctor.name}</Typography>
                                    <SelectedRow>
                                      {/* <Typography fontFamily={theme.fontFamily.roboto} style={{ width: 'auto' }} color="rgba(0, 0, 0, 0.8)" fontSize={14} lineHeight={22}>{current.speciality}</Typography> */}
                                      <Typography fontFamily={theme.fontFamily.roboto}
                                                  style={{width: 'auto', marginLeft: 5, marginRight: 5}}
                                                  color="rgba(0, 0, 0, 0.8)" fontSize={14} lineHeight={22}>
                                        {/* - */}
                                      </Typography>
                                      {/* <Typography fontFamily={theme.fontFamily.roboto} style={{ width: 'auto' }} color="rgba(0, 0, 0, 0.8)" fontSize={14} lineHeight={22}>{current.doctor.crm}</Typography> */}
                                    </SelectedRow>
                                  </Card>
                                ))
                              }
                            </CarouselMulti>
                          </CarouselContainer>
                        </SelectedCol>
                        <SelectedCol marginLeft={2} marginTop={22}
                                     style={{marginLeft: 'auto', marginRight: '16px'}}>
                          <ButtonAntd onClick={() => {
                            navigate(`/painel/prestadores/${item.id}`);
                          }}>
                            <EditFilled/>
                          </ButtonAntd>
                          {
                            // permissionEdit
                            // && (
                            <Popconfirm
                              title="Remover"
                              description="Deseja realmente remover?"
                              onConfirm={() => onClickRemove(item.id)}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <ButtonAntd>
                                <DeleteFilled/>
                              </ButtonAntd>
                            </Popconfirm>
                            // )
                          }
                        </SelectedCol>
                      </SelectedRowContainer>
                    </SelectedContainer>
                  )
                  : (
                    <>
                      <TableCell disableLeftPadding width={8}>
                        <CellContent disablePadding>
                          <Col>
                            <ChevronDown
                              onClick={() => handleSelect(index)}
                              size={35}
                              color={theme.palette.grey}
                            />
                          </Col>
                          <Col>
                            <LabelTb>
                              {item.name}
                            </LabelTb>
                          </Col>
                        </CellContent>
                      </TableCell>
                      <TableCell width={225}>
                        <CellContent>
                          <LabelTb bold paddingLeft={16}>
                            {item.cnpj_cpf}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={95}>
                        <CellContent>
                          <LabelTb paddingLeft={16}>
                            {item.unimed.name}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={185}>
                        <CellContent>
                          <LabelTb paddingLeft={16}>
                            {item.unimed.unimed_code}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={113}>
                        <CellContent>
                          <LabelTb paddingLeft={16}>
                            {item.unimed.ans_code}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={162}>
                        <CellContent>
                          <LabelTb paddingLeft={16}>
                            {item.terminals.length}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={106}>
                        <CellContent>
                          <LabelTb paddingLeft={16}>
                            {item.unimed.enabled_biometry ? 'Ativo' : 'Inativo'}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={106}>
                        <CellContent flexEnd>
                          <ButtonAntd onClick={() => {
                            navigate(`/painel/prestadores/${item.id}`);
                          }}>
                            <EditFilled/>
                          </ButtonAntd>
                          {
                            // permissionEdit
                            // && (
                            <Popconfirm
                              title="Remover"
                              description="Deseja realmente remover?"
                              onConfirm={() => onClickRemove(item.id)}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <ButtonAntd>
                                <DeleteFilled/>
                              </ButtonAntd>
                            </Popconfirm>
                            // )
                          }
                        </CellContent>
                      </TableCell>
                    </>
                  )
              }
            </TableRow>
          ))
        }
      </Table>
    </Container>
  );
}
