import styled from 'styled-components';
import { Input as InputRaw } from 'antd';

export const Container = styled.div`
  width: 100%;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.red};

  margin: 3px 0;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.grey};
  
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 10pt;

  margin: 3px 0;
`;

export const Input = styled(InputRaw)`
  .ant-input {
    border-radius: 0px;
  }

  .ant-input-group-addon {
    border-radius: 0px;
  }
`;
