import { Select } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 15px 21px;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.grey};
  
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 10pt;

  margin: 3px 0;
`;

export const BankContainer = styled.div`
  padding: 24px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const DoctorsContainer = styled.div`
  margin-top: 20px;
`;

export const SubmitButton = styled.button`
  border: 0px;
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 0px;
  color: white;
  padding: 10px 40px;

  cursor: pointer;

  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const SelectStyled = styled(Select)`
  .ant-select-selector {
    border-radius: 0px;
  }
`;
