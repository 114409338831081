import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.h1`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: bold;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 40pt;
`;
