const filterCurrencyAmountInput = (text: string) => {
  let newText = text.replace(/^0+(?!$)/, '').replace('.', '').padStart(5, '0');

  if (newText.length > 5) {
    newText = newText.substring(0, 5);
  }

  const value = `${newText.substr(0, newText.length - 2)}.${newText.substr(-2)}`.replace(/^0+(?!$)/, '');

  return value.substring(0, 1) === '.' ? `0${value}` : value;
};

export { filterCurrencyAmountInput };
