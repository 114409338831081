import { DatePicker } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'dayjs/locale/pt-br';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import dayjs from 'dayjs';
import { IPropsInputForm } from './types';
import {
  Container, Error, Title,
} from './style';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export function DatePickerForm({
  control,
  name,
  error,
  title,
  rest,
}: IPropsInputForm) {
  return (
    <Container>
      <Controller
        control={control}
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <>
            <Title>{title}</Title>
            <DatePicker
              value={value}
              onChange={onChange}
              locale={locale}
              {...rest}
            />
          </>
        )}
        name={name}
      />
      {error && <Error>{ error }</Error>}
    </Container>
  );
}
