import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup
    .string()
    .trim()
    .min(3, 'Digite uma Razão social válida')
    .max(255, 'Digite uma Razão social válida')
    .required('Razão social é obrigatório'),
  type_id: Yup
    .string()
    .uuid()
    .required('Categoria é obrigatório'),
  cnpj_cpf: Yup
    .string()
    .trim()
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/g, 'Digite um Documento válido') // CPF or CNPJ
    .required('Documento é obrigatório'),
  unimed_id: Yup
    .string()
    .uuid()
    .required('Singular é obrigatório'),
  contact_mail: Yup
    .string()
    .trim()
    .email('Digite um Email válido')
    .required('Email é obrigatório'),
  contact_phone: Yup
    .string()
    .trim()
    .min(3, 'Digite um Fone válido')
    .max(20, 'Digite um Fone válido')
    .required('Fone é obrigatório'),
  terminals: Yup.array(
    Yup.object().shape({
      pos_id: Yup
        .string()
        .trim()
        .max(255, 'Digite um Número de Serie válido')
        .required('Número de Serie é obrigatório'),
      pos_model: Yup
        .string()
        .trim()
        .max(255, 'Digite um Modelo válido')
        .required('Modelo é obrigatório'),
    }),
  ),

  // Address
  city: Yup
    .string()
    .trim()
    .min(3, 'Digite uma Cidade válida')
    .max(255, 'Digite uma Cidade válida')
    .required('Cidade é obrigatório'),
  details: Yup
    .string()
    .trim()
    .max(255, 'Digite um Complemento válido')
    .optional(),
  neighborhood: Yup
    .string()
    .trim()
    .min(3, 'Digite um Bairro válido')
    .max(255, 'Digite um Bairro válido')
    .required('Bairro é obrigatório'),
  addressNumber: Yup
    .string()
    .trim()
    .max(10, 'Digite um Número válido')
    .required('Número é obrigatório'),
  state: Yup
    .string()
    .trim()
    .length(2, 'Digite um Estado válido')
    .required('Estado é obrigatório'),
  address: Yup
    .string()
    .trim()
    .min(3, 'Digite um Logradouro válido')
    .max(255, 'Digite um Logradouro válido')
    .required('Logradouro é obrigatório'),
  cep: Yup
    .string()
    .trim()
    .matches(/^[0-9]{5}-[0-9]{3}$/g, 'Digite um CEP válido')
    .required('CEP é obrigatório'),
});
