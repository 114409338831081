import {Col, Divider, Row} from "antd";
import {GeneralContainer, Title} from "../../style";
import {InputForm} from "../../../../../components/InputForm";
import {maskCEP, maskCNPJ, maskOnlyNumbers} from "../../../../../utils/masks";
import {SelectForm} from "../../../../../components/SelectForm";
import {brazilStates} from "../../DetailsForm/data";
import {IFormSingularProps} from "../../types";
import {listAllErp} from "../../../../../utils/singularHelpers";
import { useState } from "react";

export function GeneralContainerSingular({control, errors, getLocation}: IFormSingularProps) {
  const [valueCep, setValueCep] = useState('');
  
  return (
    <GeneralContainer>
      <Row>
        <Title>Dados Cadastrais</Title>
      </Row>
      <Divider style={{marginTop: 0}}/>
      <Row
        style={{marginTop: 20}}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={5}>
          <InputForm
            name="name"
            title="Nome da Unimed"
            control={control}
            error={(errors.name && errors.name.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={5}>
          <InputForm
            name="code"
            title="Código Unimed"
            control={control}
            error={(errors.code && errors.code.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={5}>
          <InputForm
            name="codeAns"
            title="Código ANS"
            control={control}
            error={(errors.codeAns && errors.codeAns.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={5}>
          <InputForm
            name="cnpj"
            title="CNPJ"
            control={control}
            mask={maskCNPJ}
            error={(errors.cnpj && errors.cnpj.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
      </Row>
      <Row
        style={{marginTop: 30}}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={5}>
          <SelectForm
            name="erpId"
            title="ERP"
            control={control}
            data={listAllErp()}
            error={(errors.erpId && errors.erpId.message) as string}
            rest={{style: {borderRadius: 0, width: '100%'}}}
          />
        </Col>
        <Col span={5}>
          <InputForm
            name="cep"
            title="CEP"
            control={control}
            error={(errors.cep && errors.cep.message) as string}
            mask={(value: string) => {
              if (maskOnlyNumbers(value).length === 8) getLocation(maskOnlyNumbers(value));
              setValueCep(maskCEP(value));
              return maskCEP(value);
            }}
            rest={{style: {borderRadius: 0}, maxLength: 9}}
          />
        </Col>
        <Col span={5}>
          <SelectForm
            name="state"
            title="Estado"
            control={control}
            data={brazilStates}
            error={(errors.state && errors.state.message) as string}
            rest={{
              style: {borderRadius: 0, width: '100%'},
              disabled: valueCep.length >= 8 ? false : true
            }}
          />
        </Col>
        <Col span={5}>
          <InputForm
            name="city"
            title="Cidade"
            control={control}
            error={(errors.city && errors.city.message) as string}
            rest={{
              style: {borderRadius: 0},
              disabled: valueCep.length >= 8 ? false : true
            }}
          />
        </Col>

      </Row>
      <Row
        style={{marginTop: 30}}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={10}>
          <InputForm
            name="address"
            title="Logradouro"
            control={control}
            error={(errors.address && errors.address.message) as string}
            rest={{
              style: {borderRadius: 0},
              disabled: valueCep.length >= 8 ? false : true
            }}
          />
        </Col>
        <Col span={5}>
          <InputForm
            name="neighborhood"
            title="Bairro"
            control={control}
            error={(errors.neighborhood && errors.neighborhood.message) as string}
            rest={{
              style: {borderRadius: 0},
              disabled: valueCep.length >= 8 ? false : true
            }}
          />
        </Col>
        <Col span={2}>
          <InputForm
            name="addressNumber"
            title="Número"
            control={control}
            error={(errors.addressNumber && errors.addressNumber.message) as string}
            rest={{
              style: {borderRadius: 0},
              disabled: valueCep.length >= 8 ? false : true
            }}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="details"
            title="Complemento"
            control={control}
            error={(errors.details && errors.details.message) as string}
            rest={{
              style: {borderRadius: 0},
              disabled: valueCep.length >= 8 ? false : true
            }}
          />
        </Col>
      </Row>
    </GeneralContainer>
  )

}