import { createContext, useMemo } from 'react';
import { requestHolidays } from 'services/holidays/requestHolidays';
import { IHolidayContextData, IHolidayProviderProps } from './types';

export const HolidayContext = createContext({} as IHolidayContextData);

export function HolidayProvider({ children }: IHolidayProviderProps) {
  const getNationalHolidays = async (year: string) => {
    const { data } = await requestHolidays(year);
    return (data);
  };

  const value = useMemo(() => ({
    getNationalHolidays,
  }), []);

  return (
    <HolidayContext.Provider
      value={value}
    >
      {children}
    </HolidayContext.Provider>
  );
}
