import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useState } from "react";
import { IUser } from "hooks/useAuth/types";
import { getJsonItem, storageKeys } from "../utils/storage";
import { useAuth } from "../hooks/useAuth";
import { FullScreenLoading } from "../components/FullScreenLoading/FullScreenLoading";

function PrivateRoute({ children, permission }: any) {
  const isAuthenticated = getJsonItem(storageKeys.auth).token;
  const { verifyToken, verifyPermission } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const auth = searchParams.get("auth");
  const [loading, setLoading] = useState<boolean>(!!auth);
  const navigate = useNavigate();

  const deleteSearchParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("auth");
    setSearchParams(queryParams);
  };

  const validatePermissionRoute = async (user: IUser) => {
    const hasPermission = verifyPermission(permission, user);

    if (
      hasPermission == null ||
      hasPermission === undefined ||
      hasPermission === false
    ) {
      navigate("/acesso-negado");
      return;
    }

    deleteSearchParams();
    location.search = "";
    navigate(location);
  };

  const getValidation = async () => {
    let user: any = null;
    if (auth) {
      const response = await verifyToken(auth);

      if (!response) {
        navigate("/login");
        return;
      }
    } else {
      const response = await verifyToken();

      if (!response) {
        navigate("/login");
        return;
      }
      user = response;
    }

    validatePermissionRoute(user);
  };

  // useEffect(() => {
  //   getValidation();
  //   setLoading(false);
  // }, []);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" />;
}

export { PrivateRoute };
