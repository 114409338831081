import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { Dayjs } from "dayjs";

export interface IPropsTable {
  setLoading: (e: boolean) => void;
  searchDate: Dayjs | null | undefined;
  searchSingular: string[];
  searchRazaoCNPJ: string;
}

export interface DataType {
  key: string,
  cpfcnpj: string,
  dataVencimento: string,
  valorRecebido: string,
  valorAntecipado: string,
  valorComJuros: string,
}

export interface DataTypeModal {
  key: string,
  cpfcnpj: string,
  razaoSocial: String,
  dataVencimento: string,
  valorRecebido: string,
  valorAntecipado: string,
  valorComJuros: string,
}

export const dataAnticipationModal = [
  {
    key: '1',
    guia: '1234',
    dateAnticipation: '01-01-2023',
    valueAnticipation: '1000',
    valorComJuros: '940',
  },
  {
    key: '2',
    guia: '12345',
    dateAnticipation: '01-01-2023',
    valueAnticipation: '1010',
    valorComJuros: '950',
  },
  {
    key: '3',
    guia: '123456',
    dateAnticipation: '01-01-2023',
    valueAnticipation: '1020',
    valorComJuros: '960',
  },
];

export const columnsAnticipationModal = [
  {
    title: 'Guia',
    dataIndex: 'guia',
    key: 'guia',
  },
  {
    title: 'Data da Antecipação',
    dataIndex: 'dateAnticipation',
    key: 'dateAnticipation',
  },
  {
    title: 'Valor',
    dataIndex: 'valueAnticipation',
    key: 'valueAnticipation',
  },
  {
    title: (
      <span>
        Valor com Juros{' '}
        <Popover
          content="Valor atualizado diariamente."
        >
          <QuestionCircleOutlined style={{ color: 'green' }} />
        </Popover>
      </span>
    ),
    dataIndex: 'valorComJuros',
    key: 'valorComJuros',
  },
]

export const columnsModal = [
  {
    title: 'CPF',
    dataIndex: 'cpfcnpj',
    key: 'cpfcnpj',
  },
  {
    title: 'Nome',
    dataIndex: 'razaoSocial',
    key: 'razaoSocial',
  },
  {
    title: 'Data de Vencimento',
    dataIndex: 'dataVencimento',
    key: 'dataVencimento',
  },
  {
    title: 'Valor Recebido',
    dataIndex: 'valorRecebido',
    key: 'valorRecebido',
  },
  {
    title: 'Valor Antecipado',
    dataIndex: 'valorAntecipado',
    key: 'valorAntecipado',
  },
  {
    title: (
      <span>
        Valor com Juros{' '}
        <Popover
          content="Valor atualizado diariamente."
        >
          <QuestionCircleOutlined style={{ color: 'green' }} />
        </Popover>
      </span>
    ),
    dataIndex: 'valorComJuros',
    key: 'valorComJuros',
  },
];