import api from '../api';
import {ErpManyResponse, IErp} from './types';

export async function getAllErp() {
  return api.get<ErpManyResponse>('/api/v1/backoffice/singular/erps');
}

export async function getErpById(id?: string) {
  return api.get<IErp>(`/api/v1/backoffice/singular/erps/${id}`);
}
