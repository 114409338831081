import { DefaultTheme } from 'styled-components';

export const darkTheme: DefaultTheme = {
  borderRadius: '12px',
  background: '#F5F5F5',
  palette: {
    primary: '#01995D',
    primaryLight: '#91BB4B',
    secondary: '#085F55',
    secondaryDark: '#08554c',
    secondaryLight: '#ECF5F1',
    font: '#DDDDDD',
    red: '#EF6F6C',
    white: '#FFFFFF',
    black: '#000000',
    grey: '#b6b0b0',
    lightGray: '#A8A2A2',
    alternateGray: '#D9D9D9',
    text: '#1e1e1ed9',
  },
  fontFamily: {
    body: 'SF Pro',
    heading: 'SF Pro',
    roboto: 'Roboto',
  },
};

export const lightTheme: DefaultTheme = {
  borderRadius: '12px',
  background: '#F5F5F5',
  palette: {
    primary: '#01995D',
    primaryLight: '#91BB4B',
    secondary: '#085F55',
    secondaryDark: '#08554c',
    secondaryLight: '#ECF5F1',
    font: '#DDDDDD',
    red: '#EF6F6C',
    white: '#FFFFFF',
    black: '#000000',
    grey: '#b6b0b0',
    lightGray: '#A8A2A2',
    alternateGray: '#D9D9D9',
    text: '#1e1e1ed9',
  },
  fontFamily: {
    body: 'SF Pro',
    heading: 'SF Pro',
    roboto: 'Roboto',
  },
};
