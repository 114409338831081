import {createContext, useMemo, useState} from 'react';
import {requestGetProcedureById} from 'services/guides/requestGuide';
import {getAllGuides} from 'services/guides/requestAllGuides';
import {IGuide} from 'services/guides/types';
import {IGuideContextData, IGuideProviderProps} from './types';

export const GuideContext = createContext({} as IGuideContextData);

export function GuideProvider({children}: IGuideProviderProps) {
  const [allGuides, setAllGuides] = useState<IGuide[]>([]);
  const [countAllGuides, setCountAllGuides] = useState(0);
  const [tokenNextPage, setTokenNextPage] = useState('');

  const fetchAllGuides = async (
    limit: number,
    search: string,
    nextPage: string | null
  ) => {
    const {data: {data, item_count, token}} = await getAllGuides(
      limit, search, nextPage
    );
    setAllGuides(data);
    setTokenNextPage(token);
    if (item_count !== 0 && token || item_count !== 0 && !token) {
      setCountAllGuides(item_count);
    }
  };

  const getProcedureById = async (id: string) => {
    const {data: {data}} = await requestGetProcedureById(id);

    return (data);
  };

  const value = useMemo(() => ({
    fetchAllGuides,
    allGuides,
    countAllGuides,
    getProcedureById,
    tokenNextPage
  }), [allGuides, countAllGuides]);

  return (
    <GuideContext.Provider
      value={value}
    >
      {children}
    </GuideContext.Provider>
  );
}
