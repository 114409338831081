import { Tabs } from "antd";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;

  background-color: #fff;

  flex-direction: row;
  justify-content: space-between;

  padding-right: 24px;
  padding-bottom: 40px;
`;

export const StyledTabs = styled(Tabs)`
  padding-left: 24px;
  background-color: #fff;
`;

export const BodyContainer = styled.form`
  background-color: ${({ theme }) => theme.background};
`;

export const ButtonsContainer = styled.div`
  position: sticky;
  bottom: 0;

  background-color: ${({ theme }) => theme.background};

  display: flex;

  width: 100%;
  z-index: 1;

  justify-content: flex-end;

  padding: 10px 20px;

  gap: 10px;
`;

export const CloseButton = styled.button`
  border: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: solid;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0px;
  color: black;
  padding: 9px 40px;

  font-family: ${({ theme }) => theme.fontFamily.heading};
`;
