import {Button, Col, Divider, Row} from "antd";
import {ContactContainer, Title} from "../../style";
import {InputForm} from "../../../../../components/InputForm";
import {maskPhone} from "../../../../../utils/masks";
import {FiTrash} from "react-icons/fi";
import {IFormSingularProps} from "../../types";
import {useFieldArray} from "react-hook-form";
import {useEffect} from "react";
import {useTheme} from "styled-components";

export function ContactsContainerSingular({control, errors, register}: IFormSingularProps) {
  const theme = useTheme();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields, append]);

  return (
    <ContactContainer>
      <Row>
        <Title>Contatos</Title>
      </Row>
      <Divider style={{marginTop: 0}}/>
      {fields.map((field, index) => (
        <Row
          gutter={{
            lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
          }}
          key={field.id}
        >
          <Col span={6}>
            <InputForm
              name="contactName"
              title="Nome contato"
              control={control}
              error={(errors?.contacts && errors.contacts[index]?.contactName?.message) as string}
              rest={{style: {borderRadius: 0}}}
              {...register(`contacts.${index}.contactName`)}
            />
          </Col>
          <Col span={3}>
            <InputForm
              name="contactPhone"
              title="Fone"
              control={control}
              mask={maskPhone}
              error={(errors?.contacts
                && errors.contacts[index]?.contactPhone?.message) as string}
              rest={{style: {borderRadius: 0}}}
              {...register(`contacts.${index}.contactPhone`)}
            />
          </Col>
          <Col span={6}>
            <InputForm
              name="contactEmail"
              title="Email"
              control={control}
              error={(errors?.contacts
                && errors.contacts[index]?.contactEmail?.message) as string}
              rest={{style: {borderRadius: 0}}}
              {...register(`contacts.${index}.contactEmail`)}
            />
          </Col>

          <Col style={{marginTop: 22}} span={1}>
            <Button onClick={() => remove(index)}>
              <FiTrash
                size={23}
                color={theme.palette.grey}
              />
            </Button>
          </Col>
        </Row>
      ))}
      <Row justify="end" style={{marginTop: 30}}>
        <Button
          onClick={() => {
            append({});
          }}
          type="default"
          style={{borderRadius: 0}}
        >
          + Adicionar
        </Button>
      </Row>
    </ContactContainer>
  )
}