import * as Yup from 'yup';

export const schema = Yup.object().shape({
  username: Yup
    .string()
    .required('O usuário é obrigatório'),
  password: Yup
    .string()
    .min(8, 'Password deve conter no mínimo 8 caracteres')
    .required('Password é obrigatório'),
});
