

import { getBank } from 'services/bank/requestBank';
import { AndressContainer } from '../ContainerForm/AndressContainer/AndressContainer';
import { BankContainerDoctor } from '../ContainerForm/BankContainer/BankContainerDoctor';
import { RegisterContainer } from '../ContainerForm/RegisterContainer/RegisterContainer';
import { SpecialtiesContainer } from '../ContainerForm/SpecialtiesContainer/SpecialtiesContainer';
import {
  Container
} from './style';
interface IBankFormProps {
  control: any,
  errors: any,
  setValue: any,
  getLocation: any,
  id: string,
}

export function DetailsForm({
  control, errors, setValue, getLocation,
}: IBankFormProps) {

  const handleSetBank = async (value: string) => {
    const { data } = await getBank(value);
    setValue('bankCode', data.data[0].code)
    setValue('bank', data.data[0].name)
  }
  
  return (
    <Container>
      <RegisterContainer control={control} errors={errors} setValue={setValue} />
      
      <SpecialtiesContainer control={control} errors={errors} setValue={setValue} />

      <AndressContainer control={control} errors={errors} getLocation={getLocation} />

      <BankContainerDoctor control={control} errors={errors} setBank={handleSetBank} />
    </Container>
  );
}
