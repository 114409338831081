import React from "react";
import { Layout as LayoutAntd, Menu } from "antd";
import { useNavigate } from "react-router-dom";

import { BsArrowRightCircle, BsPersonCheck } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";

import {
  LogoWhite,
  SiderStyle,
  HeaderTitle,
  StyledHeader,
  StyledBreadcrumb,
  StyledContent,
  HeaderName,
  RowUser,
  UserPhoto,
} from "./styles";

import { ILayoutProps } from "./types";
import { FiLogOut } from "react-icons/fi";
import { useAuth } from "hooks/useAuth";
import { LiaHandHoldingUsdSolid } from "react-icons/lia";

const items: any = [
  {
    key: "1",
    label: "Singulares",
    route: "singulares",
    icon: <BsPersonCheck size={20} />,
    permission: "unimed.list",
  },
  {
    key: "2",
    label: "Rede Prestadora",
    route: "prestadores",
    icon: <BsArrowRightCircle size={20} />,
    permission: "clinic.list",
  },
  {
    key: "3",
    label: "Médicos",
    route: "medicos",
    icon: <BiEdit size={20} />,
    permission: "doctor.list",
  },
  {
    key: "4",
    label: "Guias",
    route: "guias",
    icon: <BiEdit size={20} />,
    permission: "doctor.list",
  },
  {
    key: "5",
    label: "Antecipações",
    route: "antecipacao",
    icon: <LiaHandHoldingUsdSolid size={20} />,
    permission: "doctor.list",
    children: [
      {
        key: "6",
        label: "Não Liquidadas",
        route: "antecipacao",
        icon: <LiaHandHoldingUsdSolid size={20} />,
        permission: "doctor.list",
      },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "11",
    label: "Usuários",
    route: "usuarios",
    icon: <BsPersonCheck size={20} />,
    permission: "user.list",
  },

  {
    key: "0",
    label: "home",
    route: "home",
    permission: "home",
    style: {
      display: "none",
    },
  },
];

const itemLogout: any = [
  {
    key: "1",
    label: "Sair",
    icon: <FiLogOut size={20} />,
  },
];

export const Layout: React.FC<ILayoutProps> = ({ children, pathRoute }) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleMenuItemClick = (item: any) => {
    items.forEach((menuItem: any) => {
      if (menuItem.key === item.key) {
        if (menuItem.route) {
          navigate(`/painel/${menuItem.route}`);
        }
      } else if (menuItem.children) {
        const subMenuItem = menuItem.children.find(
          (subItem: any) => subItem.key === item.key
        );
        if (subMenuItem && subMenuItem.route) {
          navigate(`/painel/${subMenuItem.route}`);
        }
      }
    });
  };

  return (
    <LayoutAntd style={{ minHeight: "100vh" }}>
      <SiderStyle
        width={254}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <LogoWhite />
        <Menu
          defaultSelectedKeys={["0"]}
          mode="inline"
          items={items}
          onClick={handleMenuItemClick}
        />
        <Menu
          mode="inline"
          items={itemLogout}
          onClick={logout}
          style={{ position: "absolute", bottom: 10 }}
        />
      </SiderStyle>
      <LayoutAntd style={{ marginLeft: "254px" }}>
        <StyledHeader>
          <HeaderTitle>Painel Administrativo</HeaderTitle>
          <RowUser>
            <HeaderName>{user?.name}</HeaderName>
            <UserPhoto />
          </RowUser>
        </StyledHeader>
        <StyledContent>
          <StyledBreadcrumb>
            {pathRoute &&
              pathRoute
                .split("/")
                .map((locationRoute) => (
                  <StyledBreadcrumb.Item key={locationRoute}>
                    {locationRoute[0].toUpperCase() +
                      locationRoute.substring(1)}
                  </StyledBreadcrumb.Item>
                ))}
          </StyledBreadcrumb>

          {children}
        </StyledContent>
      </LayoutAntd>
    </LayoutAntd>
  );
};
