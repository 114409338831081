import {message} from 'antd';
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {getJsonItem, storageKeys} from '../utils/storage';
import api from './api';
import {useAuth} from "../hooks/useAuth";
import {cookies, cookiesSetSession} from "../utils/sessionCookies";

export const setInterceptors = () => {
  const {getRefreshToken} = useAuth();
  const responseSuccess = (value: AxiosResponse) => value;
  const responseFailed = (error: AxiosError) => {

    if (error?.response?.status === 0) {
      getRefreshToken().then(r => {
        window.location.reload();
        return
      });
    } else if (error?.response?.status != 0) {
      message.error(error.message);
    } else {
      message.error('Ocorreu um erro inesperado. ');
    }
    return error;
  };

  // request interceptors
  const requestSuccess = (config: AxiosRequestConfig) => {
    const {token} = getJsonItem(storageKeys.auth);
    const interceptConfig = config;
    cookiesSetSession();
    interceptConfig.headers = {authorization: `Bearer ${token}`};

    return interceptConfig;
  };
  const requestFailed = (error: any) => error;

  api.interceptors.response.use(
    responseSuccess,
    responseFailed,
  );

  api.interceptors.request.use(
    requestSuccess,
    requestFailed,
  );
};
