import api from '../api';
import { IPermissionsByIndexDataResponse } from './types';

export async function getPermissionsByIndex(
  pageSize: number,
  page: number,
  searchPermission: string,
) {
  return api.get<IPermissionsByIndexDataResponse>(`permissions/pagination?pageSize=${pageSize}&page=${page}&permission=${searchPermission}`);
}
