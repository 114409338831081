import React, {useState} from 'react';
import {IPaginationTypes} from "./types";
import {Pagination, PaginationContainer} from './styles';

export const CustomPagination = ({
                                   countAllItems, limit, tokenNextPage, setLimit, setNextPage,
                                 }: IPaginationTypes) => {
  const [currentDefault, setCurrentDefault] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [historyToken, setHistoryToken] = useState([]);

  const onChangePage = (page: any, pageSize: number) => {
    const totalPages = Math.ceil(countAllItems / limit);
    setCurrentDefault(page);

    if (limit !== pageSize) {
      resetPagination(pageSize);
      return;
    }

    if (allowAdvance(page, totalPages)) {
      advancePage(page);
    } else {
      previousPage(page);
    }
  }

  const resetPagination = (pageSize: number) => {
    setLimit(pageSize);
    setHistoryToken([]);
    setNextPage('');
    setCurrentPage(1);
    setCurrentDefault(1);
  }

  const allowAdvance = (page: number, totalPages: number) => {
    return page > currentPage || page === totalPages;
  }

  const advancePage = (page: number) => {
    const newHistoryToken = [...historyToken, tokenNextPage];
    setNextPage(tokenNextPage);
    setCurrentPage(page);
    // @ts-ignore
    setHistoryToken(newHistoryToken);
  }

  const previousPage = (page: number) => {
    setCurrentPage(page);
    const previousToken = historyToken[historyToken.length - 2];
    setNextPage(previousToken);
    const newHistoryToken = [...historyToken];
    newHistoryToken.pop();
    setHistoryToken(newHistoryToken);
  }

  return (
    <PaginationContainer>
      <Pagination
        current={currentDefault}
        onChange={onChangePage}
        pageSize={limit}
        pageSizeOptions={['5', '10', '20', '50']}
        showSizeChanger={true}
        showTotal={(total: number, range: any) => `${range[0]} - ${range[1]} de ${total}`}
        total={countAllItems}
        showPrevNextJumpers={false}
      />
    </PaginationContainer>
  );
};