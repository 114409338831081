import { createContext, useMemo, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import { darkTheme } from '../../theme';
import { IThemeContextData, IThemeProviderProps } from './types';

export const ThemeContext = createContext({} as IThemeContextData);

export function ThemeProvider({ children }: IThemeProviderProps) {
  const [theme, setTheme] = useState<DefaultTheme>(darkTheme);

  const value = useMemo(() => ({
    theme,
    setTheme,
  }), [theme]);

  return (
    <ThemeContext.Provider
      value={value}
    >
      {children}
    </ThemeContext.Provider>
  );
}
