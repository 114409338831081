import { DeleteFilled, EditFilled, FileTextFilled } from "@ant-design/icons";
import { Popconfirm, Popover } from "antd";
import { useTheme } from "hooks/useTheme";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IProps } from "../../types";
import {
  ButtonAntd,
  CellContent,
  LabelName,
  LabelTb,
  TableCell,
  TableRow,
} from "./styles";

export function DataRow({
  onClickRemove,
  allDoctors,
  detailsDoctorData,
  allSingularData,
}: IProps) {
  const [currentSelected, setCurrentSelected] = useState<number>(-1);
  const { theme } = useTheme();
  const navigate = useNavigate();

  function handleSelect(index: number) {
    setCurrentSelected(currentSelected === index ? -1 : index);
  }

  return (
    <>
      {allDoctors &&
        allDoctors.length > 0 &&
        allDoctors.map((item, index) => {
          return (
            <TableRow>
              <TableCell width={100}>
                <CellContent>
                  <LabelName
                    bold
                    paddingLeft={16}
                    onClick={() => {
                      navigate(`/painel/medicos/detalhes/${item.doctor_id}`);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "${({ theme }) => theme.palette.primary}",
                    }}
                  >
                    {item.name}
                  </LabelName>
                </CellContent>
              </TableCell>
              <TableCell width={100}>
                <CellContent>
                  <LabelTb bold paddingLeft={16}>
                    {item.cpf}
                  </LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <LabelTb paddingLeft={16}>{item.crm}</LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <LabelTb paddingLeft={16}>{item.provider_code}</LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <LabelTb paddingLeft={16}>{item.cnes}</LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <LabelTb paddingLeft={16}>
                    {item.enabled_anticipation ? 'Habilitado' : 'Desabilitado'}
                  </LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={106}>
                <CellContent gap={5}>
                  <Popover content={"Editar"} trigger="hover">
                    <ButtonAntd
                      disabled
                      onClick={() => {
                        navigate(`/painel/medicos/${item.doctor_id}`);
                      }}
                    >
                      <EditFilled />
                    </ButtonAntd>
                  </Popover>
                  {
                    <Popover content={"Excluir"} trigger="hover">
                      <Popconfirm
                        title="Remover"
                        description="Deseja realmente remover?"
                        onConfirm={() => onClickRemove(item.doctor_id)}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <ButtonAntd disabled >
                          <DeleteFilled />
                        </ButtonAntd>
                      </Popconfirm>
                    </Popover>
                  }
                  <Popover content={"Ver Detalhes"} trigger="hover">
                    <ButtonAntd
                      onClick={() => {
                        navigate(`/painel/medicos/detalhes/${item.doctor_id}`);
                      }}
                    >
                      <FileTextFilled />
                    </ButtonAntd>
                  </Popover>
                </CellContent>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
}
