import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.red};

  margin: 3px 0;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.grey};
  
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 10pt;

  margin: 3px 0;
`;

export const StyledLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.grey};

  text-decoration: none;
`;

export const TitleContainer = styled.div`
  display: flex;

  justify-content: space-between;
`;
