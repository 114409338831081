import React from 'react';
import { Container } from './styles';
import { IProps } from './types';

export function Button({
  children,
  onClick,
  type = 'button',
  ...props
}: IProps) {
  return (
    <Container onClick={onClick} type={type} {...props}>
      {children}
    </Container>
  );
}
