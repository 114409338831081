import { createContext, useMemo, useState } from 'react';
import { getTransfersByIndex } from 'services/transfers/requestTransfersByIndex';
import { ITransfer } from 'services/transfers/types';
import { ITransferContextData, ITransferProviderProps } from './types';

export const TransferContext = createContext({} as ITransferContextData);

export function TransferProvider({ children }: ITransferProviderProps) {
  const [allTransfers, setAllTransfers] = useState<ITransfer[]>([]);
  const [countAllTransfers, setCountAllTransfers] = useState(0);

  const getAllTransfers = async (
    pageSize: number,
    page: number,
    doctor: string,
    initialDate: string,
    endDate: string,
  ) => {
    const { data: { data: { data, total } } } = await getTransfersByIndex(
      pageSize,
      page,
      doctor,
      initialDate,
      endDate,
    );

    setAllTransfers(data);
    setCountAllTransfers(total);
  };

  const value = useMemo(() => ({
    getAllTransfers,
    allTransfers,
    countAllTransfers,
  }), [allTransfers, countAllTransfers]);

  return (
    <TransferContext.Provider
      value={value}
    >
      {children}
    </TransferContext.Provider>
  );
}
