import {useSingular} from "../hooks/useSingular";
import {IErp, ISingular} from "../services/singular/types";
import {maskPhone} from "./masks";
import {useEffect} from "react";

export function getLabelForErpId(singularId: string) {

  const {
    allErp
  } = useSingular();
  
  const foundItem: IErp | undefined = allErp.find(item => item.erp_id === singularId);
  return foundItem ? foundItem.name : "";
}

export const listAllErp = () => {
  const {
    allErp
  } = useSingular();
  
  return (allErp.map((erp) => ({
    value: erp.erp_id,
    label: erp.name,
  })));
};

export function useSingularFormValues(singular: ISingular, setValue: any) {
  if (singular) {
    setValue('name', singular.name);
    setValue('code', singular.unimed_code);
    setValue('codeAns', singular.ans_code);
    setValue('cnpj', singular.cnpj);
    setValue('cep', singular.address.zipcode);
    setValue('address', singular.address.street);
    setValue('addressNumber', singular.address.number);
    setValue('neighborhood', singular.address.neighborhood);
    setValue('details', singular.address.details);
    setValue('state', singular.address.state);
    setValue('city', singular.address.city);
    setValue('paymentDay', singular.payment_day);
    setValue('erpId', singular.erp_id);

    setValue('anticipation', singular.enabled_anticipation === undefined ? true : singular.enabled_anticipation);
    setValue('anticipationPercent', singular.anticipation_percentage);

    setValue('taxFIDCProcedure', singular.tax_fidc);
    setValue('taxUnimedPayProcedure', singular.tax_unimed_pay);
    setValue('taxComissionProcedure', singular.commission || 0);

    setValue('bankCode', singular.account.code);
    // setValue('bank', singular.account.name);
    setValue('agency', singular.account.branch);
    setValue('account', singular.account.number);
    setValue('digit', singular.account.digit);

    setValue('contacts', singular.contacts.map((contact) => ({
      contactEmail: contact.email,
      contactName: contact.name,
      contactPhone: maskPhone(contact.phone),
      contactPosition: contact.position,
      contactType: contact.type,
    })));

  } else {
    setValue('anticipation', false);
    setValue('anticipationPercent', 0);
  }
}

// buildContent.js
export function buildContent(data: any, id = 'new') {
  return {
    name: data.name,
    unimed_code: data.code,
    ans_code: data.codeAns,
    cnpj: data.cnpj.replace(/[./-]/g, ''),
    commission: data.taxComissionProcedure,
    tax_fidc: data.taxFIDCProcedure,
    tax_unimed_pay: data.taxUnimedPayProcedure,
    anticipation_percentage: data.anticipationPercent,
    address: {
      city: data.city,
      neighborhood: data.neighborhood,
      state: data.state,
      details: data.details || '',
      number: data.addressNumber,
      street: data.address,
      zipcode: data.cep,
    },
    account: {
      branch: data.agency,
      code: data.bankCode,
      digit: data.digit,
      number: data.account,
    },
    contacts: data.contacts.map((contact: any) => ({
      email: contact.contactEmail,
      name: contact.contactName,
      phone: contact.contactPhone.replace(/[()]/g, ''),
      position: 'Mocado',
      type: null,
    })),
    master_user: {
      cpf: data.masterUserCpf.replace(/[./-]/g, ''),
      email: data.masterUserEmail,
      name: data.masterUserName,
    },
    payment_day: data.paymentDay,
    erp_id: data.erpId,
  };
}
