/* eslint-disable no-unused-vars */
import 'react-multi-carousel/lib/styles.css';
import { AndressDetails } from './Container/Andress/Andress';
import { AntecipationDetails } from './Container/Antecipation/Antecipation';
import { BankDetails } from './Container/Bank/Bank';
import { BankEscrowDetails } from './Container/BankEscrow/BankEscrow';
import { RegisterDetails } from './Container/Register/Register';
import { ContainerComponents } from './styles';

export function DetailsDoctor()  {

  return (
    <ContainerComponents>
      <RegisterDetails />
      <AndressDetails />
      <AntecipationDetails />
      <BankDetails />
      <BankEscrowDetails />
    </ContainerComponents>
  );
}
