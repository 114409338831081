import styled, { css } from 'styled-components';
import { Button, Divider as RawDivider, Pagination as PaginationRaw } from 'antd';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { ICircleStyle, IStyleProps } from './types';

export const TableCell = styled.td<IStyleProps>`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: 400;

  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text};

  padding-bottom: 5px;

  ${({ width }) => width
    && css`
      width: ${width}px;
    `}

  ${({ disableLeftPadding }) => disableLeftPadding
    && css`
      padding-left: 0px;
    `}

  ${({ disablePadding }) => disablePadding
    && css`
      padding: 0px;
    `}
`;


export const CellContent = styled.div<IStyleProps>`
  background-color: ${({ theme }) => theme.palette.white};
  
  height: 45px;

  display: flex;
  justify-items: center;
  align-items: center;

  padding: 11px 0px; 
  
  ${({ gap }) => gap
    && css`
      gap: ${gap}px;
    `}

  ${({ disablePadding }) => !disablePadding
    && css`
      padding: 11px 16px; 
    `}

  ${({ width }) => !!width
    && css`
      width: ${width}px;
    `}

  ${({ flexEnd }) => flexEnd
    && css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      padding-right: 16px;
    `}
`;

export const CellActive = styled.div<IStyleProps>`
  background-color: ${({ theme }) => theme.palette.white};
  
  height: 186px;

  display: flex;
  justify-items: center;
  align-items: center;

  padding: 11px 0px; 

  ${({ disablePadding }) => !disablePadding
    && css`
      padding: 11px 16px; 
    `}

  ${({ width }) => !!width
    && css`
      width: ${width}px;
    `}

  ${({ flexEnd }) => flexEnd
    && css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      padding-right: 16px;
    `}
`;

export const ChevronDown = styled(BiChevronDown)<IStyleProps>`
  cursor: pointer;

  margin-left: 42px;
  margin-right: -20rem;
`;

export const ChevronUp = styled(BiChevronUp)`
  cursor: pointer;

  margin-left: 42px;
  margin-right: -20rem;
`;

export const LabelName = styled.p<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary};

  ${({ centralize }) => centralize && css`
    margin: auto;
  `}
  
  ${({ paddingLeft }) => paddingLeft && css`
    padding-left: ${paddingLeft}px;
  `}

  ${({ bold }) => bold
    && css`
      font-weight: bold;
    `}
`;

export const LabelTb = styled.p<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ centralize }) => centralize && css`
    margin: auto;
  `}
  
  ${({ paddingLeft }) => paddingLeft && css`
    padding-left: ${paddingLeft}px;
  `}

  ${({ bold }) => bold
    && css`
      font-weight: bold;
    `}
`;

export const ButtonAntd = styled(Button)`
  border-radius: 2px !important;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary} !important;
    color: ${({ theme }) => theme.palette.primary} !important;
  }
`;

export const TableRow = styled.tr``;
