import { Tabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
`;

export const HeaderContainer = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  padding-right: 24px;
  margin-bottom: 40px;
`;

export const StyledTabs = styled(Tabs)`
  padding-left: 24px;
`;

export const TitleName = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.3px;

  color: ${({ theme }) => theme.palette.primary};
`;

export const BodyContainer = styled.form`
  background-color: ${({ theme }) => theme.background};
`;
