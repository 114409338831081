import { Button, Divider as RawDivider, Tabs } from "antd";
import styled, { css } from "styled-components";
import { ICircleStyle, IStyleProps } from "./types";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
`;

export const HeaderArea = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  padding: 15px 1px;
`;

export const DetailsContainer = styled.div`
  min-width: 80%;
  padding: 0px 24px;
`;

export const Divider = styled(RawDivider).attrs({
  type: "vertical",
})<IStyleProps>`
  height: 28px;
  margin-right: 16px;
  margin-left: 0px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ alternativeLayout }) =>
    alternativeLayout &&
    css`
      height: calc(100% - 50px);

      margin: auto 0;
      margin-left: 2rem;
      margin-right: 2rem;
    `}
`;

export const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.palette.white};
  padding-left: 24px;
  margin-bottom: 40px;
`;

export const Circle = styled.div<ICircleStyle>`
  height: 6px;
  width: 6px;

  border-radius: 100%;
  margin-right: 8px;

  ${({active, theme, color}) => (active
    ? css`
      background-color: ${color};
    `
    : css`
      background-color: ${theme.palette.red};
    `)
  }
`;

export const LabelTb = styled.p<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ centralize }) => centralize && css`
    margin: auto;
  `}
  
  ${({ paddingLeft }) => paddingLeft && css`
    padding-left: ${paddingLeft}px;
  `}

  ${({ bold }) => bold
    && css`
      font-weight: bold;
    `}
`;

export const ButtonsContainer = styled.div`
  position: sticky;
  bottom: 0;

  background-color: ${({ theme }) => theme.background};

  display: flex;

  width: 100%;
  z-index: 1;

  justify-content: flex-end;

  padding: 10px 20px;

  gap: 10px;
`;

export const CloseButton = styled.button`
  border: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: solid;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0px;
  color: black;
  padding: 9px 40px;

  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const ButtonAntd = styled(Button)`
  border-radius: 2px !important;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary} !important;
    color: ${({ theme }) => theme.palette.primary} !important;
  }
`;
