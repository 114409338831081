import styled from 'styled-components';
import { Input as InputRaw } from 'antd';

export const Container = styled.div`
  width: 100%;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.red};

  margin: 3px 0;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.grey};
  
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 10pt;

  margin: 3px 0;
`;

export const DivLabel = styled.div`
  border-bottom-color: #A9A6A667;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  height: 25px;
  align-items: center;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
