import { Container, Text } from './styles';

export function AccessDenied() {
  return (
    <Container>
      <Text>401</Text>
      <Text>Acesso negado</Text>
    </Container>
  );
}
