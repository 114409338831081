import api from '../api';

export async function updateAticipation(id: string, value: boolean): Promise<boolean> {
  const data = {
    enable_anticipation: value
  }
  try {
    const response = await api.put(`/api/v1/backoffice/singular/${id}/enable-anticipation`, data);
    if(response.status === 204) {
      return true
    } else {
      throw new Error(`Falha na solicitação. Código de status: ${response.status}`);
    }
  } catch (error) {
    return false;
  }
}