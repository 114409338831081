import React from 'react';
import { Container, SubTitle, Title } from './styles';
import { IProps } from './types';

export function Header({
  title,
  subtitle,
}: IProps) {
  return (
    <Container>
      <Title>
        { title }
      </Title>
      <SubTitle>
        { subtitle }
      </SubTitle>
    </Container>
  );
}
