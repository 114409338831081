import {Content, Divider, TableHead, TableHeadCell, TableLabel} from "../styles";

export function TableHeadSingular() {

  return (
    <>
      <TableHead>
        <TableHeadCell disableLeftPadding width={80}>
          <Content>
            <TableLabel style={{ marginLeft: "16px" }}>
              Código Unimed
            </TableLabel>
          </Content>
        </TableHeadCell>
        <TableHeadCell width={225}>
          <Content>
            <Divider/>
            <TableLabel>
              Nome da Singular
            </TableLabel>
          </Content>
        </TableHeadCell>
        <TableHeadCell width={95}>
          <Content>
            <Divider/>
            <TableLabel>
              Código ANS
            </TableLabel>
          </Content>
        </TableHeadCell>
        <TableHeadCell width={225}>
          <Content>
            <Divider/>
            <TableLabel>
              CNPJ
            </TableLabel>
          </Content>
        </TableHeadCell>
        <TableHeadCell width={106}>
          <Content>
            <Divider/>
            <TableLabel>
              Ações
            </TableLabel>
          </Content>
        </TableHeadCell>
      </TableHead>
    </>
  )
}