import {Col, Divider, Row} from "antd";
import {MasterUserContainer, Title} from "../../style";
import {InputForm} from "../../../../../components/InputForm";
import {maskCPF} from "../../../../../utils/masks";
import {IFormSingularProps} from "../../types";

export function MasterContainerSingular({control, errors}: IFormSingularProps) {
  return (
    <MasterUserContainer>
      <Row>
        <Title>Usuário Master</Title>
      </Row>
      <Divider style={{marginTop: 0}}/>
      <Row gutter={{
        lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
      }}
      >
        <Col span={8}>
          <InputForm
            name="masterUserName"
            title="Nome"
            control={control}
            error={(errors.masterUserName && errors.masterUserName.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={8}>
          <InputForm
            name="masterUserEmail"
            title="Email"
            control={control}
            error={(errors.masterUserEmail && errors.masterUserEmail.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={4}>
          <InputForm
            name="masterUserCpf"
            title="CPF"
            control={control}
            mask={maskCPF}
            error={(errors.masterUserCpf && errors.masterUserCpf.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
      </Row>
    </MasterUserContainer>
  )
}