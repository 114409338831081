import { useState } from "react";
import { Col, Divider, Row } from "antd";
import { SelectForm } from "components/SelectForm";
import { Container, Title, DetailsContainer } from "./style";
import { InputForm } from "../../../components/InputForm";
import { maskCPF } from "utils/masks";

interface IBankFormProps {
  control: any;
  errors: any;
  edit: boolean;
}

export function DetailsForm({ control, errors, edit }: IBankFormProps) {
  const dataProfile = [
    { value: "WRITE", label: "Administrador" },
    { value: "READ", label: "Leitor" },
  ];

  return (
    <Container>
      <DetailsContainer>
        <Row>
          <Title>Cadastro</Title>
        </Row>
        <Divider style={{ marginTop: 0 }} />
        <Row
          style={{ marginTop: 20 }}
          gutter={{
            lg: 20,
            sm: 20,
            md: 20,
            xl: 20,
            xs: 20,
            xxl: 20,
          }}
        >
          <Col span={8}>
            <InputForm
              name="name"
              title="Nome"
              control={control}
              error={(errors.name && errors.name.message) as string}
              rest={{ 
                style: { borderRadius: 0 },
                disabled: edit,
              }}
            />
          </Col>
          <Col span={8}>
            <InputForm
              name="email"
              title="Email"
              control={control}
              error={(errors.email && errors.email.message) as string}
              rest={{ 
                style: { borderRadius: 0 },
                disabled: edit,
              }}
            />
          </Col>
          <Col span={8}>
            <InputForm
              name="username"
              title="CPF"
              control={control}
              error={(errors.username && errors.username.message) as string}
              rest={{ 
                style: { borderRadius: 0 },
                disabled: edit,
              }}
              mask={maskCPF}
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 10 }}
          gutter={{
            lg: 20,
            sm: 20,
            md: 20,
            xl: 20,
            xs: 20,
            xxl: 20,
          }}
        >
          <Col span={8}>
            <SelectForm
              name="profile"
              data={dataProfile}
              title="Perfil de acesso"
              control={control}
              error={(errors.profile && errors.profile.message) as string}
              rest={{ style: { borderRadius: 0 } }}
            />
          </Col>
        </Row>
      </DetailsContainer>
    </Container>
  );
}
