import React, { useState, useEffect } from "react";
import { Table as TableComponent, Modal, Popover, Divider } from "antd";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";

import {
  IPropsTable,
  DataType,
  DataTypeModal,
  columnsAnticipationModal,
  dataAnticipationModal,
  columnsModal,
} from "./types";
import {
  Container,
  ContainerModal,
  ContainerAnticipationModal,
  Button,
} from "./styles";

import { getUnpadAnticipation } from "../../../../services/unpadAnticipation/getUnpadAnticipation";
import { useDoctor } from "hooks/useDoctor";
import { IDataUnpadAnticipation } from "services/unpadAnticipation/types";
import { currencyFormat } from "utils/currencyFormat";
import { formatDate } from "utils/dateFormat";
import { maskCPForCNPJ } from "utils/masks";

export const Table = ({ setLoading, searchDate, searchRazaoCNPJ, searchSingular }: IPropsTable) => {
  const { getDoctorById } = useDoctor();
  const [modalIsOpne, setModalIsOpen] = useState<boolean>(false);
  const [dataUnpadAnticipation, setDataUnpadAnticipation] = useState<IDataUnpadAnticipation[]>([])
  const [dataTableUnpadAnticipation, setTableDataUnpadAnticipation] = useState<
    DataType[]
  >([]);
  const [dataModalUnpadAnticipation, setDataModalUnpadAnticipation] = useState<
    DataTypeModal[]
  >([]);

  const setUnpadAnticipation = async () => {
    await getUnpadAnticipation().then(({data}) => {
      setDataUnpadAnticipation(data.data);
      setTableDataUnpadAnticipation(
        data.data.map((item) => {
          return {
            key: item.item_id,
            cpfcnpj: maskCPForCNPJ(item.cpf),
            dataVencimento: formatDate(item.created_at),
            valorRecebido: currencyFormat(item.payment_amount),
            valorAntecipado: currencyFormat(item.gross_amount),
            valorComJuros: currencyFormat(item.net_amount),
          };
        })
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setUnpadAnticipation();
  }, []);

  useEffect(() => {
    console.log(searchDate)
    console.log(searchSingular)
    console.log(searchRazaoCNPJ)
  }, [searchDate, searchSingular, searchRazaoCNPJ])

  const getDoctor = async (id: string): Promise<string> => {
    const data = await getDoctorById(id);
    return data.doctor.name;
  }

  const columns = [
    {
      title: "CPF",
      dataIndex: "cpfcnpj",
      key: "cpfcnpj",
    },
    {
      title: "Data de Vencimento",
      dataIndex: "dataVencimento",
      key: "dataVencimento",
    },
    {
      title: "Valor Recebido",
      dataIndex: "valorRecebido",
      key: "valorRecebido",
    },
    {
      title: "Valor Antecipado",
      dataIndex: "valorAntecipado",
      key: "valorAntecipado",
    },
    {
      title: (
        <span>
          Valor com Juros{" "}
          <Popover content="Valor atualizado diariamente.">
            <QuestionCircleOutlined style={{ color: "green" }} />
          </Popover>
        </span>
      ),
      dataIndex: "valorComJuros",
      key: "valorComJuros",
    },
    {
      title: "Ações",
      key: "acoes",
      dataIndex: "key",
      render: (_: any, record: DataType) => (
        <Button
          icon={<SearchOutlined />}
          onClick={() => {
            const aux = dataUnpadAnticipation.find((item) => item.item_id === record.key)
            if (aux) {
              getDoctor(aux.doctor_id).then((doctor) => {
                setDataModalUnpadAnticipation([{
                    key: aux.item_id,
                    razaoSocial: doctor,
                    cpfcnpj: maskCPForCNPJ(aux.cpf),
                    dataVencimento: formatDate(aux.created_at),
                    valorRecebido: currencyFormat(aux.payment_amount),
                    valorAntecipado: currencyFormat(aux.gross_amount),
                    valorComJuros: currencyFormat(aux.net_amount),
                }])
                setModalIsOpen(true);
              });
            }
          }}
        >
          Detalhes
        </Button>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item, ellipsis: true }));
  const tableColumnsModalGuias = columnsAnticipationModal.map((item) => ({
    ...item,
    ellipsis: true,
  }));

  return (
    <Container>
      <TableComponent
        dataSource={dataTableUnpadAnticipation}
        columns={tableColumns}
      />
      <Modal
        title="Detalhes da atencipação"
        open={modalIsOpne}
        footer={null}
        width={1000}
        onCancel={() => setModalIsOpen(false)}
      >
        <ContainerModal>
          <Divider />
          <ContainerAnticipationModal>
            <TableComponent
              dataSource={dataModalUnpadAnticipation}
              columns={columnsModal}
              pagination={false}
            />
          </ContainerAnticipationModal>
          <TableComponent
            dataSource={dataAnticipationModal}
            columns={tableColumnsModalGuias}
          />
        </ContainerModal>
      </Modal>
    </Container>
  );
};
