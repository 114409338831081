import {
  ButtonAntd,
  CellContent,
  LabelTb,
  TableCell,
  TableRow,
} from "../styles";
import { DeleteFilled, EditFilled, FileTextFilled } from "@ant-design/icons";
import { Col, Popconfirm, Popover } from "antd";
import { IProps } from "../types";
import { useTheme } from "../../../../hooks/useTheme";
import { useNavigate } from "react-router-dom";
import { maskCNPJ } from "../../../../utils/masks";

export function TableRowSingular({ allSingular, onClickRemove }: IProps) {
  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <>
      {allSingular &&
        allSingular!.map((item) => {
          return (
            <TableRow>
              <TableCell width={80}>
                <CellContent>
                  <Col>
                    <LabelTb>{item.unimed_code}</LabelTb>
                  </Col>
                </CellContent>
              </TableCell>
              <TableCell width={225}>
                <CellContent>
                  <LabelTb
                    bold
                    paddingLeft={16}
                    onClick={() => {
                      navigate(
                        `/painel/singulares/detalhes/${item.singular_id}`
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      color: `${theme.palette.primary}`,
                    }}
                  >
                    {item.name}
                  </LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <LabelTb paddingLeft={16}>{item.ans_code}</LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={225}>
                <CellContent>
                  <LabelTb paddingLeft={16}>{maskCNPJ(item.cnpj)}</LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={106}>
                <CellContent>
                  {
                    <Popover content={"Editar"} trigger="hover">
                      <ButtonAntd
                        disabled
                        onClick={() => {
                          navigate(`/painel/singulares/${item.singular_id}`);
                        }}
                        style={{ marginLeft: 5, marginRight: 5 }}
                      >
                        <EditFilled />
                      </ButtonAntd>
                    </Popover>
                  }
                  {
                    <Popover content={"Excluir"} trigger="hover">
                      <Popconfirm
                        title="Remover"
                        description="Deseja realmente remover?"
                        onConfirm={() => onClickRemove(item.singular_id)}
                        okText="Sim"
                        cancelText="Não"
                        disabled={true}
                      >
                        <ButtonAntd>
                          <DeleteFilled />
                        </ButtonAntd>
                      </Popconfirm>
                    </Popover>
                  }
                  <Popover content={"Ver Detalhes"} trigger="hover">
                    <ButtonAntd
                      onClick={() => {
                        navigate(
                          `/painel/singulares/detalhes/${item.singular_id}`
                        );
                      }}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <FileTextFilled />
                    </ButtonAntd>
                  </Popover>
                </CellContent>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
}
