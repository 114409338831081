/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useClinic } from "hooks/useClinic";
import { maskCNPJ, maskOnlyNumbers } from "utils/masks";
import { Header } from "../../components/Header";
import { Table } from "./Table";
import {
  Container,
  PaginationContainer,
  Pagination,
  SearchContainer,
  SearchBySingular,
  SearchByClinic,
  SearchContent,
  TitleSearch,
} from "./styles";

export function Provider() {
  const { allClinics, countAllClinics, getAllClinics, removeClinic } =
    useClinic();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchedSingular, setSearchedSingular] = useState<any[]>([]);
  const [selectedSingular, setSelectedSingular] = useState();
  const [searchClinic, setSearchClinic] = useState();
  const [clinicType, setClinicType] = useState();

  const onClickRemove = async (id: string) => {
    await removeClinic(id);
    //   getAllClinics(pageSize, page, selectedSingular || '', searchClinic || '', clinicType || '');
  };
  //TODO - fix this
  const searchUnimed = async (name: string) => {
    //   const {data} = await getSingularByName(name);
    //
    //   setSearchedSingular(data.map((unimed) => ({
    //     value: unimed.singular_id,
    //     label: unimed.name,
    //     code: unimed.unimed_code,
    //   })));
  };

  useEffect(() => {
    // getAllClinics(pageSize, page, selectedSingular || '', searchClinic || '', clinicType || '');
  }, [page, pageSize, selectedSingular, searchClinic, clinicType]);

  useEffect(() => {
    // searchUnimed('');
  }, []);

  return (
    <Container>
      <Header
        title="Clínicas, Laboratórios e Centros de Diagnóstico"
        subtitle="Relação de Clínicas e Laboratórios da Rede Credenciada por Singular"
      />
      <SearchContainer>
        <SearchContent>
          <TitleSearch>Singular</TitleSearch>
          <SearchBySingular
            options={searchedSingular}
            onChange={(value: any) => {
              setSelectedSingular(value);
            }}
            onSearch={(value) => {
              const IsCNPJSearchSingular =
                maskOnlyNumbers(value) ===
                value.replaceAll(".", "").replace("-", "").replace("/", "");
              searchUnimed(IsCNPJSearchSingular ? maskCNPJ(value) : value);
            }}
            filterOption={(input, option) =>
              String(option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            optionFilterProp="children"
            size="large"
            placeholder="Selecione ou digite o nome da singular"
            showSearch
          />
        </SearchContent>
        <SearchContent>
          <TitleSearch>Clínica</TitleSearch>
          <SearchByClinic
            value={searchClinic}
            onChange={(event: any) => {
              const IsCNPJSearchClinic =
                maskOnlyNumbers(event.target.value) ===
                event.target.value
                  .replaceAll(".", "")
                  .replace("-", "")
                  .replace("/", "");
              setSearchClinic(
                IsCNPJSearchClinic
                  ? maskCNPJ(event.target.value)
                  : event.target.value
              );
            }}
            placeholder="Pesquise por nome da clínica ou CNPJ"
          />
        </SearchContent>
      </SearchContainer>

      {allClinics && (
        <>
          <Table
            title="Lista de prestadores"
            clinics={allClinics}
            onChangeType={setClinicType}
            onClickRemove={onClickRemove}
          />
          <PaginationContainer>
            {`${page}-${page * pageSize} of ${countAllClinics} items`}
            <Pagination
              showSizeChanger
              defaultCurrent={1}
              pageSize={pageSize}
              onChange={(selectedPage, size) => {
                setPage(selectedPage || 1);
                setPageSize(size);
              }}
              total={countAllClinics}
            />
          </PaginationContainer>
        </>
      )}
    </Container>
  );
}
