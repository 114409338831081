import { useState } from "react";
import { Col, Divider, Modal, Popconfirm, Row, Switch } from "antd";
import { Container, Title, DetailsContainer, TitleEnable } from "./style";
import { InputForm } from "components/InputDetails";
import { maskCPF } from "utils/masks";
import { formatDate } from "utils/dateFormat";

import { requestEnableUser } from "services/users/requestEnableUser";
import { requestDisableUser } from "services/users/requestDisableUser";
import { IUserNew } from "services/users/types";

interface IProps {
  user: IUserNew;
}

export function DetailsForm({ user }: IProps) {
  const [enabled, setEnabled] = useState<boolean>(user.enabled);

  const utilProfile = (value: string) => {
    switch (value) {
      case "WRITE":
        return "Administrador";
      case "READ":
        return "Leito";
      default:
        return "";
    }
  };

  const handleOnChangeEnabled = async () => {
    try {
      if(!enabled) {
        await requestEnableUser(user.username)
      } else {
        await requestDisableUser(user.username)
      }
      Modal.success({
        content: `Usuário ${!enabled ? 'Habilitado' : 'Desabilitado' } com Sucesso`,
      });
      setEnabled(!enabled)
    } catch (error) {
      
    }
  };

  return (
    <Container>
      <DetailsContainer>
        <Row>
          <Title>Cadastro</Title>
        </Row>
        <Divider style={{ marginTop: 0 }} />
        <Row
          style={{ marginTop: 20 }}
          gutter={{
            lg: 20,
            sm: 20,
            md: 20,
            xl: 20,
            xs: 20,
            xxl: 20,
          }}
        >
          <Col span={8}>
            <InputForm title="Nome" value={user.name} />
          </Col>
          <Col span={8}>
            <InputForm title="Email" value={user.email} />
          </Col>
          <Col span={8}>
            <InputForm title="CPF" value={maskCPF(user.username)} />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 10 }}
          gutter={{
            lg: 20,
            sm: 20,
            md: 20,
            xl: 20,
            xs: 20,
            xxl: 20,
          }}
        >
          <Col span={8}>
            <InputForm title="Perfil" value={utilProfile(user.profile)} />
          </Col>
          <Col span={8}>
            <InputForm title="Criado Em" value={formatDate(user.created_at)} />
          </Col>
          <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
            <TitleEnable>Status</TitleEnable>
            <Popconfirm
              title="Status"
              description={`Deseja realmente ${!enabled ? 'Habilitar' : 'Desabilitar' } o usário?`}
              onConfirm={() => handleOnChangeEnabled()}
              okText="Sim"
              cancelText="Não"
            >
              <Switch
                style={{ width: "30px" }}
                checked={enabled}
              />
            </Popconfirm>
          </Col>
        </Row>
      </DetailsContainer>
    </Container>
  );
}
