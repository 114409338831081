import { useContext } from 'react';
import { ClinicContext, ClinicProvider } from './ClinicContext';

function useClinic() {
  const clinic = useContext(ClinicContext);

  return clinic;
}

export { useClinic, ClinicProvider };
