import { Navigate } from "react-router-dom";
import { getJsonItem, storageKeys } from "../utils/storage";

function PublicRoute({ children }: any) {
  const isAuthenticated = getJsonItem(storageKeys.auth).token;

  return !isAuthenticated ? children : <Navigate to="/" />;
}

export { PublicRoute };
