import React, { useEffect, useState } from "react";
import { Header } from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Modal, Spin } from "antd";
import { useUser } from "hooks/useUsers";
import {
  BodyContainer,
  Container,
  HeaderContainer,
  StyledTabs,
  TitleName,
  SubmitButton,
  CloseButton,
  ButtonsContainer,
} from "./styles";
import { schema } from "./schema";
import { DetailsForm } from "./DetailsForm";
import { requestUpdateProfile } from "services/users/requestProfileUser";
import { maskCPF } from "utils/masks";

const items = [
  {
    key: "1",
    label: "Cadastro de Usuário",
  },
];

export function UserForm() {
  const { id } = useParams() as any;
  const { createUser, getUserById } = useUser();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(id !== 'new');
  const [name, setName] = useState<string>('')

  const getUser = async() => {
    const response = await getUserById(id);
    
    setEdit(true);
    setName(response.name)
    setValue('name', response.name)
    setValue('email', response.email)
    setValue('username', maskCPF(response.username))
    setValue('profile', response.profile)
    setLoading(false)
  }

  useEffect(() => {
    if(id !== 'new') {
      setLoading(true)
      getUser()
    } 
  }, [])

  const submitForm = async (data: any) => {
    setLoading(true);
    try {
      if(!edit) {
        const dataSend = {
          name: data.name,
          email: data.email,
          username: data.username.replace(/[^\d]/g, ""),
          profile: data.profile,
        };
        await createUser(dataSend);
        Modal.success({
          content: 'Usuário Cadastrado com Sucesso',
        });
      } else {
        const dataSend = {
          profile: data.profile
        }
        await requestUpdateProfile(id, dataSend);
        Modal.success({
          content: 'Perfil Alterado com Sucesso',
        });
      }
      navigate("/painel/usuarios")
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <Header title="Usuário" subtitle="Cadastro de Usuário" />

        <TitleName>{name}</TitleName>
      </HeaderContainer>
      <StyledTabs defaultActiveKey="1" items={items} />

      <Spin spinning={loading}>
        <BodyContainer onSubmitCapture={handleSubmit(submitForm)}>
          <DetailsForm control={control} errors={errors} edit={edit}/>

          <ButtonsContainer>
            <CloseButton
              type="button"
              onClick={() => navigate("/painel/usuarios")}
            >
              Fechar
            </CloseButton>
            <SubmitButton type="submit">Salvar</SubmitButton>
          </ButtonsContainer>
        </BodyContainer>
      </Spin>
    </Container>
  );
}
