import { useContext } from 'react';
import { DoctorContext, DoctorProvider } from './DoctorContext';

function useDoctor() {
  const doctor = useContext(DoctorContext);

  return doctor;
}

export { useDoctor, DoctorProvider };
