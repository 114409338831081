/* eslint-disable no-unused-vars */
import "react-multi-carousel/lib/styles.css";
import { Modal, Popconfirm, Popover, Switch } from "antd";
import {
  SettingOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  FileTextFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { requestDeleteUser } from "services/users/requestDeleteUser";
import { IProps } from "./types";
import { Button } from "../../../components/Button";
import {
  Container,
  Header,
  Title,
  Items,
  Table,
  TableHead,
  TableHeadCell,
  Content,
  TableLabel,
  Divider,
  TableCell,
  TableRow,
  CellContent,
  LabelTb,
  ButtonAntd,
} from "./styles";
import { maskCPF } from "utils/masks";

export function TableContainer({ title, data, requestUsers }: IProps) {
  const navigate = useNavigate();

  const handleDeleteItem = async (id: string) => {
    try {
      await requestDeleteUser(id);
      Modal.success({
        content: 'Usuário Deletado com Sucesso!',
      });
      requestUsers();
    } catch (error) {
      throw new Error;
    }
  };

  const utilProfile = (value: string): string => {
    switch (value) {
      case "WRITE":
        return "Administrador";
      case "READ":
        return "Leitor";
      default:
        return "";
    }
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Items>
          <Button
            onClick={() => {
              navigate("/painel/usuarios/new");
            }}
          >
            <PlusOutlined style={{ marginRight: 10 }} />
            Novo usuário
          </Button>
        </Items>
      </Header>
      <Table cellPadding={0} cellSpacing={0}>
        <TableHead>
          <TableHeadCell disableLeftPadding>
            <Content>
              <Divider />
              <TableLabel>Nome</TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell>
            <Content>
              <Divider />
              <TableLabel>CPF</TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell>
            <Content>
              <Divider />
              <TableLabel>Email</TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell>
            <Content>
              <Divider />
              <TableLabel>Perfil</TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell>
            <Content>
              <Divider />
              <TableLabel>Ações</TableLabel>
            </Content>
          </TableHeadCell>
        </TableHead>
        {data!.map((item, index) => (
          <TableRow key={item.username}>
            <TableCell>
              <CellContent>
                <LabelTb>{item.name}</LabelTb>
              </CellContent>
            </TableCell>
            <TableCell>
              <CellContent>
                <LabelTb paddingLeft={16}>{maskCPF(item.username)}</LabelTb>
              </CellContent>
            </TableCell>
            <TableCell>
              <CellContent>
                <LabelTb paddingLeft={16}>{item.email}</LabelTb>
              </CellContent>
            </TableCell>
            <TableCell>
              <CellContent>
                <LabelTb paddingLeft={16}>{utilProfile(item.profile)}</LabelTb>
              </CellContent>
            </TableCell>
            <TableCell>
              <CellContent style={{ paddingLeft: 32 }}>
                {
                  <Popover content={"Editar"} trigger="hover">
                    <ButtonAntd
                      onClick={() => {
                        navigate(`/painel/usuarios/${item.username}`);
                      }}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <EditFilled />
                    </ButtonAntd>
                  </Popover>
                }
                {
                  <Popover content={"Remover"} trigger="hover">
                    <Popconfirm
                      title="Remover"
                      description="Deseja realmente remover o usário?"
                      onConfirm={() => handleDeleteItem(item.username)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <ButtonAntd>
                        <DeleteFilled />
                      </ButtonAntd>
                    </Popconfirm>
                  </Popover>
                }
                {
                  <Popover content={"Ver Detalhes"} trigger="hover">
                    <ButtonAntd
                      onClick={() => {
                        navigate(`/painel/usuarios/detalhes/${item.username}`);
                      }}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <FileTextFilled />
                    </ButtonAntd>
                  </Popover>
                }
              </CellContent>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Container>
  );
}
