import { Col, Divider, Modal, Row, Switch } from "antd";
import { AnticipationContainer, Title } from "../../style";
import { IFormSingularProps } from "../../types";

import { InputForm } from "components/InputDetails";
import { useState } from "react";
import { updateAticipation } from "services/singular/requestUpdateAticipation";

export function AnticipationContainerSingular({
  singular,
}: IFormSingularProps) {
  const [anticipation, setAnticipation] = useState<boolean>(
    singular.enabled_anticipation
  );

  const contentPopoverAnticipationPercent = (
    <div>
      <p>
        Trata-se do percentual máximo da produção do médico,
        <br /> definido pela Singular, permitido para antecipação.
      </p>
    </div>
  );

  const contentPopoverPaymentDate = (
    <div>
      <p>
        Trata-se da data de pagamento geral em que a Singular
        <br /> realiza o pagamento dos honorários para os cooperados.
      </p>
    </div>
  );

  const handleOnChangeAnticipation = async () => {

    await updateAticipation(singular.singular_id, !anticipation).then(
      (data) => {
        if (data) {
          Modal.success({
            title: `Anticipação ${
              !anticipation ? "habilitada" : "desabilitada"
            } com sucesso!`,
            okButtonProps: { type: "text" },
          });
          setAnticipation(!anticipation);
        } else {
          Modal.error({
            title: `Anticipação não pode ser ${
              !anticipation ? "habilitada" : "desabilitada"
            }!`,
            content: `Tente novamente!`,
            okButtonProps: { type: "text" },
          });
        }
      }
    );
  };

  return (
    <AnticipationContainer>
      <Row>
        <Title>Antecipação</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row
        style={{ marginTop: 10 }}
        gutter={{
          lg: 20,
          sm: 20,
          md: 20,
          xl: 20,
          xs: 20,
          xxl: 20,
        }}
        align="bottom"
      >
        <Col>
          <InputForm
            title="Máximo Antecipável"
            popover={{
              content: contentPopoverAnticipationPercent,
              title: "Informação",
            }}
            value={`${singular.anticipation_percentage.toString()}%`}
          />
        </Col>
        <Col>
          <InputForm
            title="Taxa FIDC"
            value={`${singular.tax_fidc.toString()}%`}
          />
        </Col>
        <Col>
          <InputForm
            title="Taxa Unimed Pay"
            value={`${singular.tax_unimed_pay.toString()}%`}
          />
        </Col>
        <Col>
          <InputForm
            title="Comissão Singular"
            value={`${singular.commission.toString()}%`}
          />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20,
          sm: 20,
          md: 20,
          xl: 20,
          xs: 20,
          xxl: 20,
        }}
        align="middle"
      >
        <Col span={3}>Habilitar antecipação</Col>
        <Col>
          <Switch
            onChange={handleOnChangeAnticipation}
            checked={anticipation}
          />
        </Col>
      </Row>
    </AnticipationContainer>
  );
}
