import {RefreshTokenData} from "./types";
import {cognitoRefreshToken} from "../../config/cognito/cognitoAuth";
import {CognitoRefreshToken} from "amazon-cognito-identity-js";

export async function requestRefreshToken(data: RefreshTokenData): Promise<string> {
  const {cognitoUserRefresh} = await cognitoRefreshToken(data);
  const {refreshToken} = data;

  const refreshTokenObject = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });
  return new Promise<string>((resolve, reject) => {
    try {
      cognitoUserRefresh.refreshSession(refreshTokenObject, (err, session) => {
        if (err) {
          reject(err);
          return
        }
        const idToken = session.getIdToken().getJwtToken();
        resolve(idToken);
      });
    } catch (error) {
      reject(error);
    }
  });
}