import {useEffect} from 'react';
import {useAuth} from '../hooks/useAuth';
import Cookies from 'universal-cookie';
import {message} from 'antd';

export const cookies = new Cookies();


export function cookiesSetSession() {
  const sessionExpireTime = 'sessionExpireTime';
  const timeToExpire = new Date(Date.now() + 7200 * 1000);
  cookies.set('sessionCookie', sessionExpireTime, {path: '/', expires: timeToExpire});
}

function SessionActiveChecker() {
  const {logout} = useAuth();

  useEffect(() => {
    checkActiveSession();

    const intervalId = setInterval(() => {
      checkActiveSession();
    }, 3605 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function checkActiveSession() {
    const sessionToken = cookies.get('sessionCookie');
    if (!sessionToken && !window.location.pathname.includes('login')) {
      message.loading('Sessão expirada, por favor, realize o login novamente.', 2.5).then(() => {
        logout();
      });
    }
  }

}

export default SessionActiveChecker;
