import {Col, Divider, Row} from "antd";
import {BankContainer, Title} from "../../style";
import {IFormSingularProps} from "../../types";

import { InputForm } from "components/InputDetails";

export function BankContainerSingular({singular}: IFormSingularProps) {  
  return (
    <BankContainer>
      <Row>
        <Title>Domicílio Bancário</Title>
      </Row>
      <Divider style={{marginTop: 0}}/>
      <Row gutter={{
        lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
      }}
      >
        <Col span={10}>
          <InputForm 
            title="Código e Nome do Banco"
            value={`${singular.account.code} - ${singular.account.name}`}
          />
        </Col>
        <Col span={3}>
        <InputForm 
            title="Agência"
            value={singular.account.branch}
          />
        </Col>
        <Col span={3}>
        <InputForm 
            title="Conta"
            value={singular.account.number}
          />
        </Col>
        <Col span={1}>
        <InputForm 
            title="Dígito"
            value={singular.account.digit}
          />
        </Col>
      </Row>
    </BankContainer>
  )
}