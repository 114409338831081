import { Col, Divider, Row, Spin } from "antd";
import { InputForm } from 'components/InputDetails';
import { useSingular } from "hooks/useSingular";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDoctorById } from "services/doctors/requestDoctorById";
import { requestSpecialitiesByDoctor } from "services/doctors/requestSpecialtiesByDoctor";
import { DoctorUniqueDataResponse, IDoctorsSpecialties } from "services/doctors/types";
import { ISingular } from "services/singular/types";
import { formatDate } from "utils/dateFormat";
import { maskCPF, maskPhone } from "utils/masks";
import { DetailsContainer, Title } from "../../styles";
import { fontSize } from "@mui/system";

export function RegisterDetails() {
  const { id } = useParams();
  const { fetchSingularById } = useSingular();
  const [doctorData, setDoctorData] = useState<DoctorUniqueDataResponse>();
  const [singularData, setSingularData] = useState<ISingular | null>(null);
  const [specialtiesData, setSpecialtiesData] = useState<IDoctorsSpecialties[]>([]);
  const [loading, setLoading] = useState(true);

  async function getLabelForSingularId(singularId: string) {
    if (singularId) {
      try {
        const dataSingular = await fetchSingularById(singularId);
        setSingularData(dataSingular);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    }
  };

  async function getLabelForSpecialtiesId(specialtiesId: string): Promise<IDoctorsSpecialties> {
    const response = await requestSpecialitiesByDoctor(specialtiesId);
    const dataSpecialties = response.data;

    return dataSpecialties;
  }

  useEffect(() => {
    async function fetchDoctorData() {
      if (id) {
        try {
          const response = await getDoctorById(id);
          setDoctorData(response.data);
  
          const singularId = response.data.doctor.singular_id;
          getLabelForSingularId(singularId);
  
          const specialtyPromises = response.data.doctor.specialty_ids.map((item) => {
            return getLabelForSpecialtiesId(item);
          });
  
          const specialties = await Promise.all(specialtyPromises);
  
          setSpecialtiesData(specialties);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching doctor data:', error);
          setLoading(false);
        }
      }
    }
    fetchDoctorData();
  }, [id]);

  return (
    <Spin spinning={loading}>
      {doctorData && (
        <DetailsContainer>
          <Row>
            <Title>Informações de registro</Title>
          </Row>
          <Divider style={{ marginTop: 0 }} />
          <Row
            style={{ marginTop: 20 }}
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
            align="bottom"
          >
            <Col span={7}>
              <InputForm
                title="Nome do Cooperado"
                value={doctorData.doctor.name}
              />
            </Col>
            <Col span={6}>

              <InputForm
                title="CPF"
                value={maskCPF(doctorData.doctor.cpf)}
              />
            </Col>
            <Col span={5}>
              <InputForm
                title="CRM"
                value={doctorData.doctor.crm}
              />
            </Col>
            <Col span={5}>
              <InputForm
                title="Cód. na Prestadora"
                value={doctorData.doctor.provider_code}
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: 20 }}
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
            align="bottom"
          >
            <Col span={3}>
              <InputForm
                title="Data de Nascimento"
                value={formatDate(doctorData.doctor.birth_date)}
              />
            </Col>
            <Col span={4}>
              <InputForm
                title="Gênero"
                value={doctorData.doctor.gender}
              />
            </Col>
            <Col span={doctorData.doctor.secondary_phone ? 3 : 6}>
              <InputForm
                title="Fone Primário"
                value={maskPhone(doctorData.doctor.primary_phone)}
              />
            </Col>
            {doctorData.doctor.secondary_phone && (
              <Col span={3}>
                <InputForm
                  title="Fone Segundario"
                  value={maskPhone(doctorData.doctor.secondary_phone)}
                />
              </Col>
            )}
            <Col span={5}>
              <InputForm
                title="CNES"
                value={doctorData.doctor.cnes}
              />
            </Col>
            <Col span={5}>
              <InputForm
                title="Antecipação"
                value={doctorData.doctor.enabled_anticipation ? 'Habilitado' : 'Desabilitado'}
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: 20 }}
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
            align="bottom"
          >
            <Col span={7}>
              <InputForm
                title="Email"
                value={doctorData.doctor.email}
              />
            </Col>
            <Col span={6}>
              {singularData && (
                <>
                  <InputForm
                    title="Singular"
                    value={singularData?.name}
                  />
                </>
              )}
            </Col>
            {specialtiesData.length > 0 && (
              <>
                {specialtiesData.map(item => (
                  <Col span={5} style={{fontSize: '12px'}}>
                    <InputForm
                      title="Especialidades"
                      value={item.name}
                    />
                  </Col>
                ))}
              </>
            )}
          </Row>
        </DetailsContainer>
      )}
    </Spin>
  );
}
