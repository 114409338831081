import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { Spin } from "antd";

import { Header } from "components/Header";
import { DetailsSingularForm } from './components/DetailsForm';

import { useSingular } from 'hooks/useSingular';
import { ISingular } from "services/singular/types";


import { itemsSingular } from './data';
import {
  BodyContainer,
  ButtonsContainer,
  CloseButton,
  Container,
  HeaderContainer,
  StyledTabs,
  TitleName
} from './styles';
import { getErpById } from 'services/singular/requestErp';

export const Details: React.FC = () => {
  const { id } = useParams() as any;
  const { fetchSingularById } = useSingular();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [singular, setsingular] = useState<ISingular>();
  const [erpName, setErpName] = useState<string>('')
  
  useEffect(() => {
    setLoading(true);
    fetchSingularById(id).then(async (data) => {
      await getErpById(data.erp_id).
      then((res) => {
        setErpName(res.data.name);
        setsingular(data);
        setLoading(false);
      })
    })
  }, []);
  
  return (
    <Container>
      <Spin spinning={loading}>
        <HeaderContainer>
          <Header title="Cadastro de singulares" subtitle="" />
          <TitleName>{singular?.name}</TitleName>
        </HeaderContainer>
        <StyledTabs
          defaultActiveKey="1"
          items={itemsSingular}
        />
        {singular ? (
          <BodyContainer>
          <DetailsSingularForm
           singular={singular}
          />
          {
            <ButtonsContainer>
              <CloseButton
                type="button"
                onClick={() => navigate('/painel/singulares/')}
              >
                Voltar
              </CloseButton>
            </ButtonsContainer>
          }
        </BodyContainer>
        ) : (<Spin spinning={loading} />)}
      </Spin>
    </Container>
  );
}