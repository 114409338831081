import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 15px 21px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const GeneralContainer = styled.div`
  padding: 24px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const TaxContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ComissionContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const AnticipationContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const BankContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const MasterUserContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ContactContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;
