import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Spin } from "antd";
import { Header } from "components/Header";
// import { Modal } from "components/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getLocationByCep } from "services/brasilApi/requestCep";
import { getBank } from "services/bank/requestBank";
import { ISingular } from "services/singular/types";
import { Button as ComponentButton } from "../../components/Button/ProductionWindow";
import { useSingular } from "../../hooks/useSingular";
import {
  buildContent,
  useSingularFormValues,
} from "../../utils/singularHelpers";
import { DetailsSingularForm } from "./components/DetailsForm";
import { itemsSingular } from "./data";
import { schema } from "./schema";
import {
  BodyContainer,
  ButtonsContainer,
  CloseButton,
  Container,
  ContantModal,
  DivButtonModal,
  HeaderContainer,
  StyledTabs,
  SubmitButton,
  TitleName,
} from "./styles";

export function SingularForm() {
  const { id } = useParams() as any;
  const { fetchSingularById, fetchAllErp } = useSingular();
  const { createSingular, updateSingular } = useSingular();
  const navigate = useNavigate();
  const [tabSelected, setTabSelected] = useState("1");
  const [singular, setsingular] = useState<ISingular>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalClosePage, setModalClosePage] = useState<boolean>(false);
  
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    resolver: yupResolver(schema)
  });


  const getLocation = async (cep: string) => {
    const { data } = await getLocationByCep(cep);
    setValue("address", data.street);
    setValue("neighborhood", data.neighborhood);
    setValue("state", data.state);
    setValue("city", data.city);
  };

  const handleSetBank = async (value: string) => {
    const { data } = await getBank(value);
    setValue('bankCode', data.data[0].code)
    setValue('bank', data.data[0].name)
    setValue('bankLabel', `${data.data[0].code} - ${data.data[0].name}`)
  }

  useEffect(() => {
    fetchAllErp()
      .then((r) => setLoading(false))
      .catch((e) => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (id !== "new") {
      fetchSingularById(id).then(setsingular);
    }
    setLoading(false);

    setValue("paymentDay", 10);
    setValue("anticipationPercent", 1);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (singular) {
      useSingularFormValues(singular, setValue);
    }
    setLoading(false);
  }, [singular]);

  useEffect(() => {
    if (errors) {
      if (Object.keys(errors).length > 0) {
        Modal.error({
          content: 'Erro. Tente Novamente!',
        });
      }
    }
  }, [errors]);

  const submitForm = async (data: any) => {
    setLoading(true);

    const content = buildContent(data, id);

    if (id === "new") {
      const response = await createSingular(content).finally(() =>
        setLoading(false)
      );
      if (!response) {
        Modal.error({
          content: 'Erro ao Cadastrar Singular, Tente Novamente!',
        });
        return;
      }
    } else {
      const response = await updateSingular(id, content).finally(() =>
        setLoading(false)
      );
      if (!response) {
        Modal.error({
          content: 'Erro ao Atualizar Singular, Tente Novamente!',
        });
        return;
      }
    }

    Modal.success({
      content: 'Singular Cadastrada com Sucesso!',
      onOk: () => {
        navigate("/painel/singulares");
      }
    });
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <HeaderContainer>
          <Header title="Cadastro de singulares" subtitle="" />
          <TitleName>{singular?.name}</TitleName>
        </HeaderContainer>
        <StyledTabs
          defaultActiveKey="1"
          items={itemsSingular}
          onChange={setTabSelected}
        />
        <BodyContainer onSubmitCapture={handleSubmit(submitForm)}>
          <DetailsSingularForm
            control={control}
            errors={errors}
            getLocation={getLocation}
            register={register}
            setBank={handleSetBank}
          />
          {
            <ButtonsContainer>
              <CloseButton
                type="button"
                onClick={() => setModalClosePage(true)}
              >
                Fechar
              </CloseButton>
              <SubmitButton type="submit">Salvar</SubmitButton>
            </ButtonsContainer>
          }
          <Modal
            title="Seus dados serão perdido!"
            open={modalClosePage}
            onOk={() => {
              setModalClosePage(false);
              navigate("/painel/singulares");
            }}
            onCancel={() => setModalClosePage(false)}
            okText="Ok"
            cancelText="Cancelar"
          >
            <p>Deseja Realmente Fechar?</p>
          </Modal>
        </BodyContainer>
      </Spin>
    </Container>
  );
}
