import styled from 'styled-components';

export const Input = styled.input`
  -webkit-appearance: none;
  outline: none;
  border: none;
  /* background-color: transparent; */
  border: 1px solid ${({theme}) => theme.palette.alternateGray};
  font-size: 16px;
  line-height: 24px;
  height: 40px;  
  width: 100%;

  ::placeholder {
    color: #c0c0c0;
  }
`;

export const Button = styled.button`
  background-color: ${({theme}) => theme.palette.primary};
  outline: none;
  border-width: 0;
  height: 40px;
  margin-top: -1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  color: ${({theme}) => theme.palette.white};
  right: 0;
  width: 103px;
  font-size: 16px;
  line-height: 24px;
`;
