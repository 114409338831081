import { Col, Divider, Row } from "antd";
import { DatePickerForm } from "components/DatePickerForm";
import { InputForm } from "components/InputForm";
import { SelectForm } from "components/SelectForm";
import { useEffect, useState } from "react";
import { getAllSingular } from "services/singular/requestSingular";
import { maskCPF, maskPhone } from "utils/masks";
import { DetailsContainer, Title } from "../../DetailsForm/style";

interface IBankFormProps {
  control: any,
  errors: any,
  setValue: any,
}

export function RegisterContainer({ control, errors, setValue }: IBankFormProps) {
  const [searchedUnimed, setSearchedUnimed] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    searchUnimed();
  }, []);

  const searchUnimed = async () => {
    const { data: { data } } = await getAllSingular(limit, search || '', token || '');

    setSearchedUnimed(data.map((unimed) => ({
      value: unimed.singular_id,
      label: unimed.name,
      code: unimed.unimed_code,
    })));
  };

  return (
    <DetailsContainer>
      <Row>
        <Title>Informações de registro</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={7}>
          <InputForm
            name="name"
            title="Nome cooperado"
            control={control}
            error={(errors.name && errors.name.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={6}>
          <InputForm
            name="cpf"
            title="CPF"
            control={control}
            mask={maskCPF}
            error={(errors.cpf && errors.cpf.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={4}>
          <InputForm
            name="crm"
            title="CRM"
            control={control}
            error={(errors.crm && errors.crm.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="provider_code"
            title="Cód na Prestadora"
            control={control}
            error={(errors.provider_code && errors.provider_code.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={3}>
          <DatePickerForm
            name="birth_date"
            title="Data de Nascimento"
            control={control}
            error={(errors.birth_date && errors.birth_date.message) as string}
            rest={{
              style: { width: '100%', borderRadius: 0 },
              format: 'DD/MM/YYYY',
            }}
          />
        </Col>
        <Col span={4}>
          <SelectForm
            name="gender"
            title="Gênero"
            control={control}
            error={(errors.gender && errors.gender.message) as string}
            rest={{ style: { borderRadius: 0 } }}
            data={[
              {
                label: 'Masculino',
                value: 'MASCULINO',
              },
              {
                label: 'Feminino',
                value: 'FEMININO',
              },
            ]}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="primary_phone"
            title="Fone Primário"
            control={control}
            mask={maskPhone}
            error={(errors.primary_phone && errors.primary_phone.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="secondary_phone"
            title="Fone Secundario"
            control={control}
            mask={maskPhone}
            error={(errors.secondary_phone && errors.secondary_phone.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={7}>
          <InputForm
            name="cnes"
            title="CNES"
            control={control}
            error={(errors.cnes && errors.cnes.message) as string}
            rest={{
              style: { borderRadius: 0 },
            }}
          />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={7}>
          <InputForm
            name="email"
            title="Email"
            control={control}
            error={(errors.email && errors.email.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={6}>
          <SelectForm
            name="unimed_id"
            data={searchedUnimed}
            title="Singular"
            control={control}
            error={(errors.unimed_id && errors.unimed_id.message) as string}
            onSearchValue={searchUnimed}
            onChangeValue={(value: any) => {
              const selectedUnimed = searchedUnimed.find((unimed) => unimed.value === value);
              setValue('unimed_id', selectedUnimed.code);
              setValue('unimed_code', selectedUnimed.code); // Atualize o valor de unimed_code
            }}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={7}>
          <InputForm
            name="unimed_code"
            title="Unimed"
            control={control}
            error={(errors.unimed_code && errors.unimed_code.message) as string}
            rest={{
              style: {
                borderRadius: 0,
                color: '#000',
              },
              disabled: true,
            }}
          />
        </Col>
      </Row>
    </DetailsContainer>
  );
}
