import {  Col, Divider, Row } from "antd";
import { DetailsContainer, Title } from "../../DetailsForm/style";
import { InputForm } from "components/InputForm";
import { maskCEP,  maskOnlyNumbers,  } from "utils/masks";
import { SelectForm } from "components/SelectForm";
import { useEffect, useState } from "react";
import { getAllSingular } from "services/singular/requestSingular";
import { useTheme } from "styled-components";
import { brazilStates } from "../../DetailsForm/data";

interface IBankFormProps {
  control: any,
  errors: any,
  getLocation: any,
}

export function AndressContainer({ control, errors, getLocation }: IBankFormProps) {
  const [searchedUnimed, setSearchedUnimed] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    searchUnimed();
  }, []);

  const searchUnimed = async () => {
    const { data: { data } } = await getAllSingular(limit, search || '', token || '');

    setSearchedUnimed(data.map((unimed) => ({
      value: unimed.singular_id,
      label: unimed.name,
      code: unimed.unimed_code,
    })));
  };

  return (
    <DetailsContainer>
      <Row>
        <Title>Endereço</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={4}>
          <InputForm
            name="zipcode"
            title="CEP"
            control={control}
            error={(errors.zipcode && errors.zipcode.message) as string}
            mask={(value: string) => {
              if (maskOnlyNumbers(value).length === 8) getLocation(maskOnlyNumbers(value));

              return maskCEP(value);
            }}
            rest={{ style: { borderRadius: 0 }, maxLength: 9 }}
          />
        </Col>
        <Col span={7}>
          <InputForm
            name="street"
            title="Logradouro"
            control={control}
            error={(errors.street && errors.street.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={6}>
          <InputForm
            name="city"
            title="Cidade"
            control={control}
            error={(errors.city && errors.city.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={7}>
          <SelectForm
            name="state"
            title="Estado"
            control={control}
            data={brazilStates}
            error={(errors.state && errors.state.message) as string}
            rest={{ style: { borderRadius: 0, width: '100%' } }}
          />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={3}>
          <InputForm
            name="number"
            title="Número"
            control={control}
            error={(errors.number && errors.number.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={6}>
          <InputForm
            name="neighborhood"
            title="Bairro"
            control={control}
            error={(errors.neighborhood && errors.neighborhood.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
        <Col span={7}>
          <InputForm
            name="details"
            title="Complemento"
            control={control}
            error={(errors.details && errors.details.message) as string}
            rest={{ style: { borderRadius: 0 } }}
          />
        </Col>
      </Row>
    </DetailsContainer>
  );
}
