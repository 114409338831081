import { createContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  createPasswordFirstAccess,
  requestResetPassword,
  requestSso,
  resetPasswordWithCognito,
  sendEmailToResetPassword,
} from "../../services/auth/requestAuth";
import {
  FirstAccessData,
  FirstAcessVerify,
  ILoginData,
  INewPassword,
  LoggedUser,
  NewPasswordCognito,
  NewPasswordData,
  NewPasswordResponse,
  RefreshTokenData,
  ResetPasswordData,
} from "../../services/auth/types";
import { clearStorage, setStorage, storageKeys } from "../../utils/storage";
import { IAuthContextData, IAuthProviderProps, IUser } from "./types";
import { isLoginPage } from "../../services/auth/location";
import { message } from "antd";
import { requestLoginCognito } from "../../services/auth/requestLoginCognito";
import { requestRefreshToken } from "../../services/auth/requestRefreshToken";
import { cookies, cookiesSetSession } from "../../utils/sessionCookies";
import { requestSignIn } from "services/auth/auth";
import { requestNewPassword } from "services/auth/requestNewPassword";

export const AuthContext = createContext({} as IAuthContextData);

export function AuthProvider({ children }: IAuthProviderProps) {
  const [signed, setSigned] = useState<boolean>(false);
  const [firstAcess, setFirstAcess] = useState<FirstAcessVerify>({
    isFistAcess: false,
    username: "",
    session: "",
  });
  const [user, setUser] = useState<IUser>();
  const [newPassResponse, setNewPassResponse] = useState<NewPasswordResponse>(
    {} as NewPasswordResponse
  );

  const loginWithCognito = async (login: ILoginData) => {
    try {
      const { data } = await requestSignIn(login);
      if (
        data.challenge_name &&
        data.challenge_name === "NEW_PASSWORD_REQUIRED"
      ) {
        setFirstAcess({
          isFistAcess: true,
          username: login.username,
          session: data.session,
        });
        return;
      }

      const token = {
        token: data.IdToken,
      };
      const refreshToken = data.RefreshToken;
      setStorage(storageKeys.auth, JSON.stringify(token));
      setStorage(storageKeys.refreshToken, refreshToken);
      setStorage(storageKeys.username, JSON.stringify(login.username));
      cookiesSetSession();
      setSigned(true);
    } catch (error: any) {
      message.error("Erro ao realizar o login. Verifique seu usuário e senha.");
      throw error;
    }
  };

  const newPassword = async (form: INewPassword) => {
    try {
      const { data } = await requestNewPassword(form);
      
      const token = {
        token: data.IdToken,
      };
      const refreshToken = data.RefreshToken;
      setStorage(storageKeys.auth, JSON.stringify(token));
      setStorage(storageKeys.refreshToken, refreshToken);
      setStorage(storageKeys.username, JSON.stringify(form.username));
      cookiesSetSession();
      setFirstAcess({
        isFistAcess: false,
        username: "",
        session: "",
      })
      setSigned(true);
    } catch (error: any) {
      message.error("Erro ao alterar a senha. Tente novamente");
      throw error;
    }
  };

  const getRefreshToken = async () => {
    const data: RefreshTokenData = {
      username: localStorage.getItem("username") || "",
      refreshToken: localStorage.getItem("refreshToken") || "",
    };
    try {
      const idToken = await requestRefreshToken(data);
      const token = {
        token: idToken,
      };
      setStorage(storageKeys.auth, JSON.stringify(token));
    } catch (error: any) {
      await logout();
    }
  };

  const logout = async () => {
    clearStorage();
    cookies.remove("sessionCookie", { path: "/" });
    if (!isLoginPage()) window.location.reload();
  };
  const isResetPassword = (message: string) =>
    message === "New password is required";

  const loginByFirstAccess = async (data: FirstAccessData) => {
    try {
      const user = await createPasswordFirstAccess(data);
      setStorage(storageKeys.auth, JSON.stringify(user.data));
      setSigned(true);
      message.success("Aguarde enquanto te redirecionamos!");
    } catch (error: any) {
      message.error("Erro ao realizar o primeiro acesso");
      throw error;
    }
  };

  const resetPassword = async (data: ResetPasswordData) => {
    await requestResetPassword(data);
  };

  const sendResetPasswordCognito = async (email: string) => {
    await sendEmailToResetPassword(email);
  };

  const newPasswordWithCognito = async (data: NewPasswordCognito) => {
    await resetPasswordWithCognito(data);
  };

  const verifyToken = async (token?: string) => {
    try {
      const tokenFromStorage = localStorage.getItem("auth");
      const tokenObject = JSON.parse(tokenFromStorage || "{}");
      const tokenToUse = tokenObject.token || "";

      const response = await requestSso(
        token ? { token } : { token: tokenToUse }
      );

      if (!response.data) {
        return null;
      }

      const authToken = {
        token: response.data?.data?.token,
      };
      setStorage(storageKeys.auth, JSON.stringify(authToken));
      const userData = response.data.data?.user;

      const parsedUser: IUser | undefined =
        typeof userData === "string" ? JSON.parse(userData) : userData;
      setUser(parsedUser);
      setSigned(true);

      return response.data.data.user;
    } catch (err) {
      return null;
    }
  };

  const verifyPermission = (
    permission: string,
    userToVerify?: IUser
  ): boolean => {
    if (permission === "home") {
      return true;
    }

    if (
      userToVerify &&
      userToVerify.role &&
      userToVerify.role.role_has_permissions
    ) {
      const hasPermission = userToVerify.role.role_has_permissions.some(
        (rolePermission) => rolePermission.permission?.name === permission
      );

      return hasPermission;
    }
    return false;
  };

  const value = useMemo(
    () => ({
      loginWithCognito,
      getRefreshToken,
      loginByFirstAccess,
      sendResetPasswordCognito,
      newPasswordWithCognito,
      logout,
      signed,
      resetPassword,
      newPassword,
      newPassResponse,
      verifyToken,
      user,
      verifyPermission,
      firstAcess,
    }),
    [signed, newPassResponse, firstAcess]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
