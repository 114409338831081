import api from '../api';
import { SettlementsByIndexDataResponse } from './types';

export async function getSettlementsByIndex(
  pageSize: number,
  page: number,
  searchSettlements: string,
) {
  return api.get<SettlementsByIndexDataResponse>(`settlements/pagination?pageSize=${pageSize}&page=${page}&settlement=${searchSettlements}`);
}
