import api from '../api';
import { IRolesByIndexDataResponse } from './types';

export async function getRolesByIndex(
  pageSize: number,
  page: number,
  searchRoles: string,
) {
  return api.get<IRolesByIndexDataResponse>(`roles/pagination?pageSize=${pageSize}&page=${page}&role=${searchRoles}`);
}
