/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  EditFilled, LeftOutlined, RightOutlined, UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FiTrash } from 'react-icons/fi';
import { IProps } from './types';
import {
  Container,
  Header,
  Title,
  Items,
  Label,
  Item,
  Table,
  TableHead,
  TableHeadCell,
  Content,
  TableLabel,
  Divider,
  TableCell,
  TableRow,
  CellContent,
  LabelTb,
  ChevronDown,
  Circle,
  SelectedContainer,
  SelectedCol,
  ChevronUp,
  Code,
  SelectedRowContainer,
  SelectedTitle,
  SelectedRow,
  SelectedLabel,
  Address,
  Typography,
  Arrows,
  CarouselContainer,
  Card,
  Badge,
  ButtonAntd,
} from './styles';
import { useTheme } from '../../../../hooks/useTheme';

export function TableContainer({
  data,
  removeDoctor,
}: IProps) {
  const [currentSelected, setCurrentSelected] = useState<number>(-1);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const ref = useRef<any>();
  const [currentPage, setCurrentPage] = useState<number>(0);

  function handleSelect(index: number) {
    setCurrentSelected(index);
  }

  function navigationCarrousel(page: number, length: number) {
    const nextPrevPage = currentPage + (page);

    if (nextPrevPage >= length) {
      ref!.current!.goToSlide(0);
      setCurrentPage(0);
    } else if (nextPrevPage < 0) {
      ref!.current!.goToSlide(length - 1);
      setCurrentPage(length - 1);
    } else {
      setCurrentPage(currentPage + (page));
      ref!.current!.goToSlide(currentPage + (page));
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  return (
    <Container>
      <Table cellPadding={0} cellSpacing={0}>
        <TableHead>
          <TableHeadCell disableLeftPadding width={80}>
            <Content>
              <Divider />
              <TableLabel marginLeft={2}>
                Nome cooperado
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={225}>
            <Content>
              <Divider />
              <TableLabel>
                CPF
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={95}>
            <Content>
              <Divider />
              <TableLabel>
                CRM
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={50}>
            <Content>
              <Divider />
              <TableLabel>
                Ações
              </TableLabel>
            </Content>
          </TableHeadCell>
        </TableHead>
        {
          data?.map((item, index) => {
            const escrowAccount = item.bank.find((bank) => bank.type === 'CONTA_ESCROW');
            const currentAccount = item.bank.find((bank) => bank.type === 'CONTA_CORRENTE');

            return (
              <TableRow>
                {
                currentSelected === index
                  ? (
                    <SelectedContainer colSpan={9}>
                      <SelectedRowContainer>
                        <SelectedCol centralize marginTop={22} width={60}>
                          <ChevronUp
                            onClick={() => handleSelect(-1)}
                            size={35}
                            color={theme.palette.primary}
                          />
                        </SelectedCol>
                        <SelectedCol marginLeft={1}>
                          <SelectedTitle
                            marginTop={22}
                          >
                            COOPERADO
                          </SelectedTitle>
                          <Typography
                            color={theme.palette.primary}
                            width={16}
                            lineHeight={18}
                            fontSize={18}
                            fontWeight={500}
                            marginTop={5}
                            style={{ height: 35 }}
                          >
                            {item.name}
                          </Typography>
                          <SelectedRow width={14} spaceBetween marginTop={5}>
                            <SelectedCol>
                              <SelectedTitle>
                                CPF
                              </SelectedTitle>
                              <SelectedLabel>
                                {item.cpf}
                              </SelectedLabel>
                            </SelectedCol>
                            <SelectedCol>
                              <SelectedTitle>
                                CRM
                              </SelectedTitle>
                              <SelectedLabel>
                                {item.crm}
                              </SelectedLabel>
                            </SelectedCol>
                          </SelectedRow>
                        </SelectedCol>
                        <SelectedCol marginLeft={5}>
                          <SelectedTitle
                            marginTop={22}
                          >
                            CONTATOS
                          </SelectedTitle>
                          <Address style={{ marginTop: 30 }}>
                            {item.email}
                          </Address>
                          <SelectedLabel style={{ marginTop: 10 }}>
                            Fone:
                            {' '}
                            {item.primary_phone}
                          </SelectedLabel>
                          {item.secondary_phone && (
                            <SelectedLabel>
                              Fone:
                              {' '}
                              {item.secondary_phone}
                            </SelectedLabel>
                          )}
                        </SelectedCol>
                        <SelectedCol marginLeft={5}>
                          <SelectedRow marginTop={22}>
                            <SelectedTitle>
                              ESPECIALIDADES
                              {' '}
                              {`(${item.doctorSpecialties.length})`}
                            </SelectedTitle>
                            <Arrows>
                              <LeftOutlined onClick={() => navigationCarrousel(-1, item.doctorSpecialties.length)} style={{ color: currentPage === 0 ? theme.palette.lightGray : theme.palette.text, cursor: 'pointer' }} />
                              <RightOutlined onClick={() => navigationCarrousel(+1, item.doctorSpecialties.length)} style={{ color: currentPage === item.doctorSpecialties.length - 1 ? theme.palette.lightGray : theme.palette.text, cursor: 'pointer' }} />
                            </Arrows>
                          </SelectedRow>
                          <CarouselContainer style={{ width: 300 }}>
                            <CarouselMulti
                              responsive={responsive}
                              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                              ref={ref as any}
                              autoPlay={false}
                              draggable
                            >
                              {
                                item.doctorSpecialties.map((current, i) => (
                                  <Card>
                                    <SelectedLabel>{current.specialty.code}</SelectedLabel>
                                    <Typography fontFamily={theme.fontFamily.roboto} fontWeight={500} marginTop={5} color="rgba(0, 0, 0, 0.8)" fontSize={14} lineHeight={18}>{current.specialty.name}</Typography>
                                  </Card>
                                ))
                              }
                            </CarouselMulti>
                          </CarouselContainer>
                        </SelectedCol>
                        <Divider alternativeLayout />
                        <SelectedCol>
                          <SelectedCol marginTop={22}>
                            <SelectedTitle>
                              DOMICÍLIO BANCÁRIO
                            </SelectedTitle>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color={theme.palette.primary}
                              lineHeight={14}
                              width={5}
                              marginTop={5}
                            >
                              Escrow
                            </Typography>
                            <Address style={{ marginTop: 5 }}>
                              Banco:
                              {' '}
                              {escrowAccount?.code}
                              {' '}
                              |
                              {' '}
                              {escrowAccount?.name}
                            </Address>
                            <Address>
                              Ag:
                              {' '}
                              {escrowAccount?.branch}
                              {' '}
                              | CC:
                              {' '}
                              {`${escrowAccount?.number}-${escrowAccount?.digit}`}
                            </Address>
                          </SelectedCol>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={theme.palette.primary}
                            lineHeight={14}
                            marginTop={9}
                          >
                            Livre Movimentação
                          </Typography>
                          <Address style={{ marginTop: 5 }}>
                            Banco:
                            {' '}
                            {currentAccount?.code}
                            {' '}
                            |
                            {' '}
                            {currentAccount?.name}
                          </Address>
                          <Address>
                            Ag:
                            {' '}
                            {currentAccount?.branch}
                            {' '}
                            | CC:
                            {' '}
                            {`${currentAccount?.number}-${currentAccount?.digit}`}
                          </Address>
                        </SelectedCol>
                        <SelectedCol marginLeft={2} marginTop={22} style={{ marginLeft: 'auto', marginRight: '16px' }}>
                          <ButtonAntd onClick={() => { navigate(`/painel/medicos/${item.id}`); }}>
                            Editar
                          </ButtonAntd>
                          <ButtonAntd
                            style={{ marginTop: 10 }}
                            onClick={() => { removeDoctor(item.id); }}
                          >
                            Excluir
                          </ButtonAntd>
                        </SelectedCol>
                      </SelectedRowContainer>
                    </SelectedContainer>
                  )
                  : (
                    <>
                      <TableCell disableLeftPadding width={8}>
                        <CellContent disablePadding>
                          <ChevronDown
                            onClick={() => handleSelect(index)}
                            size={35}
                            color={theme.palette.grey}
                          />
                          <LabelTb>
                            {item.name}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={225}>
                        <CellContent>
                          <LabelTb bold paddingLeft={16}>
                            {item.cpf}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={95}>
                        <CellContent>
                          <LabelTb paddingLeft={16}>
                            {item.crm}
                          </LabelTb>
                        </CellContent>
                      </TableCell>
                      <TableCell width={106}>
                        <CellContent flexEnd>
                          <FiTrash
                            onClick={() => { removeDoctor(item.id); }}
                            style={{ marginRight: 10 }}
                          />
                          <EditFilled onClick={() => { navigate(`/painel/medicos/${item.id}`); }} />
                        </CellContent>
                      </TableCell>
                    </>
                  )
              }
              </TableRow>
            );
          })
        }
      </Table>
    </Container>
  );
}
