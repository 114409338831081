import { useEffect, useState } from "react";
import {Col, Divider, Row} from "antd";
import {BankContainer, Title} from "../../style";
import {InputForm} from "../../../../../components/InputForm";
import {maskOnlyNumbers} from "../../../../../utils/masks";
import {IFormSingularProps, SelectBank} from "../../types";
import { SelectForm } from "components/SelectForm";

import { getBank } from "services/bank/requestBank";
import { IBank } from "services/bank/types";

export function BankContainerSingular({control, errors, setBank}: IFormSingularProps) {  
  const [dataBank, setDataBank] = useState<SelectBank[]>([]);

  const getTotalBank = async () => {
    const { data } = await getBank();
    setDataBank(data.data.map((e: IBank) => {return { value: e.code, label: `${e.code} - ${e.name}` }}));
  } 

  useEffect(() => {
    getTotalBank();
  }, [])

  const handleGetBank = async (value: string) => {
    const { data } = await getBank(value);
    setDataBank(data.data.map((e: IBank) => {return { value: e.code, label: `${e.code} - ${e.name}` }}));
  }
  
  return (
    <BankContainer>
      <Row>
        <Title>Domicílio bancário</Title>
      </Row>
      <Divider style={{marginTop: 0}}/>
      <Row gutter={{
        lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
      }}
      >
        <Col span={10}>
          <SelectForm
            name="bankLabel"
            data={dataBank}
            title="Código e Nome do Banco"
            control={control}
            error={(errors.bank && errors.bank.message) as string}
            onSearchValue={(value: any) => {
                handleGetBank(value)
            }}
            onChangeValue={(value: any) => {
              setBank(value)
            }}
            rest={{style: { borderRadius: 0 },}}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="agency"
            title="Agência"
            control={control}
            mask={maskOnlyNumbers}
            error={(errors.agency && errors.agency.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="account"
            title="Conta"
            control={control}
            mask={maskOnlyNumbers}
            error={(errors.account && errors.account.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
        <Col span={3}>
          <InputForm
            name="digit"
            title="Dígito"
            control={control}
            mask={maskOnlyNumbers}
            error={(errors.digit && errors.digit.message) as string}
            rest={{style: {borderRadius: 0}}}
          />
        </Col>
      </Row>
    </BankContainer>
  )
}