import api from '../api';
import { ClinicsByIndexDataResponse } from './types';

export async function getClinicsByIndex(
  pageSize: number,
  page: number,
  searchSingular: string,
  searchClinic: string,
  clinicType: string,
) {
  return api.get<ClinicsByIndexDataResponse>(`clinics/pagination?pageSize=${pageSize}&page=${page}&singular=${searchSingular}&clinic=${searchClinic}&clinicType=${clinicType}`);
}
