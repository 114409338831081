import styled from "styled-components";

import LogoUnimedPayWhite from '../../assets/images/logoUnimedPayWhite.svg';

import { Breadcrumb, Layout, Row } from 'antd'
const { Sider, Header, Content } = Layout;

export const LogoWhite = styled.img.attrs({
  src: LogoUnimedPayWhite,
  alt: 'Logo',
})`
  width: 60%;
  margin-top: 20px;
  margin-left: 20%;
  margin-bottom: 20px;
`;

export const LayoutStyle = styled(Layout)`
  display: flex;
`;

export const SiderStyle = styled(Sider)`  
  // FIXAR SIDER 
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  //

  .ant-layout-sider-children {
    background-color: ${({ theme }) => theme.palette.secondary};

    .ant-menu {
      background-color: ${({ theme }) => theme.palette.secondary};
      color: ${({ theme }) => theme.palette.white};

      .ant-menu-item {
        :hover {
          color: ${({ theme }) => theme.palette.primaryLight};
        }

      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          color: ${({ theme }) => theme.palette.primaryLight};
        }
      }

      .ant-menu-item-selected {
        background-color: ${({ theme }) => theme.palette.secondaryDark};
        color: ${({ theme }) => theme.palette.primaryLight};
      }
    }
  }
`

export const StyledHeader = styled(Header)`
  background-color: ${({ theme }) => theme.palette.primary} !important;
  display: flex;
  flex-direction: row;

  padding: 0 24px;
  position: relative;
`;

export const HeaderTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 16pt;
  font-weight: 400;
  letter-spacing: 1px;

  margin: 0;
  color: ${({ theme }) => theme.palette.white};
`;

export const RowUser = styled(Row)`
  display: flex;
  align-items: center;

  position: absolute;
  right: 24px;
  height: 100%;
`

export const UserPhoto = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.background};
`

export const HeaderName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 12pt;
  font-weight: 400;
  letter-spacing: 1px;

  margin: 0 10px;
  color: ${({ theme }) => theme.palette.white};
`;

export const StyledContent = styled(Content)``;

export const StyledBreadcrumb = styled(Breadcrumb)`
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.palette.white};
`;