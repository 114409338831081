import React, { useEffect, useState } from "react";
import { Header } from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Popconfirm, Spin } from "antd";
import { useUser } from "hooks/useUsers";
import {
  BodyContainer,
  Container,
  HeaderContainer,
  StyledTabs,
  TitleName,
  SubmitDelete,
  CloseButton,
  ButtonsContainer,
} from "./styles";
import { DetailsForm } from "./DetailsForm";
import { IUserNew } from "services/users/types";
import { requestDeleteUser } from "services/users/requestDeleteUser";

const items = [
  {
    key: "1",
    label: "Detalhes do Usuário",
  },
];

export function UserDetails() {
  const { id } = useParams() as any;
  const { getUserById } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUserNew>();

  const getUser = async () => {
    const response = await getUserById(id);
    setUser(response);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleDeleteItem = async (id: string) => {
    try {
      await requestDeleteUser(id);
      Modal.success({
        content: 'Usuário Deletado com Sucesso',
      });
      navigate("/painel/usuarios");
    } catch (error) {
      throw new Error;
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <Header title="Usuário" subtitle="Detalhes do Usuário" />

        <TitleName>{user?.name}</TitleName>
      </HeaderContainer>
      <StyledTabs defaultActiveKey="1" items={items} />

      <Spin spinning={loading}>
        <BodyContainer>
          {user && (
            <>
              <DetailsForm user={user} />
              <ButtonsContainer>
                <Popconfirm
                  title="Remover"
                  description="Deseja realmente remover o usário?"
                  onConfirm={() => handleDeleteItem(user.username)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <SubmitDelete
                    type="button"
                  >
                    Deletar
                  </SubmitDelete>
                </Popconfirm>
                <CloseButton
                  type="button"
                  onClick={() => navigate("/painel/usuarios")}
                >
                  Fechar
                </CloseButton>
              </ButtonsContainer>
            </>
          )}
        </BodyContainer>
      </Spin>
    </Container>
  );
}
