import {ILoginData, LoggedUser} from "../../services/auth/types";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import {awsExports} from "./aws-exports";

export async function cognitoAuth(data: ILoginData) {

  const {username, password} = data;

  const poolData = {
    UserPoolId: awsExports.USER_POOL_ID,
    ClientId: awsExports.USER_POOL_APP_CLIENT_ID
  };

  const userPool = new CognitoUserPool(poolData);

  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUserLogin = new CognitoUser(userData);

  return {authenticationDetails, cognitoUserLogin}
}


export async function cognitoRefreshToken(data: ILoginData) {

  const {username} = data;
  const poolData = {
    UserPoolId: awsExports.USER_POOL_ID,
    ClientId: awsExports.USER_POOL_APP_CLIENT_ID
  };

  const userPool = new CognitoUserPool(poolData);


  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUserRefresh = new CognitoUser(userData);

  return {cognitoUserRefresh}
}