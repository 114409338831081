import styled from 'styled-components';
import { Pagination as PaginationRaw } from 'antd';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 2px;

  width: 100%;

  padding: 20px 25px;
  gap: 10px;
`;

export const Pagination = styled(PaginationRaw)`
  margin-left: 15px;
`;

export const PaginationContainer = styled.div`
  display: flex;

  padding-top: 2rem;
  padding-right: 25px;

  align-items: center;

  width: 100%;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.background};
`;

export const TitleSearch = styled.p`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin: 0;
`