import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 15px 21px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const DetailsContainer = styled.div`
  padding: 24px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ParamsContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  margin-left: 10px;
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;

  width: 100%;

  justify-content: flex-end;
  margin-top: 70px;

  padding-right: 20px;
  padding-bottom: 20px;

  gap: 10px;
`;

export const CloseButton = styled.button`
  border: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: solid;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0px;
  color: black;
  padding: 9px 40px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const SubmitButton = styled.button`
  border: 0px;
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 0px;
  color: white;
  padding: 10px 40px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;
