import { Popover } from "antd";
import { Container, Title, DivLabel } from "./style";
import { IPropsInputForm } from "./types";
import { QuestionCircleOutlined } from "@ant-design/icons";

export function InputForm({ value, title, popover }: IPropsInputForm) {
  return (
    <Container>
      <Title>
        {title}
        {popover && (
          <Popover
            content={popover.content}
            title={popover.title}
            trigger="hover"
          >
            <QuestionCircleOutlined
              style={{ marginLeft: 5, cursor: "pointer" }}
            />
          </Popover>
        )}
      </Title>
      <DivLabel>{value}</DivLabel>
    </Container>
  );
}
