import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup
    .string()
    .min(3, 'Digite uma Razão social válida')
    .max(255, 'Digite uma Razão social válida')
    .required('Razão social é obrigatório'),
  provider_code: Yup
    .string()
    .min(1, 'Digite um Código válido')
    .max(20, 'Digite um Código válido')
    .required('Código é obrigatório'),
  cpf: Yup
    .string()
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/g, 'Digite um CPF válido') // CPF or CNPJ
    .required('CPF é obrigatório'),
  birth_date: Yup
    .date()
    .typeError('Digite uma data válida')
    .required('Data é obrigatório'),
  gender: Yup
    .string()
    .oneOf(['MASCULINO', 'FEMININO'])
    .required('Gênero é obrigatório'),
  anticipation: Yup
    .boolean(),
  crm: Yup
    .string()
    .min(1, 'Digite um CRM válido')
    .max(20, 'Digite um CRM válido')
    .required('CRM é obrigatório'),
  cnes: Yup
    .number()
    .typeError('Digite um CNES válido')
    .positive('Digite um CNES válido')
    .integer('Digite um CNES válido')
    .required('CNES é obrigatório'),
  primary_phone: Yup
    .string()
    .min(3, 'Digite um Fone válido')
    .max(20, 'Digite um Fone válido')
    .required('Fone é obrigatório'),
  secondary_phone: Yup
    .string()
    .optional()
    .min(3, 'Digite um Fone válido')
    .max(20, 'Digite um Fone válido')
    .nullable(),
  email: Yup
    .string()
    .email('Digite um Email válido')
    .required('Email é obrigatório'),
  unimed_id: Yup
    .string()
    .uuid()
    .required('Singular é obrigatório'),
});
