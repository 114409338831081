import api from '../api';
import {AllDoctorsDataResponse} from './types';

export async function requestAllDoctors(
  limit: number,
  search: string,
  nextPage: string | null,
) {
  const queryParams = `limit=${limit}${search ? `&search=${encodeURIComponent(search)}` : ''}${nextPage ? `&token=${encodeURIComponent(nextPage)}` : ''}`;
  const url = `api/v1/backoffice/doctors?${queryParams}`;
  return api.get<AllDoctorsDataResponse>(url);
}


export async function requestAllDoctorsNoSearch() {
  return api.get<AllDoctorsDataResponse>('api/v1/backoffice/doctors');
}