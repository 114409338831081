import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Container } from './style';

export function FullScreenLoading() {
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  return (
    <Container>
      <Spin indicator={antIcon} />
    </Container>
  );
}
