import { Input } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Container, Error, StyledLink, Title, TitleContainer,
} from './style';
import { IPropsPasswordInputForm } from './types';

export function PasswordInputForm({
  control,
  name,
  error,
  title,
  showResetPassword,
  rest,
}: IPropsPasswordInputForm) {
  return (
    <Container>
      <Controller
        control={control}
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <>
            <TitleContainer>
              <Title>{title}</Title>
              {showResetPassword && <StyledLink to="/reset-password">Esqueci minha senha</StyledLink>}
            </TitleContainer>

            <Input.Password
              onChange={onChange}
              value={value}
              {...rest}
            />
          </>
        )}
        name={name}
      />
      {error && <Error>{ error }</Error>}
    </Container>
  );
}
