import {createContext, useMemo, useState} from 'react';
import {postCreateDoctor} from '../../services/doctors/requestCreateDoctor';
import {deleteDoctor} from '../../services/doctors/requestDeleteDoctor';
import {deleteDoctorFile} from '../../services/doctors/requestDeleteDoctorFile';
import {getDoctorById as requestGetDoctorById} from '../../services/doctors/requestDoctorById';
import {getFileUrl} from '../../services/doctors/requestDoctorFileUrl';
import {patchUpdateDoctor} from '../../services/doctors/requestUpdateDoctor';
import {
  DoctorById,  DoctorUniqueDataResponse,  ICreateDoctorDataRequest,  IDoctorResume,  IDoctorsSpecialties
} from '../../services/doctors/types';
import {IDoctorContextData, IDoctorProviderProps} from './types';
import {requestAllDoctorsSpecialities, requestAllDoctors} from "../../services/doctors";
import { requestSpecialitiesByDoctor } from 'services/doctors/requestSpecialtiesByDoctor';

export const DoctorContext = createContext({} as IDoctorContextData);

export function DoctorProvider({children}: IDoctorProviderProps) {
  const [allDoctors, setAllDoctors] = useState<IDoctorResume[]>([]);
  const [detailsDoctors, setDetailsDoctors] = useState<DoctorById[]>([]);
  const [countAllDoctors, setCountAllDoctors] = useState(0);
  const [tokenNextPage, setTokenNextPage] = useState('');

  const getAllDoctors = async (
    limit: number,
    search: string,
    nextPage: string | null,
  ) => {
    const {data: {data, item_count, token}} = await requestAllDoctors(limit, search, nextPage);
    setAllDoctors(data);
    setTokenNextPage(token)
    if (item_count !== 0 && token || item_count !== 0 && !token) {
      setCountAllDoctors(item_count);
    }
  };

  const getDoctorById = async (id: string): Promise<DoctorUniqueDataResponse> => {
    const {data} = await requestGetDoctorById(id);
    return data;
  };

  const createDoctor = async (createDoctorData: ICreateDoctorDataRequest) => {
    const {data} = await postCreateDoctor(createDoctorData);
    return data;
  };

  const updateDoctor = async (id: string, createDoctorData: ICreateDoctorDataRequest) => {
    const {data} = await patchUpdateDoctor(id, createDoctorData);

    return data;
  };

  const getPreviewFileUrl = async (id: string, name: string) => {
    const {data} = await getFileUrl(id, name);

    return data;
  };

  const removeDoctorFile = async (id: string, url: string) => {
    const {data} = await deleteDoctorFile(id, url);

    return data;
  };

  const removeDoctor = async (id: string) => {
    const {data} = await deleteDoctor(id);

    return data;
  };

  const getAllDoctorsSpecialties = async (): Promise<IDoctorsSpecialties[]> => {
    const {data: {data}} = await requestAllDoctorsSpecialities();
    return data;
  }

  const getSpecialtiesByDoctor = async (id: string): Promise<IDoctorsSpecialties> => {
    const {data} = await requestSpecialitiesByDoctor(id);
    return data;
  }

  const value = useMemo(() => ({
    getAllDoctors,
    allDoctors,
    detailsDoctors,
    getDoctorById,
    createDoctor,
    updateDoctor,
    countAllDoctors,
    tokenNextPage,
    getPreviewFileUrl,
    removeDoctorFile,
    removeDoctor,
    getAllDoctorsSpecialties,
    getSpecialtiesByDoctor,
  }), [allDoctors, detailsDoctors, countAllDoctors]);

  return (
    <DoctorContext.Provider
      value={value}
    >
      {children}
    </DoctorContext.Provider>
  );
}
