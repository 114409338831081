import { FileTextFilled } from '@ant-design/icons';
import { Popover, Table } from 'antd';
import { Header } from 'components/Header';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { requestGuideById } from 'services/guides/requestGuideById';
import { currencyFormat } from 'utils/currencyFormat';
import { formatDate } from 'utils/dateFormat';
import { guideStatusFormat } from 'utils/guidesHelpers';
import { ButtonAntd, ButtonsContainer, CloseButton, Container, DetailsContainer, HeaderArea, StyledTabs } from './style';
import { guideType } from './types';

export function GuideDetails() {
  const [guideData, setGuideData] = useState<guideType[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchGuideData() {
      try {
        const response = await requestGuideById(id);
        const guideDataFromResponse = response;
  
        if (guideDataFromResponse) {
          const item = guideDataFromResponse;
  
          const guideContent = [{
            key: item.data.doctor_id,
            numberGuide: item.data.operator_guide_number,
            totalValue: currencyFormat(item.data.total_value),
            status: guideStatusFormat(item.data.status),
            tax: `${item.data.tax}%`,
            advanceValue: currencyFormat(item.data.disbursement_amount),
            payDay: formatDate(item.data.payment_date),
            createDate: formatDate(item.data.created_at),
          }];
  
          setGuideData(guideContent);
        }
      } catch (error) {
        console.error('Error fetching guide data:', error);
      }
    }
    fetchGuideData();
  }, [id]);
  

  const items = [
    {
      key: '1',
      label: 'Detalhes da Guia',
    }
  ];

  const columns = [
    {
      title: 'Número da Guia Operadora',
      dataIndex: 'numberGuide',
      key: 'numberGuide',
    },
    {
      title: 'Valor Total',
      dataIndex: 'totalValue',
      key: 'totalValue',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Taxa Aplicada',
      dataIndex: 'tax',
      key: 'tax',
    },
    {
      title: 'Valor Antecipado',
      dataIndex: 'advanceValue',
      key: 'advanceValue',
    },
    {
      title: 'Data do Pagamento',
      dataIndex: 'payDay',
      key: 'payDay',
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: guideType) => (
        <Popover content={"Ver Detalhes do Doutor"} trigger="hover">
          <ButtonAntd
            onClick={() => {
              navigate(`/painel/medicos/detalhes/${record.key}`);
            }}
          >
            <FileTextFilled />
          </ButtonAntd>
        </Popover>
      ),
    },
  ];

    return (
      <>
        <Container>
          <HeaderArea>
            <Header
              title="Guias"
              subtitle="Relação de Guias Geradas"
            />
          </HeaderArea>
          <StyledTabs defaultActiveKey="1" items={items} />
          <DetailsContainer>
            <Table dataSource={guideData} columns={columns} pagination={false} />
          </DetailsContainer>        
        </Container>
        <ButtonsContainer>
          <CloseButton
            type="button"
            onClick={() => navigate('/painel/guias/')}
          >
            Voltar
          </CloseButton>
        </ButtonsContainer>
      </>
    );
}
