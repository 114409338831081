import api from '../api';
import {
  FirstAccessData, IVerifySsoResponse, NewPasswordCognito, NewPasswordData, NewPasswordResponse,
  ResetPasswordData, ResetPasswordResponse, ResponseFirstAccess,
} from './types';

export async function createPasswordFirstAccess(data: FirstAccessData): Promise<ResponseFirstAccess> {
  try {
    const response = await api.post<ResponseFirstAccess>('auth/v2/login/first-access', data);
    // @ts-ignore
    if (response?.name === 'AxiosError') throw response;

    return response?.data;
  } catch (error: any) {
    throw error?.response;
  }
}

export async function sendEmailToResetPassword(email: string) {
  try {
    const response = await api.post('auth/v2/send-confirmation-reset-password', {email});
    // @ts-ignore
    if (response?.name === 'AxiosError') throw response;

  } catch (error: any) {
    throw error?.response;
  }
}

export async function resetPasswordWithCognito(data: NewPasswordCognito) {
  try {
    const response = await api.post('auth/v2/confirm-password', data);
    // @ts-ignore
    if (response?.name === 'AxiosError') throw response;

  } catch (error: any) {
    throw error?.response;
  }
}

export async function requestResetPassword(data: ResetPasswordData) {
  return api.post<ResetPasswordResponse>('auth/reset-password', data);
}

export async function requestNewPassword(data: NewPasswordData) {
  return api.post<NewPasswordResponse>('auth/new-password', data);
}

export async function requestSso(data: { token: string }) {
  return api.post<IVerifySsoResponse>('auth/sso', data);
}
