export const storageKeys = {
  auth: 'auth',
  refreshToken: 'refreshToken',
  username: 'username',
};

export const setStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getStorage = (key: string) => {
  const item = localStorage.getItem(key);

  return item;
};

export const getJsonItem = (key: string) => {
  const item = localStorage.getItem(key);

  if (!item) {
    return {};
  }

  return JSON.parse(item);
};

export const clearStorage = () => {
  localStorage.clear();
};
