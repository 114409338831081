import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import GlobalStyles from './theme/globalStyles';

import ContextProvider from './hooks/ContextProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ContextProvider>
    <GlobalStyles />
    <App />
  </ContextProvider>,
);
