import styled from 'styled-components';
import { Pagination as PaginationRaw, Input, Select } from 'antd';

export const Container = styled.div`

`;

export const Pagination = styled(PaginationRaw)`
  margin-left: 15px;
`;

export const PaginationContainer = styled.div`
  display: flex;

  padding-top: 2rem;
  padding-right: 25px;

  align-items: center;

  width: 100%;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.background};
`;

export const SearchContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;  
  background-color: ${({ theme }) => theme.palette.white};

  padding: 22px 25px 26px;  
  gap: 25px;
`;

export const SearchContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const TitleSearch = styled.p`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin: 0;
`

export const SearchBySingular = styled(Select)`
  width: 100%;

  .ant-select-selector {
    border-radius: 0px !important;
  }
`;

export const SearchByClinic = styled(Input)`
  width: 100%;

  border-radius: 0px;
  height: 40px;
`;
