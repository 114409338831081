import api from '../api';
import {GuidesManyDataResponse} from './types';

export async function getAllGuides(
  limit: number, search: string, nextPage: string | null
) {
  const queryParams = `limit=${limit}${search ? `&search=${encodeURIComponent(search)}` : ''}${nextPage ? `&token=${encodeURIComponent(nextPage)}` : ''}`;
  const url = `/api/v1/backoffice/guides?${queryParams}`;
  return api.get<GuidesManyDataResponse>(url);
}
