import { Button, Col, Divider, Row } from "antd";
import { InputForm } from "components/InputForm";
import { SelectForm } from "components/SelectForm";
import { useEffect, useState } from "react";
import { FiTrash } from "react-icons/fi";
import { requestAllDoctorsSpecialities } from "services/doctors";
import { useTheme } from "styled-components";
import { DetailsContainer, LabelText, Title } from "../../DetailsForm/style";

interface IBankFormProps {
  control: any,
  errors: any,
  setValue: any,
}

export function SpecialtiesContainer({ control, errors, setValue }: IBankFormProps) {
  const theme = useTheme();
  const [specialtySearched, setSpecialtySearched] = useState<any[]>([]);
  const [showSelectForm, setShowSelectForm] = useState(false);
  const [cbo, setCBO] = useState('');
  const [cboSecond, setCBOSecond] = useState('');

  useEffect(() => {
    searchSpecialty();
  }, []);

  const searchSpecialty = async () => {
    const { data: { data } } = await requestAllDoctorsSpecialities();

    setSpecialtySearched(data.map((specialty) => ({
      value: specialty.code,
      label: specialty.name,
      code: specialty.code,
    })));
  };

  const handleAddButtonClick = () => {
    setShowSelectForm(true);
  };

  const handleTrashButtonClick = () => {
    setShowSelectForm(false);
  };

  return (
    <DetailsContainer>
      <Row>
        <Title>Especialidades</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        <Col span={9}>
          <SelectForm
            name="specialties"
            data={specialtySearched}
            title="Especialidade"
            control={control}
            error={(errors.code && errors.code.message) as string}
            onSearchValue={searchSpecialty}
            onChangeValue={(value: any) => {
              setValue('specialties', specialtySearched.find((specialty) => specialty.value === value).code);
              const selectedSpecialty = specialtySearched.find((specialty) => specialty.value === value);
              setValue('cbo', selectedSpecialty.code);
            }}
            rest={{
              style: { borderRadius: 0 }
            }}
          />
        </Col>

        <Col span={2}>
          <InputForm
            name="cbo"
            title="CBO"
            control={control}
            value={cbo}
            onChange={(e) => setCBO(e.target.value)}
            rest={{
              style: { borderRadius: 0 },
              disabled: true
            }}
          />
        </Col>
        
        <Col
          span={3}
          style={{ marginTop: 22 }}
        >
          <Button
            type="default"
            style={{
              borderRadius: 0,
              backgroundColor: theme.palette.primary,
              color: theme.palette.white,
              display: showSelectForm ? 'none' : 'block',
            }}
            disabled={showSelectForm}
            onClick={handleAddButtonClick}
          >
            Adicionar Mais Especialidade
          </Button>
        </Col>
      </Row>
      
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >
        {showSelectForm && (
          <>
            <Col span={9}>
              <SelectForm
                name="specialtiesSecond"
                data={specialtySearched}
                title="Especialidade"
                control={control}
                error={(errors.specialtyName && errors.specialtyName.message) as string}
                onSearchValue={searchSpecialty}
                onChangeValue={(value: any) => {
                  setValue('specialtiesSecond', specialtySearched.find((specialty) => specialty.value === value).code);
                  const selectedSpecialty = specialtySearched.find((specialty) => specialty.value === value);
                  if (selectedSpecialty) {
                    setValue('specialtiesSecond', selectedSpecialty.label);
                  }
                  setValue('cboSecond', selectedSpecialty.code);
                }}
                rest={{ style: { borderRadius: 0 } }}
              />
            </Col>

            <Col span={2}>
              <InputForm
                name="cboSecond"
                title="CBO"
                control={control}
                value={cboSecond}
                onChange={(cboValue) => setCBOSecond(cboValue.target.value)}
                rest={{
                  style: { borderRadius: 0 },
                  disabled: true
                }}
              />
            </Col>

            <Col
              span={2}
              style={{ marginTop: 25 }}
            >
              <FiTrash
                size={23}
                color={theme.palette.grey}
                onClick={handleTrashButtonClick}
              />
            </Col>
          </>
        )}
      </Row>

      <Row
        style={{ marginTop: 10, marginLeft: 0 }}
        gutter={{
          lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
        }}
      >        
        <Col
          span={9}
          style={{ paddingLeft: 0 }}
        >
          <LabelText>Apenas duas especialidades serão inseridas</LabelText>
        </Col>
      </Row>
    </DetailsContainer>
  );
}
