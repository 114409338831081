import {
  SettingOutlined,
} from '@ant-design/icons';
import { Tooltip, } from "antd";
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import { getGuideStatusColor, guideReasonFormat, guideStatusFormat } from "../../../utils/guidesHelpers";
import {
  CellContent,
  Circle,
  Container,
  Content,
  Divider,
  Header,
  LabelTb,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableLabel,
  TableRow,
  Title,
} from './styles';
import { IProps } from './types';

export function TableGuide({
  title,
  data,
}: IProps) {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <Title>
          {title}
        </Title>
      </Header>
      <Table cellPadding={0} cellSpacing={0}>
        <TableHead>
          <TableHeadCell width={225}>
            <Content>
              <Divider />
              <TableLabel>
                Número da Guia
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={185}>
            <Content>
              <Divider />
              <TableLabel>
                Status Médico
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={185}>
            <Content>
              <Divider />
              <TableLabel>
                Status Guia
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={106}>
            <Content>
              <Divider />
              <TableLabel>
                Ações
              </TableLabel>
            </Content>
          </TableHeadCell>
        </TableHead>
        {
          data!.map((item, index) => (
            <TableRow>
              <TableCell width={200}>
                <CellContent>
                  <LabelTb paddingLeft={16}>
                    {item.operator_guide_number}
                  </LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <LabelTb paddingLeft={16}>
                    {item.doctor_id ? 'Cadastrado' : 'Não Cadastrado'}
                  </LabelTb>
                </CellContent>
              </TableCell>
              <TableCell width={95}>
                <CellContent>
                  <Tooltip
                    title={item.status === 'FAILED' || item.status === 'INVALID' ? guideReasonFormat(item.reason) : ''}>
                    <LabelTb paddingLeft={16}>
                      <Circle
                        active={true}
                        color={getGuideStatusColor(item.status)}
                      />
                      {guideStatusFormat(item.status)}
                    </LabelTb>
                  </Tooltip>
                </CellContent>
              </TableCell>
              <TableCell width={106}>
                <CellContent>
                  {item.status !== 'FAILED' && (
                    <SettingOutlined
                      style={{ marginLeft: 20, marginRight: 10 }}
                      onClick={() => {
                        navigate(`/painel/guias/${item.guide_id}`);
                      }}
                    />
                  )}
                </CellContent>
              </TableCell>
            </TableRow>
          ))
        }
      </Table>
    </Container>
  );
}
