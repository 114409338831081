import { AnticipationContainer, Title } from "../../style";
import { Col, Divider, Popover, Row, Switch } from "antd";
import { IFormSingularProps } from "../../types";
import { Controller } from "react-hook-form";
import { InputNumberForm } from "../../../../../components/InputNumberForm";
import { SelectForm } from "../../../../../components/SelectForm";
import { paymentDays } from "../../DetailsForm/data";

import { InputForm } from "../../../../../components/InputForm";
import { filterCurrencyAmountInput } from "../../../../../utils/filterCurrencyAmountInput";
import { QuestionCircleOutlined } from "@ant-design/icons";

export function AnticipationContainerSingular({
  control,
  errors,
}: IFormSingularProps) {
  const contentPopoverAnticipationPercent = (
    <div>
      <p>
        Trata-se do percentual máximo da produção do médico,
        <br /> definido pela Singular, permitido para antecipação.
      </p>
    </div>
  );

  const contentPopoverPaymentDate = (
    <div>
      <p>
        Trata-se da data de pagamento geral em que a Singular
        <br /> realiza o pagamento dos honorários para os cooperados.
      </p>
    </div>
  );

  return (
    <AnticipationContainer>
      <Row>
        <Title>Antecipação</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row
        style={{ marginTop: 10 }}
        gutter={{
          lg: 20,
          sm: 20,
          md: 20,
          xl: 20,
          xs: 20,
          xxl: 20,
        }}
        align="bottom"
      >
        <Controller
          name="anticipation"
          control={control}
          render={({ field: { value } }) => (
            <>
              <Col span={3}>
                <InputForm
                  name="anticipationPercent"
                  title="Percentual Antecipável"
                  popover={{
                    content: contentPopoverAnticipationPercent,
                    title: "Informação"
                  }}
                  control={control}
                  error={
                    (errors.anticipationPercent &&
                      errors.anticipationPercent.message) as string
                  }
                  mask={filterCurrencyAmountInput}
                  rest={{
                    style: { borderRadius: 0, width: "100%" },
                    addonAfter: "%",
                  }}
                />
              </Col>
              <Col span={3}>
                <InputForm
                  name="taxFIDCProcedure"
                  title="Taxa FIDC"
                  control={control}
                  error={
                    (errors.taxFIDCProcedure &&
                      errors.taxFIDCProcedure.message) as string
                  }
                  mask={filterCurrencyAmountInput}
                  rest={{
                    style: { borderRadius: 0, width: "100%" },
                    addonAfter: "%",
                  }}
                />
              </Col>
              <Col span={3}>
                <InputForm
                  name="taxUnimedPayProcedure"
                  title="Taxa Unimed Pay"
                  control={control}
                  error={
                    (errors.taxUnimedPayProcedure &&
                      errors.taxUnimedPayProcedure.message) as string
                  }
                  mask={filterCurrencyAmountInput}
                  rest={{
                    style: { borderRadius: 0, width: "100%" },
                    addonAfter: "%",
                  }}
                />
              </Col>
              {errors.sumOfTaxes && (
                <Col span={24}>
                  <br />
                  <span style={{ color: "red" }}>
                    {errors.sumOfTaxes.message}
                  </span>
                </Col>
              )}
              <Col span={3}>
                <InputForm
                  name="taxComissionProcedure"
                  title="Comissão singular"
                  control={control}
                  error={
                    (errors.taxComissionProcedure &&
                      errors.taxComissionProcedure.message) as string
                  }
                  mask={filterCurrencyAmountInput}
                  rest={{
                    style: { borderRadius: 0, width: "100%" },
                    addonAfter: "%",
                  }}
                />
              </Col>
            </>
          )}
        />
      </Row>
    </AnticipationContainer>
  );
}
