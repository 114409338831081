import { FileTextFilled } from '@ant-design/icons';
import { Popover, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { requestGuideByDoctor } from 'services/guides/requestGuideByDoctor';
import { IGuide } from 'services/guides/types';
import { formatDate } from 'utils/dateFormat';
import { guideStatusFormat } from 'utils/guidesHelpers';
import { ButtonAntd, ButtonsContainer, CloseButton, Container, DetailsContainer, Space } from './style';
import { guideType } from './types';

export function GuideDetails() {
  const [guideData, setGuideData] = useState<guideType[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchGuideData() {
      try {
        const response = await requestGuideByDoctor(id);
        const guideContent = response.data.data.map((item: IGuide) => {
          return {
            key: item.guide_id,
            numberGuide: item.operator_guide_number,
            status: guideStatusFormat(item.status),
            createDate: formatDate(item.created_at),
          }
        })
        setGuideData(guideContent);
      } catch (error) {
        console.error('Error fetching guide data:', error);
      }
    }
    fetchGuideData();
  }, []);

  const columns = [
    {
      title: 'Número da Guia Operadora',
      dataIndex: 'numberGuide',
      key: 'numberGuide',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: guideType) => (
        <Popover content={"Ver Detalhes da Guia"} trigger="hover">
          <ButtonAntd
            onClick={() => {
              navigate(`/painel/guias/${record.key}`);
            }}
          >
            <FileTextFilled />
          </ButtonAntd>
        </Popover>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Space />
        <DetailsContainer>
          <Table dataSource={guideData} columns={columns} />
        </DetailsContainer>
        <ButtonsContainer>
          <CloseButton
            type="button"
            onClick={() => navigate('/painel/medicos/')}
          >
            Voltar
          </CloseButton>
        </ButtonsContainer>
      </Container>
    </>
  );
}
