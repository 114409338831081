import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup
    .string()
    .trim()
    .min(3, 'Digite um Nome válido')
    .max(255, 'Digite um Nome válido')
    .required('Nome é obrigatório'),
  code: Yup
    .string()
    .trim()
    .max(255, 'Digite um Código válido')
    .required('Código é obrigatório'),
  codeAns: Yup
    .string()
    .trim()
    .max(255, 'Digite um ANS válido')
    .required('ANS é obrigatório'),
  cnpj: Yup
    .string()
    .trim()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/g, 'Digite um CNPJ válido') // CNPJ
    .required('CNPJ é obrigatório'),
  anticipation: Yup
    .boolean(),
  anticipationPercent: Yup
    .number()
    .when('anticipation', {
      is: true,
      then: Yup
        .number()
        .typeError('Digite um Valor máximo de antecipação válido')
        .min(0, 'Digite um Valor máximo de antecipação válido')
        .max(100, 'Digite um Valor máximo de antecipação válido')
        .required('Valor máximo de antecipação é obrigatório'),
    }),
  paymentDay: Yup
    .number()
    .when('anticipation', {
      is: true,
      then: Yup
      .number()
      .typeError('Digite uma dia válido para a data de pagamento')
      .default(10)
      .required('Dia de pagamento é obrigatório'),
    }),
  taxComissionProcedure: Yup
    .number()
    .typeError('Digite uma Comissão da Singular válida')
    .min(0, 'Digite uma Comissão da Singular válida')
    .max(100, 'Digite uma Comissão da Singular válida')
    .required('Comissão da Singular é obrigatório'),
  taxFIDCProcedure: Yup
    .number()
    .typeError('Digite uma Taxa do FIDC válida')
    .min(0, 'Digite uma Taxa do FIDC válida')
    .max(2.5, 'Digite uma Taxa do FIDC válida')
    .required('Taxa do FIDC é obrigatória'),
  taxUnimedPayProcedure: Yup
    .number()
    .typeError('Digite uma Taxa da Unimed Pay válida')
    .min(0, 'Digite uma Taxa da Unimed Pay válida')
    .max(2.5, 'Digite uma Taxa da Unimed Pay válida')
    .required('Taxa da Unimed Pay é obrigatória'),

  sumOfTaxes: Yup.number().test('sum-of-taxes', 'A soma das taxas não pode ser superior a 2.50%', function (value) {
    const {taxFIDCProcedure, taxUnimedPayProcedure} = this.parent;
    const sumOfTaxes = (taxFIDCProcedure || 0) + (taxUnimedPayProcedure || 0);
    return sumOfTaxes <= 2.5;
  }),

// Address
  city: Yup
    .string()
    .trim()
    .min(3, 'Digite uma Cidade válida')
    .max(255, 'Digite uma Cidade válida')
    .required('Cidade é obrigatório'),
  details:
    Yup
      .string()
      .trim()
      .max(255, 'Digite um Complemento válido')
      .optional(),
  neighborhood:
    Yup
      .string()
      .trim()
      .min(3, 'Digite um Bairro válido')
      .max(255, 'Digite um Bairro válido')
      .required('Bairro é obrigatório'),
  addressNumber:
    Yup
      .string()
      .trim()
      .max(10, 'Digite um Número válido')
      .required('Número é obrigatório'),
  state:
    Yup
      .string()
      .trim()
      .length(2, 'Digite um Estado válido')
      .required('Estado é obrigatório'),
  address:
    Yup
      .string()
      .trim()
      .min(3, 'Digite um Logradouro válido')
      .max(255, 'Digite um Logradouro válido')
      .required('Logradouro é obrigatório'),
  cep:
    Yup
      .string()
      .trim()
      .matches(/^[0-9]{5}-[0-9]{3}$/g, 'Digite um CEP válido')
      .required('CEP é obrigatório'),
  erpId:
    Yup
      .string()
      .required('ERP é obrigatório'),

  // Bank
  bankLabel: 
    Yup
      .string()
      .trim()
      .min(3, 'Digite um Banco válido')
      .max(50, 'Digite um Banco válido')
      .required('Banco é obrigatório'),
  bankCode:
    Yup
      .string()
      .trim()
      .max(20, 'Digite um Código válido')
      .required('Código é obrigatório'),
  bank:
    Yup
      .string()
      .trim()
      .min(3, 'Digite um Banco válido')
      .max(50, 'Digite um Banco válido')
      .required('Banco é obrigatório'),
  agency:
    Yup
      .string()
      .trim()
      .required('Agência é obrigatório'),
  account:
    Yup
      .string()
      .trim()
      .required('Conta é obrigatório'),
  digit:
    Yup
      .string()
      .trim()
      .length(1, 'Digite um Dígito válido')
      .required('Dígito é obrigatório'),
  // Contacts
  contacts:
    Yup.array().of(Yup.object().shape({
      // contactType: Yup
      //   .string()
      //   .trim()
      //   .min(3, 'Digite um valor válido para o contato')
      //   .max(30, 'Digite um valor válido para o contato')
      //   .required('Classe é obrigatório'),
      contactName: Yup
        .string()
        .trim()
        .min(3, 'Digite um Nome válido para o contato')
        .max(255, 'Digite um valor válido para o contato')
        .required('Nome é obrigatório'),
      // contactPosition: Yup
      //   .string()
      //   .trim()
      //   .min(3, 'Digite um Cargo válido para o contato')
      //   .max(30, 'Digite um Cargo válido para o contato')
      //   .required('Cargo é obrigatório'),
      contactPhone: Yup
        .string()
        .trim()
        .min(3, 'Digite um telefone válido para o contato')
        .max(20, 'Digite um telefone válido para o contato')
        .required('Telefone é obrigatório'),
      contactEmail: Yup
        .string()
        .trim()
        .email('Digite um Email válido')
        .required('Email é obrigatório'),

    })),
  masterUserCpf:
    Yup
      .string()
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/g, 'Digite um CPF válido')
      .required('CPF é obrigatório'),
  masterUserEmail:
    Yup
      .string()
      .trim()
      .email('Digite um Email válido')
      .required('Email é obrigatório'),
  masterUserName:
    Yup
      .string()
      .trim()
      .min(3, 'Digite um Nome válido para o usuário master')
      .max(255, 'Digite um valor válido para o usuário master')
      .required('Nome do usuário é obrigatório'),


});


