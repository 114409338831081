import { createContext, useMemo, useState } from 'react';
import { requestGetSettlementById } from 'services/settlements/requestSettlement';
import { getSettlementsByIndex } from 'services/settlements/requestSettlementByIndex';
import { ISettlement } from 'services/settlements/types';
import { ISettlementContextData, ISettlementProviderProps } from './types';

export const SettlementContext = createContext({} as ISettlementContextData);

export function SettlementProvider({ children }: ISettlementProviderProps) {
  const [allSettlements, setAllSettlements] = useState<ISettlement[]>([]);
  const [countAllSettlements, setCountAllSettlements] = useState(0);

  const getAllSettlements = async (
    pageSize: number,
    page: number,
    searchSettlements: string,
  ) => {
    const { data: { data: { data, total } } } = await getSettlementsByIndex(
      pageSize,
      page,
      searchSettlements,
    );

    setAllSettlements(data);
    setCountAllSettlements(total);
  };

  const getSettlementById = async (id: string) => {
    const { data: { data } } = await requestGetSettlementById(id);

    return (data);
  };

  const value = useMemo(() => ({
    getAllSettlements,
    allSettlements,
    countAllSettlements,
    getSettlementById,
  }), [allSettlements, countAllSettlements]);

  return (
    <SettlementContext.Provider
      value={value}
    >
      {children}
    </SettlementContext.Provider>
  );
}
