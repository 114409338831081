/* eslint-disable no-unused-vars */
import {
  PlusOutlined,
} from '@ant-design/icons';
import 'react-multi-carousel/lib/styles.css';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../../components/Button';
import {CellContent, Container, Header, Items, Table, TableRow, Title, Typography} from '../styles';
import {IProps} from '../types';
import {TableHeadSingular} from "../TableHead";
import { TableRowSingular } from "../TableRow";

export function TableContainer({
  title,
  allSingular,
  onClickRemove
}: IProps) {
  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <Title>
          {title}
        </Title>
        <Items>
          <Button onClick={() => {
            navigate('/painel/singulares/new');
          }}>
            <PlusOutlined style={{marginRight: 10}}/>
            Nova singular
          </Button>
        </Items>
      </Header>
      <Table cellPadding={0} cellSpacing={0}>
        {allSingular && allSingular.length > 0 ? (
          <>
            <TableHeadSingular/>
            <TableRowSingular allSingular={allSingular} onClickRemove={onClickRemove}/>
          </>
        ) : (
          <TableRow>
            <Typography
              lineHeight={18}
              marginTop={22}
              fontSize={18}
              fontWeight={500}
              marginLeft={400}
              width={50}
            >
              Nenhuma singular encontrada, tente novamente.
            </Typography>
          </TableRow>
        )}
      </Table>

    </Container>
  );
}
