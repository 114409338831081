import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import { InputForm } from '../../../components/InputForm';
import { schema } from './schema';
import {
  FormStyled, SubmitButton, ResetPasswordContainer, StyledLink,
} from './style';
import { IPropsForm } from './types';
import { PasswordInputForm } from '../../../components/PasswordInputForm';

export function LoginForm({
  handleSubmitForm,
} : IPropsForm) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <FormStyled onSubmit={handleSubmit(handleSubmitForm)}>
      <Space direction="vertical">
        <InputForm
          name="username"
          error={(errors.username && errors.username.message) as string}
          control={control}
          title="USUÁRIO"
          rest={{
            placeholder: 'Unimed',
            type: 'text',
            size: 'large',
          }}
        />
        <PasswordInputForm
          name="password"
          error={(errors.password && errors.password.message) as string}
          control={control}
          title="SENHA"
          rest={{
            placeholder: '********',
            size: 'large',
          }}
        />
      </Space>

      <ResetPasswordContainer>
        <StyledLink to="/reset-password">Esqueci minha senha</StyledLink>
      </ResetPasswordContainer>

      <SubmitButton>
        Entrar
      </SubmitButton>
    </FormStyled>
  );
}
