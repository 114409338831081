import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 24px;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Title = styled.h2`
  font-size: 24px;

  height: 34px;
  margin: 0;

  font-family: 'Sf Pro';

  font-weight: 590;
`;

export const SubTitle = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.lightGray};

  margin: 4px 0 0 0;
`;
