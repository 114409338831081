import {createContext, useMemo, useState} from 'react';
import {postCreateSingular} from 'services/singular/requestCreateSingular';
import {putUpdateSingular} from 'services/singular/requestUpdateSingular';
import {deleteSingular} from 'services/singular/requestDeleteSingular';
import {getSingularById} from '../../services/singular/requestSingularById';
import {
  ICreateSingularDataRequest, IErp,
  ISingular, ISingularResume,
} from '../../services/singular/types';
import {ISingularContextData, ISingularProviderProps} from './types';
import {getAllSingular} from "../../services/singular/requestSingular";
import {getSingularByName} from "../../services/singular/requestSingularSearch";
import {getAllErp} from "../../services/singular/requestErp";

export const SingularContext = createContext({} as ISingularContextData);

export function SingularProvider({children}: ISingularProviderProps) {
  const [allSingular, setAllSingular] = useState<ISingularResume[]>([]);
  const [allErp, setAllErp] = useState<IErp[]>([]);
  const [countAllSingular, setcountAllSingular] = useState(0);
  const [tokenNextPage, setTokenNextPage] = useState('');

  const fetchAllSingular = async (limit: number,
                                  search: string,
                                  nextPage: string | null) => {
    const {data: {data, item_count, token}} = await getAllSingular(limit, search, nextPage);
    setAllSingular(data);
    setTokenNextPage(token);
    if (item_count !== 0 && token || item_count !== 0 && !token) {
      setcountAllSingular(item_count);
    }
  };

  const fetchSingularById = async (id: string): Promise<ISingular> => {
    const {data} = await getSingularById(id);
    return data
  };

  const fetchAllErp = async () => {
    if (allErp.length === 0) {
      const {data: {data, item_count, token}} = await getAllErp();
      setAllErp(data);
    }
  };
  
  const fetchSingularByName = async (name: string): Promise<ISingularResume[]> => {
    const {data: {data}} = await getSingularByName(name);
    return data
  };


  const createSingular = async (createSingularData: ICreateSingularDataRequest) => {
    const {data} = await postCreateSingular(createSingularData);

    return data;
  };

  const updateSingular = async (id: string, createSingularData: ICreateSingularDataRequest) => {
    const {data} = await putUpdateSingular(id, createSingularData);

    return data;
  };

  const removeSingular = async (id: string) => {
    const {data} = await deleteSingular(id);

    return data;
  }

  const value = useMemo(() => ({
    fetchAllSingular,
    fetchSingularByName,
    fetchAllErp,
    allSingular,
    allErp,
    fetchSingularById,
    createSingular,
    updateSingular,
    countAllSingular,
    tokenNextPage,
    removeSingular,
  }), [allSingular, countAllSingular, allErp]);

  return (
    <SingularContext.Provider
      value={value}
    >
      {children}
    </SingularContext.Provider>
  );
}
