import {ConfigProvider} from 'antd';
import {ThemeProvider} from 'styled-components';
import locale from 'antd/locale/pt_BR';
import {useTheme} from './hooks/useTheme';
import {Routes} from './routes';
import {setInterceptors} from './services/interceptors';
import 'dayjs/locale/pt-br';
import SessionActiveChecker from "./utils/sessionCookies";

export function App() {
  const {theme} = useTheme();

  setInterceptors();
  SessionActiveChecker();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.palette.primary,
        },
      }}
      locale={locale}
    >
      <ThemeProvider theme={theme}>
        <Routes/>
        
      </ThemeProvider>
    </ConfigProvider>
  );
}
