import { Col, Spin } from "antd";
import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { useDoctor } from "../../hooks/useDoctor";
import { Table } from "./Table";
import {
  Container,
  SearchByName,
  SearchBySpecialty,
  SearchContainer,
  SearchSingular,
  TitleSearch,
  SearchContainerTwo,
} from "./styles";
import { CustomPagination } from "../../components/Pagination/Pagination";
import { useSingular } from "../../hooks/useSingular";

export function Doctor() {
  const {
    getAllDoctors,
    allDoctors,
    countAllDoctors,
    removeDoctor,
    tokenNextPage,
    getAllDoctorsSpecialties,
  } = useDoctor();

  const { fetchSingularByName } = useSingular();
  const [searchedUnimed, setSearchedUnimed] = useState<any[]>([]);
  const [searchedSpecialty, setSearchedSpecialty] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const searchUnimed = async (name: string) => {
    const data = await fetchSingularByName("");
    setSearchedUnimed(
      data.map((unimed) => ({
        value: unimed.singular_id,
        label: unimed.name,
      }))
    );
  };

  const searchSpecialty = async (name: string) => {
    const data = await getAllDoctorsSpecialties("");
    setSearchedSpecialty(
      data.map((doctorSpecilty) => ({
        value: doctorSpecilty.code,
        label: doctorSpecilty.name,
      }))
    );
  };

  const onClickRemove = async (id: string) => {
    await removeDoctor(id);
    getAllDoctors(limit, search || "", nextPage || "");
  };

  useEffect(() => {
    setLoading(true);
    getAllDoctors(limit, search || "", nextPage || "")
      .then((r) => setLoading(false))
      .catch((e) => setLoading(false));
  }, [limit, search, nextPage]);

  useEffect(() => {
    setLoading(true);
    searchUnimed("")
      .then((r) => setLoading(false))
      .catch((e) => setLoading(false));
    searchSpecialty("")
      .then((r) => setLoading(false))
      .catch((e) => setLoading(false));
  }, []);

  return (
    <Container>
      <Spin spinning={loading}>
        <Header
          title="Médicos Cooperados"
          subtitle="Relação de Médicos Cooperado por Singular"
        />
        <SearchContainer>
          <TitleSearch style={{ marginRight: "16px" }}>Cooperado</TitleSearch>
          <Col span={9}>
            <SearchByName
              onchange={setSearch}
              value={search}
              placeholder="Pesquise por nome, CRM ou CPF "
            />
          </Col>
          <TitleSearch>Singular</TitleSearch>
          <Col span={9}>
            <SearchSingular
              allowClear
              size="large"
              options={searchedUnimed}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label.localeCompare(optionB.label)
              }
              optionFilterProp="label"
              onChange={(value: any) => setSearch(value)}
              placeholder="Selecione singular"
              showSearch
            />
          </Col>
        </SearchContainer>
        <SearchContainerTwo>
          <TitleSearch>Especialidade</TitleSearch>
          <Col span={9}>
            <SearchBySpecialty
              allowClear
              onChange={(value: any) => setSearch(value)}
              size="large"
              options={searchedSpecialty}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label.localeCompare(optionB.label)
              }
              optionFilterProp="label"
              placeholder="Selecione especialidade"
              showSearch
            />
          </Col>
        </SearchContainerTwo>
        {allDoctors && (
          <>
            <Table
              title="Lista de Cooperados"
              allDoctors={allDoctors}
              onClickRemove={onClickRemove}
              allSingularData={searchedUnimed}
            />
            <CustomPagination
              countAllItems={countAllDoctors}
              limit={limit}
              tokenNextPage={tokenNextPage}
              setLimit={setLimit}
              setNextPage={setNextPage}
            />
          </>
        )}
      </Spin>
    </Container>
  );
}
