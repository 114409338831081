import {Col, Divider, Row} from "antd";
import {MasterUserContainer, Title} from "../../style";
import {IFormSingularProps} from "../../types";

import { InputForm } from "components/InputDetails";
import { maskCPF } from "utils/masks";

export function MasterContainerSingular({singular}: IFormSingularProps) {
  return (
    <MasterUserContainer>
      <Row>
        <Title>Usuário Master</Title>
      </Row>
      <Divider style={{marginTop: 0}}/>
      <Row gutter={{
        lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
      }}
      >
        <Col span={8}>
          <InputForm 
            title="Nome"
            value={singular.master_user.name}
          />
        </Col>
        <Col span={8}>
        <InputForm 
            title="Email"
            value={singular.master_user.email}
          />
        </Col>
        <Col span={4}>
        <InputForm 
            title="CPF"
            value={maskCPF(singular.master_user.cpf)}
          />
        </Col>
      </Row>
    </MasterUserContainer>
  )
}