import api from '../api';
import { IPermissionDataResponse, IPermissionsDataResponse } from './types';

export async function requestGetPermissionById(id: string) {
  return api.get<IPermissionDataResponse>(`permissions/${id}`);
}

export async function requestGetPermissionByName(name: string) {
  return api.get<IPermissionsDataResponse>(`permissions?name=${name}`);
}

export async function requestGetPermissionAll() {
  return api.get<IPermissionsDataResponse>('permissions');
}
