import { useContext } from 'react';
import { GuideContext, GuideProvider } from './GuideContext';

function useGuide() {
  const guides = useContext(GuideContext);

  return guides;
}

export { useGuide, GuideProvider };
