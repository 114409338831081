import { createGlobalStyle } from 'styled-components';

import SfProRegular from '../assets/fonts/SF-Pro-Display-Regular.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Sf Pro';
    font-style: normal;
    font-weight: 400;
    src: url(${SfProRegular});
  }

  /* body {
    
  } */
  /* Estilize a barra de rolagem */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Estilize o indicador (a parte que você arrasta) */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  /* Estilize a pista (a parte da barra de rolagem que não é o indicador) */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Estilize os cantos da barra de rolagem */
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

