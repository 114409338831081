import { createContext, useMemo, useState } from 'react';
import { requestGetClinicById } from 'services/clinics/requestClinic';
import { getClinicsByIndex } from 'services/clinics/requestClinicsByIndex';
import { postCreateClinic } from 'services/clinics/requestCreateClinic';
import { patchUpdateClinic } from 'services/clinics/requestUpdateClinic';
import { IClinic, ICreateClinicDataRequest } from 'services/clinics/types';
import { IClinicContextData, IClinicProviderProps } from './types';
import { requestDeleteClinic } from 'services/clinics/requestDeleteClinic';

export const ClinicContext = createContext({} as IClinicContextData);

export function ClinicProvider({ children }: IClinicProviderProps) {
  const [allClinics, setAllClinics] = useState<IClinic[]>([]);
  const [countAllClinics, setCountAllClinics] = useState(0);

  const getAllClinics = async (
    pageSize: number,
    page: number,
    searchSingular: string,
    searchClinic: string,
    clinicType: string,
  ) => {
    const { data: { data: { data, total } } } = await getClinicsByIndex(
      pageSize,
      page,
      searchSingular,
      searchClinic,
      clinicType,
    );

    setAllClinics(data);
    setCountAllClinics(total);
  };

  const getClinicById = async (id: string) => {
    const { data: { data } } = await requestGetClinicById(id);

    return (data);
  };

  const createClinic = async (createClinicData: ICreateClinicDataRequest) => {
    const { data } = await postCreateClinic(createClinicData);

    return data;
  };

  const updateClinic = async (id: string, createClinicData: ICreateClinicDataRequest) => {
    const { data } = await patchUpdateClinic(id, createClinicData);

    return data;
  };

  const removeClinic = async (id: string) => {
    const { data } = await requestDeleteClinic(id);

    return data;
  };

  const value = useMemo(() => ({
    getAllClinics,
    allClinics,
    countAllClinics,
    getClinicById,
    createClinic,
    updateClinic,
    removeClinic,
  }), [allClinics, countAllClinics]);

  return (
    <ClinicContext.Provider
      value={value}
    >
      {children}
    </ClinicContext.Provider>
  );
}
