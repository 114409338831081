import { Col, Divider, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDoctorById } from "services/doctors/requestDoctorById";
import { DoctorUniqueDataResponse } from "services/doctors/types";
import { DetailsContainer, Title } from "../../styles";
import { patchUpdateDoctorAnticipation } from "services/doctors/requestUpdateDoctor";

export function AntecipationDetails() {
  const { id } = useParams();
  const [doctorData, setDoctorData] = useState<DoctorUniqueDataResponse>();
  const [anticipation, setAnticipation] = useState<boolean>();

  useEffect(() => {
    async function fetchDoctorData() {
      if (id) {
        try {
          const response = await getDoctorById(id);
          setDoctorData(response.data);
          // Defina o valor inicial de 'anticipation' com base no 'enabled_anticipation' do médico
          setAnticipation(response.data.doctor.enabled_anticipation);
        } catch (error) {
          console.error('Error fetching doctor data:', error);
        }
      }
    }
    fetchDoctorData();
  }, [id]);

  const handleOnChangeAnticipation = async () => {
    if (id) {
      try {
        // Inverta o valor de 'anticipation'
        const enableValue = !anticipation;

        // Atualize o estado
        setAnticipation(enableValue);

        // Chame a função de atualização
        await patchUpdateDoctorAnticipation(id, enableValue).then(() => {
          Modal.success({
            title: `Antecipação ${enableValue ? "Habilitada" : "Desabilitada"} com sucesso!`,
            okButtonProps: { type: "text" },
          });
        });
      } catch (error) {
        Modal.error({
          title: `Antecipação não pode ser ${!anticipation ? "Habilitada" : "Desabilitada"}!`,
          content: `Tente novamente!`,
          okButtonProps: { type: "text" },
        });
      }
    }
  };

  return (
    <>
      {doctorData && (
        <DetailsContainer>
          <Row>
            <Title>Antecipação</Title>
          </Row>
          <Divider style={{ marginTop: 0 }} />
          <Row>
            <Col span={3}>Habilitar antecipação</Col>
            <Col>
              <Switch
                onChange={handleOnChangeAnticipation}
                checked={anticipation} // Defina o valor inicial do switch com base no estado 'anticipation'
              />
            </Col>
          </Row>
        </DetailsContainer>
      )}
    </>
  );
}
