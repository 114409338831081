import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ILoginData } from "../../services/auth/types";
import { LoginForm } from "./LoginForm";
import {
  Container,
  FormContainer,
  Title,
  LeftContainer,
  RightContainer,
  LoginFormContainer,
  Logo,
  LogoWhite,
} from "./style";
import { Spin } from "antd";
import { useState } from "react";

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { loginWithCognito, signed, firstAcess } = useAuth();

  const handleSubmitForm = async (data: ILoginData) => {
    setLoading(true);
    await loginWithCognito(data)
      .then((r) => setLoading(false))
      .catch((e) => setLoading(false));
  };

  if (signed) {
    navigate("/");
  }

  if (firstAcess.isFistAcess) {
    navigate(`/login/primeiro-acesso`);
  }

  return (
    <Container>
      <FormContainer>
        <LeftContainer>
          <Logo />
          <Spin spinning={loading}>
            <LoginFormContainer>
              <Title>Login</Title>
              <LoginForm handleSubmitForm={handleSubmitForm} />
            </LoginFormContainer>
          </Spin>
        </LeftContainer>
        <RightContainer>
          <LogoWhite />
        </RightContainer>
      </FormContainer>
    </Container>
  );
}
