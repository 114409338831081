import { createContext, useMemo, useState } from 'react';
import { requestGetUserById } from 'services/users/requestUser';
import { getUsers } from 'services/users/requestUsersByIndex';
import { postCreateUser } from 'services/users/requestCreateUser';
import { ICreateUserDataRequest, ReturnUsers } from 'services/users/types';
import { IUserContextData, IUserProviderProps } from './types';

export const UserContext = createContext({} as IUserContextData);

export function UserProvider({ children }: IUserProviderProps) {
  const [countAllUsers, setCountAllUsers] = useState<number>(0);
  const [tokenNextPage, setTokenNextPage] = useState<string | null>(null);

  const getAllUsersNEW = async (
    // limit?: number,
    // token?: string | null,
    name?: string,
  ): Promise<ReturnUsers> => {
    const response = await getUsers(
      // limit,
      // token,
      name
    );

    setCountAllUsers(response.data.item_count)
    setTokenNextPage(response.data.token);
    
    return response.data;
  };

  const createUser = async (createUserData: ICreateUserDataRequest) => {
    try {
      await postCreateUser(createUserData);
    } catch (error) {
      throw new Error("Falha ao criar usuário");
    }
  };

  const getUserById = async (id: string) => {
    try {
      const { data } = await requestGetUserById(id);
  
      return data;
    } catch (error) {
      throw new Error;
    }
  };

  const value = useMemo(() => ({
    countAllUsers,
    getUserById,
    createUser,
    getAllUsersNEW,
    tokenNextPage,
  }), [countAllUsers, tokenNextPage]);

  return (
    <UserContext.Provider
      value={value}
    >
      {children}
    </UserContext.Provider>
  );
}
