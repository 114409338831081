import { useState, useEffect } from "react";
import { Col, Divider, Row } from "antd";
import { GeneralContainer, Title } from "../../style";
import { IFormSingularProps } from "../../types";

import { InputForm } from "components/InputDetails";
import { maskCNPJ } from "utils/masks";
import { getErpById } from "services/singular/requestErp";

export function GeneralContainerSingular({ singular }: IFormSingularProps) {
  const [erpName, setErpName] = useState<string>('')

  useEffect(() => {
    const getErpName = async () => {
      const { data } = await getErpById(singular.erp_id);
      setErpName(data.name)
    }
    getErpName();
  }, [])

  return (
    <GeneralContainer>
      <Row>
        <Title>Dados Cadastrais</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row
        style={{ marginTop: 20 }}
        gutter={{
          lg: 20,
          sm: 20,
          md: 20,
          xl: 20,
          xs: 20,
          xxl: 20,
        }}
      >
        <Col span={5}>
          <InputForm title="Nome da Unimed" value={singular.name} />
        </Col>
        <Col span={5}>
          <InputForm title="Código Unimed" value={singular.unimed_code} />
        </Col>
        <Col span={5}>
          <InputForm title="Código Ans" value={singular.ans_code} />
        </Col>
        <Col span={5}>
          <InputForm title="CNPJ" value={maskCNPJ(singular.cnpj)} />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 30 }}
        gutter={{
          lg: 20,
          sm: 20,
          md: 20,
          xl: 20,
          xs: 20,
          xxl: 20,
        }}
      >
        <Col span={5}>
          <InputForm title="ERP" value={erpName} />
        </Col>
        <Col span={5}>
          <InputForm title="CEP" value={singular.address.zipcode} />
        </Col>
        <Col span={5}>
          <InputForm title="Estado" value={singular.address.state} />
        </Col>
        <Col span={5}>
          <InputForm title="Cidade" value={singular.address.city} />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 30 }}
        gutter={{
          lg: 20,
          sm: 20,
          md: 20,
          xl: 20,
          xs: 20,
          xxl: 20,
        }}
      >
        <Col span={10}>
          <InputForm title="Logradouro" value={singular.address.street} />
        </Col>
        <Col span={5}>
          <InputForm title="Bairro" value={singular.address.neighborhood} />
        </Col>
        <Col span={2}>
          <InputForm title="Número" value={singular.address.number} />
        </Col>
        {singular.address.details && (
          <Col span={3}>
            <InputForm title="Complemento" value={singular.address.details} />
          </Col>
        )}
      </Row>
    </GeneralContainer>
  );
}
