import {
  PlusOutlined
} from '@ant-design/icons';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { DataRow } from './components/DataRow';
import {
  Container, Content, Divider, Header, Items, Table, TableHead, TableHeadCell, TableLabel, Title
} from './styles';
import { IProps } from './types';

export function TableContainer({
  title,
  onClickRemove,
  allDoctors,
  allSingularData
}: IProps) {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <Title>
          {title}
        </Title>
        <Items>
          <Button onClick={() => {
            navigate('/painel/medicos/new');
          }}>
            <PlusOutlined style={{marginRight: 10}}/>
            Novo cooperado
          </Button>
        </Items>
      </Header>
      <Table cellPadding={0} cellSpacing={0}>
        <TableHead>
          <TableHeadCell disableLeftPadding width={80}>
            <Content>
              <TableLabel marginLeft={2}>
                Nome Cooperado
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={100}>
            <Content>
              <Divider/>
              <TableLabel>
                CPF
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={95}>
            <Content>
              <Divider/>
              <TableLabel>
                CRM
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={95}>
            <Content>
              <Divider/>
              <TableLabel>
                Cod
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={95}>
            <Content>
              <Divider/>
              <TableLabel>
                Ans
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={80}>
            <Content>
              <Divider/>
              <TableLabel>
                Antecipação
              </TableLabel>
            </Content>
          </TableHeadCell>
          <TableHeadCell width={50}>
            <Content>
              <Divider/>
              <TableLabel>
                Ações
              </TableLabel>
            </Content>
          </TableHeadCell>
        </TableHead>
        <DataRow onClickRemove={onClickRemove} allDoctors={allDoctors} allSingularData={allSingularData}/>
      </Table>
    </Container>
  );
}
