import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { getDoctorSearch } from 'services/doctors/requestDoctorSearch';
import { IDoctors } from 'services/doctors/types';
import { requestGetClinicAddDoctor } from 'services/clinics/requestClinicAddDoctor';
import { requestGetClinicRemoveDoctor } from 'services/clinics/requestClinicRemoveDoctor';
import { getDoctorByClinic } from 'services/doctors/requestDoctorByClinic';
import {
  Container, DoctorsContainer, SubmitButton, Title, SelectStyled,
} from './style';
import { Table } from './Table';

interface IDoctorsFormProps {
  control: any,
  errors: any,
  register: any,
  clinicId: string | undefined
}

export function DoctorsForm({
  // eslint-disable-next-line no-unused-vars
  control, errors, register, clinicId,
}: IDoctorsFormProps) {
  const [searchedUnimed, setSearchedUnimed] = useState<any[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<any>();
  const [doctors, setDoctors] = useState<IDoctors[]>();

  const searchUnimed = async (name: string) => {
    const { data: { data } } = await getDoctorSearch(name);

    setSearchedUnimed(data.map((unimed) => ({
      value: unimed.id,
      label: unimed.name,
    })));
  };

  const getDoctors = async () => {
    if (clinicId) {
      const { data: { data } } = await getDoctorByClinic(clinicId);

      setDoctors(data);
    }
  };

  const addDoctor = async () => {
    if (clinicId && selectedDoctor) { await requestGetClinicAddDoctor(clinicId, selectedDoctor); }

    await getDoctors();
  };

  const removeDoctor = async (doctorId: string) => {
    if (clinicId) { await requestGetClinicRemoveDoctor(clinicId, doctorId); }

    await getDoctors();
  };

  useEffect(() => {
    searchUnimed('');
    getDoctors();
  }, []);

  return (
    <Container>
      <DoctorsContainer>
        <Row style={{ marginTop: 20, marginLeft: 10 }} gutter={{ lg: 30 }}>
          <Col span={7}>
            <Title>Médicos</Title>
            <SelectStyled
              options={searchedUnimed}
              filterOption={(input, option) => String((option?.label ?? '')).toLowerCase().includes(input.toLowerCase())}
              onSearch={searchUnimed}
              optionFilterProp="children"
              showSearch
              style={{ borderRadius: 0, width: '100%' }}
              value={selectedDoctor}
              onChange={setSelectedDoctor}
            />
          </Col>
          <Col span={6} style={{ marginTop: 20 }}>
            <SubmitButton type="button" onClick={addDoctor}>Adicionar</SubmitButton>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Table
            data={doctors}
            removeDoctor={removeDoctor}
          />
        </Row>
      </DoctorsContainer>
    </Container>
  );
}
