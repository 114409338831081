import styled from 'styled-components';
import { Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const Container = styled.div`
  width: 100%;
  height: 40px;
`;

export const CustomDropdown = styled.div`
  max-height: 200px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

export const CustomItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding-left: 10px;
  height: 40px;
`;

export const CustomCheckbox = styled(Checkbox)`
  margin-right: 8px;
  font-size: 40px;
`;

export const CustomLabel = styled.span``;

export const CustomInput = styled(Input)`
  .ant-input {
    width: 100%;
    height: 38px;
    padding-right: 30px;
  }
`;

export const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
