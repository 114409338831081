import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({theme}) => theme.palette.white};
`;

export const ContainerBottomHeader = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  background-color: ${({theme}) => theme.palette.white};
`;

export const ContainerContent = styled.div`
  display: flex;
  width: 100%;

  padding: 21px;

  background-color: ${({theme}) => theme.background};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 24px;

  border-radius: 8px;
  padding: 24px;

  background-color: ${({theme}) => theme.palette.white};
`;
