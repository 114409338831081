import { Button, Pagination as PaginationRaw, Divider as RawDivider } from 'antd';
import { BiChevronUp } from 'react-icons/bi';
import styled, { css } from 'styled-components';
import { IStyleProps } from '../../types';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  min-width: 100%;
  padding: 0 24px;
`;

export const TableRow = styled.tr`
  
`;

export const Divider = styled(RawDivider).attrs({
  type: 'vertical',
})<IStyleProps>`
  height: 28px;
  margin-right: 16px;
  margin-left: 0px;

  ${({ color }) => color
    && css`
      background-color: ${color};
    `}

  ${({ height }) => height
  && css`
    height: ${height}px;
  `}

  ${({ alternativeLayout }) => alternativeLayout && css`
    height: calc(100% - 50px);

    margin: auto 0;
    margin-left: 2rem;
    margin-right: 2rem;
  `}
`;

export const ButtonAntd = styled(Button)`
  border-radius: 2px !important;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary} !important;
    color: ${({ theme }) => theme.palette.primary} !important;
  }
`;

export const SelectedCollapsedCell = styled.td`
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Typography = styled.p<IStyleProps>`
  color: ${({ theme }) => theme.palette.primary};
  margin: 0px;
  width: 16rem;

  ${({ color }) => color
    && css`
      color: ${color};
    `}

  ${({ width }) => width
    && css`
      width: ${typeof width === 'number' ? `${width}rem` : width};
    `}

  ${({ fontSize }) => fontSize
    && css`
      font-size: ${fontSize}px;
    `}

  ${({ lineHeight }) => lineHeight
    && css`
      line-height: ${lineHeight}px;
    `}

  ${({ marginTop }) => marginTop
    && css`
      margin-top: ${marginTop}px;
    `}

  ${({ fontWeight }) => fontWeight
    && css`
      font-weight: ${fontWeight};
    `}

  ${({ fontFamily }) => fontFamily
    && css`
      font-family: ${fontFamily};
    `}
`;

export const SelectedCol = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;

  ${({ centralize }) => centralize
    && css`
      align-items: center;
    `}

  ${({ width }) => !!width
  && css`
    width: ${width}%;
  `}

  ${({ paddingLeft }) => paddingLeft
    && css`
      padding-left: ${paddingLeft}px;
    `}

  ${({ marginLeft }) => marginLeft
    && css`
      margin-left: ${marginLeft}rem;
    `}


  ${({ marginTop }) => !!marginTop
    && css`
      margin-top: ${marginTop}px;
    `}
`;

export const SelectedRow = styled.div<IStyleProps>`
  display: flex;
  flex-direction: row;

  ${({ centralize }) => centralize && css`
    align-items: center;
  `}

  ${({ width }) => width
    && css`
      width: ${width}rem;
    `}

  ${({ marginTop }) => !!marginTop
    && css`
      margin-top: ${marginTop}px;
  `}

  ${({ spaceBetween }) => spaceBetween
    && css`
      justify-content: space-between;
    `}
`;

export const SelectedContainer = styled.td`
  padding-bottom: 5px;
  height: 186px;
`;

export const SelectedRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  background-color: ${({ theme }) => theme.palette.white};
  padding-bottom: 5px;
  height: 186px;
`;

export const SelectedTitle = styled.p<IStyleProps>`
  margin: 0;

  font-size: 12px;
  line-height: 22px;

  color: ${({ theme }) => theme.palette.lightGray};

  font-weight: 300;

  ${({ marginTop }) => marginTop && css`
    margin-top: ${marginTop}px;
  `}
`;

export const SelectedLabel = styled.p`
  margin: 0;
  font-weight: bold;

  font-size: 16px;
`;

export const Address = styled.div`
  font-size: 14px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.7);
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: 400;
`;

export const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 3rem;

  margin-left: 1rem;
`;

export const CarouselContainer = styled.div`
  width: 30rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 4px;
`;

export const Pagination = styled(PaginationRaw)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const DetailsContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const LabelText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  margin-left: 10px;
`;

export const ContainerComponents = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 15px 21px;
`;
