import { useContext } from 'react';
import { UserContext, UserProvider } from './UserContext';

function useUser() {
  const users = useContext(UserContext);

  return users;
}

export { useUser, UserProvider };
