import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Container, Error, Title, Select,
} from './style';
import {IPropsSelectForm} from './types';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export function SelectForm({
  control,
  name,
  error,
  title,
  data,
  onChangeValue,
  onSearchValue,
  defaultValue,
  rest,
  popover,
}: IPropsSelectForm) {
  return (
    <Container>
      <Controller
        control={control}
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <>
            {title && (
              <Title>
                {title}
                {popover && (
                <Popover content={popover.content} title={popover.title} trigger="hover">
                  <QuestionCircleOutlined
                    style={{ marginLeft: 5, cursor: "pointer" }}
                  />
                </Popover>
              )}
              </Title>
            )}
            <Select
              filterOption={(input, option) => String((option?.label ?? '')).toLowerCase().includes(input.toLowerCase())}
              options={data}
              onChange={(changedValue: any) => {
                if (onChangeValue) {
                  onChangeValue(changedValue);
                }

                onChange(changedValue);
              }}
              onSearch={onSearchValue}
              disabled={rest?.disabled}
              optionFilterProp="children"
              showSearch
              value={value}
              defaultValue={defaultValue}
              {...rest}
            />
          </>
        )}
        name={name}
      />
      {error && <Error>{ error }</Error>}
    </Container>
  );
}
