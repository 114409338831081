import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Container, Error, Title, Input,
} from './style';
import { IPropsInputForm } from './types';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export function InputForm({
  control,
  name,
  error,
  title,
  mask,
  rest,
  popover,
}: IPropsInputForm) {
  return (
    <Container>
      <Controller
        control={control}
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <>
            <Title>
              {title}
              {popover && (
                <Popover content={popover.content} title={popover.title} trigger="hover">
                  <QuestionCircleOutlined
                    style={{ marginLeft: 5, cursor: "pointer" }}
                  />
                </Popover>
              )}
            </Title>
            <Input
              onChange={(changedValue: any) => {
                if (mask) {
                  // eslint-disable-next-line no-param-reassign
                  changedValue.target.value = mask(changedValue.target.value);
                }

                onChange(changedValue);
              }}
              value={value}
              {...rest}
            />
          </>
        )}
        name={name}
      />
      {error && <Error>{ error }</Error>}
    </Container>
  );
}
