import * as Yup from 'yup';

export const schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password deve conter no mínimo 8 caracteres')
    .required('Senha é obrigatório'),
  confirmPassword: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .oneOf([Yup.ref('newPassword'), null], 'As senhas não coincidem'),
});
