import styled from "styled-components";
import {Pagination as PaginationRaw} from "antd";

export const Pagination = styled(PaginationRaw)`
  margin-left: 15px;

  .ant-pagination-item {
    pointer-events: none;
  }

`;

export const PaginationContainer = styled.div`
  display: flex;

  padding-top: 2rem;
  padding-right: 25px;
  padding-bottom: 2rem;

  align-items: center;

  width: 100%;
  justify-content: flex-end;

  background-color: ${({theme}) => theme.background};
`;