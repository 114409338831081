import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background-color: transparent;
      white-space: break-spaces;
      text-align: left;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        white-space: break-spaces;
        text-align: left;
        overflow: visible;
      }

      .ant-table-cell-row-hover {
        background-color: transparent;
      }
    }
  }
`;

export const ContainerModal = styled(Container)`
  .ant-table-thead {
    .ant-table-cell {
      ::before {
        display: none;
      }
    }
  }
`

export const ContainerAnticipationModal = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({theme}) => theme.background};

  margin-bottom: 24px;
  padding: 8px;
  border-radius: 5px;

  .ant-table-thead, .ant-table-tbody {
    .ant-table-cell {
      background-color: ${({theme}) => theme.background};
      border: 0;
      padding: 5px 15.6px;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell-row-hover {
        background-color: ${({theme}) => theme.background};
      }
    }
  }
`;

export const Button = styled(ButtonAntd)`
  background-color: ${({theme}) => theme.palette.secondaryLight};
  border: 0;
  border-radius: 25px;
  color: ${({theme}) => theme.palette.primary};
  box-shadow: none;
`
