import React, {useState, useEffect} from 'react';
import {Input} from './styles';
import {IProps} from './types';

export function SearchBar({
                            placeholder, onchange, value,
                          }: IProps) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      onchange(inputValue);
    }, 1000);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [inputValue, onchange]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
    </>
  );
}
