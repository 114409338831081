export function guideStatusFormat(status: string) {
  const statusDefault: string = 'Indisponível';

  const guidesStatus: {
    [key: string]: string;
  } = {
    'ALLOWED': 'Autorizado',
    'ANTICIPATED': 'Antecipado',
    'CANCELED': 'Cancelado',
    'CANCELING': 'Cancelando',
    'DENIED': 'Negado',
    'EXPIRED': 'Expirado',
    'FAILED': 'Falha',
    'IN_ANTICIPATION': 'Em Antecipação',
    'INVALID': 'Inválido',
    'LATE': 'Em Atraso',
    'PENDING': 'Pendente',
    'UNAVAILABLE': 'Indisponível',
    'VALID': 'Válido',
  };

  if (status in guidesStatus) {
    return guidesStatus[status];
  } else {
    return statusDefault;
  }
}


export const guideStatusOptions = [
  {value: 'ALLOWED', label: 'Autorizado'},
  {value: 'ANTICIPATED', label: 'Antecipado'},
  {value: 'CANCELED', label: 'Cancelado'},
  {value: 'CANCELING', label: 'Cancelando'},
  {value: 'EXPIRED', label: 'Expirado'},
  {value: 'LATE', label: 'Em Atraso'},
  {value: 'IN_ANTICIPATION', label: 'Em Antecipação'},
  {value: 'FAILED', label: 'Falha'},
  {value: 'INVALID', label: 'Inválido'},
  {value: 'UNAVAILABLE', label: 'Indisponível'},
  {value: 'DENIED', label: 'Negado'},
  {value: 'PENDING', label: 'Pendente'},
  {value: 'VALID', label: 'Válido'},
];


export function guideReasonFormat(reason: string) {
  const reasonDefault: string = '';

  const guidesReasons: {
    [key: string]: string;
  } = {};

  if (reason in guidesReasons) {
    return guidesReasons[reason];
  } else {
    return reasonDefault;
  }
}

export const getGuideStatusColor = (status: string) => statusColors[status] || 'red';

const statusColors: { [key: string]: string } = {
  'ALLOWED': 'green',
  'ANTICIPATED': 'green',
  'CANCELED': 'red',
  'CANCELING': 'red',
  'DENIED': 'red',
  'EXPIRED': 'red',
  'FAILED': 'red',
  'IN_ANTICIPATION': 'orange',
  'INVALID': 'red',
  'LATE': 'orange',
  'PENDING': 'orange',
  'UNAVAILABLE': 'red',
  'VALID': 'green',
};
