import { Spin } from "antd";
import { Header } from "components/Header";
import { useState } from "react";
import { useNavigate } from "react-router";
import { DetailsDoctor } from "../DetailsDoctor";
import { GuideDetails } from "../ListGuilde";
import { BodyContainer, ButtonsContainer, CloseButton, HeaderContainer, StyledTabs } from "./styles";

export function HeaderDoctor() {
  const [tabSelected, setTabSelected] = useState('1');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const items = [
    {
      key: '1',
      label: 'Detalhes cooperado',
    },
    {
      key: '2',
      label: 'Lista de Guias',
    }
  ];

  const renderForm = () => {
    switch (tabSelected) {
      case('1') :
        return (
          <>
            <HeaderContainer>
              <Header
                title="Médicos cooperados"
                subtitle="Relação de clínicas e laboratórios da rede credenciada por singular"
              />
            </HeaderContainer>
            <StyledTabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
            <DetailsDoctor />
          </>
        );
      case('2') :
        return (
          <>
            <HeaderContainer>
              <Header
                title="Guias do cooperados"
                subtitle="Relação de guias e cooperado"
              />
            </HeaderContainer>
            <StyledTabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
            <GuideDetails />
          </>
        );
      default :
        return null;
    }
  };

  const handleTabChange = (key: any) => {
    setTabSelected(key);
  };

  return (
    <>
      <Spin spinning={loading}>
        {renderForm()}
        <BodyContainer>
          {(tabSelected == '1') && (
            <ButtonsContainer>
              <CloseButton
               type="button"
               onClick={() => { navigate('/painel/medicos') }}
              >
                Voltar
              </CloseButton>
            </ButtonsContainer>
          )}
        </BodyContainer>
      </Spin>
    </>
  );
};