import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import { InputForm } from '../../../components/InputForm';
import { schema } from './schema';
import {
  FormStyled, SubmitButton, ResetPasswordContainer, StyledLink,
} from './style';
import { IPropsForm } from './types';
import { PasswordInputForm } from '../../../components/PasswordInputForm';

export function FirstAcessForm({
  handleSubmitForm,
} : IPropsForm) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <FormStyled onSubmit={handleSubmit(handleSubmitForm)}>
      <Space direction="vertical">
        <PasswordInputForm
          name="newPassword"
          error={(errors.password && errors.password.message) as string}
          control={control}
          title="NOVA SENHA"
          rest={{
            placeholder: '********',
            size: 'large',
          }}
        />
        <PasswordInputForm
          name="confirmPassword"
          error={(errors.confirmPassword && errors.confirmPassword.message) as string}
          control={control}
          title="CONFIRMAR NOVA SENHA"
          rest={{
            placeholder: '********',
            size: 'large',
          }}
        />
      </Space>

      <ResetPasswordContainer>
        <StyledLink to="/reset-password">Esqueci minha senha</StyledLink>
      </ResetPasswordContainer>

      <SubmitButton>
        Entrar
      </SubmitButton>
    </FormStyled>
  );
}
