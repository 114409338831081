import { Col, Divider, Row } from "antd";
import { InputForm } from 'components/InputDetails';
import { useSingular } from "hooks/useSingular";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDoctorById } from "services/doctors/requestDoctorById";
import { DoctorUniqueDataResponse } from "services/doctors/types";
import { DetailsContainer, Title } from "../../styles";

export function BankEscrowDetails() {
  const { id } = useParams();
  const [doctorData, setDoctorData] = useState<DoctorUniqueDataResponse>();
  
  useEffect(() => {
    async function fetchDoctorData() {
      if (id) {
        try {
          const response = await getDoctorById(id);
          setDoctorData(response.data); 
        } catch (error) {
          console.error('Error fetching doctor data:', error);
        }
      }
    }
    fetchDoctorData();
  }, [id]);

  return (
    <>
      {doctorData && (
        <DetailsContainer>
          <Row>
            <Title>Dados Bancário - Conta Escrow</Title>
          </Row>
          <Divider style={{ marginTop: 0 }} />
          <Row
            style={{ marginTop: 20 }}
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
          >
            <Col span={9}>
              <InputForm
                title="Código e Nome do Banco"
                value={`${doctorData.account.code} - ${doctorData.account.name}`}
              />
            </Col>
            <Col span={3}>
              <InputForm
                title="Agência"
                value={doctorData.account.branch}
              />
            </Col>
            <Col span={3}>
              <InputForm
                title="Conta"
                value={doctorData.account.number}
              />
            </Col>
            <Col span={3}>
              <InputForm
                title="Dígito"
                value={doctorData.account.digit}
              />
            </Col>
          </Row>
        </DetailsContainer>
      )}
    </>
  );
}
