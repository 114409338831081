import {useContext} from 'react';
import {SingularContext, SingularProvider} from './SingularContext';

function useSingular() {
  const unimed = useContext(SingularContext);

  return unimed;
}

export {useSingular, SingularProvider};
