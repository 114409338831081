import {Container,} from '../style';
import {IFormSingularProps} from "../types";
import {GeneralContainerSingular} from "../ContainerForm/GeneralContainer/GeneralContainerSingular";
import {BankContainerSingular} from "../ContainerForm/BankContainer/BankContainerSingular";
import {MasterContainerSingular} from "../ContainerForm/MasterUserContainer/MasterUserContainerSingular";
import {ContactsContainerSingular} from "../ContainerForm/ContactContainer/ContactContainerSingular";
import {
  AnticipationContainerSingular
} from "../ContainerForm/AnticipationContainer/AnticipationContainerSingular";

export function DetailsSingularForm({control, errors, getLocation, register, setBank}: IFormSingularProps) {
  return (
    <Container>
      <GeneralContainerSingular control={control} errors={errors} getLocation={getLocation}/>
      <AnticipationContainerSingular control={control} errors={errors}/>
      <BankContainerSingular control={control} errors={errors} setBank={setBank}/>
      <MasterContainerSingular control={control} errors={errors}/>
      <ContactsContainerSingular control={control} errors={errors} register={register}/>
    </Container>
  );
}
