import React, { useState } from "react";
import { Spin } from "antd";
import { Dayjs } from "dayjs";

import { Header } from "../../components/Header";

import { Filters } from "./components/Filters";
import { Table } from "./components/Table";

import { 
  Container,
  ContainerBottomHeader,
  ContainerContent,
  Content,
} from "./styles";

export const UnpadAnticipation: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedSingulares, setSelectedSingulares] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>();
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <Container>
      <Spin spinning={loading}>
        <Header
          title="Atencipações Não Liquidadas"
          subtitle="Relação de Antecipações Não Liquidadas Dentro do Prazo."
        />
        <ContainerBottomHeader />
        <ContainerContent>
          <Content>
            <Filters 
              singular={{selectedSingulares, setSelectedSingulares}}
              date={{selectedDate, setSelectedDate}}
              value={{searchValue, setSearchValue}}
            />
            <Table 
              setLoading={setLoading}
              searchDate={selectedDate}
              searchRazaoCNPJ={searchValue}
              searchSingular={selectedSingulares}
            />
          </Content>
        </ContainerContent>
      </Spin>
    </Container>
  );
};
