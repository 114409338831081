import {useEffect, useState} from 'react';
import {Header} from '../../components/Header';
import {SearchBar} from '../../components/SearchBar';
import {useSingular} from '../../hooks/useSingular';
import {Table} from './components/Table';
import {Container, ContainerSearch, TitleSearch} from './styles';
import {CustomPagination} from "../../components/Pagination/Pagination";
import {Spin} from "antd";

export function Singular() {
  const {
    fetchAllSingular,
    fetchAllErp,
    allSingular,
    countAllSingular,
    removeSingular,
    tokenNextPage,
  } = useSingular();
  const [limit, setLimit] = useState(10);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchAllErp().then(r => setLoading(false)).catch(e => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchAllSingular(limit, search || '', nextPage || '').then(r => setLoading(false)).catch(e => setLoading(false));
  }, [limit, search, nextPage]);

  const onClickRemove = async (id: string) => {
    await removeSingular(id);
    fetchAllSingular(limit, search || '', nextPage || '').then(r => setLoading(false)).catch(e => setLoading(false));
  }
  return (
    <Container>
      <Spin spinning={loading}>
        <Header
          title="Cadastro de Singulares"
          subtitle="Relação das Singulares do Sistema Unimed"
        />
        <ContainerSearch>
          <TitleSearch>Singular</TitleSearch>
          <SearchBar
            placeholder="Pesquise pelo nome, código da singular, ANS ou CNPJ"
            value={search}
            onchange={setSearch}
          />
        </ContainerSearch>
        {allSingular && allSingular.length > 0 ? (
          <>
            <Table
              title="Lista de Singulares"
              allSingular={allSingular}
              onClickRemove={onClickRemove}
            />
            <CustomPagination
              countAllItems={countAllSingular}
              limit={limit}
              tokenNextPage={tokenNextPage}
              setLimit={setLimit}
              setNextPage={setNextPage}
            />
          </>
        ) : (
          <Table
            title="Lista de singulares"
            allSingular={allSingular}
            onClickRemove={onClickRemove}
          />
        )}
      </Spin>
    </Container>

  );
}
