import { Col, Divider, Row } from "antd";
import { ContactContainer, Title } from "../../style";
import { IFormSingularProps } from "../../types";

import { InputForm } from "components/InputDetails";
import { maskPhone } from "utils/masks";

export function ContactsContainerSingular({ singular }: IFormSingularProps) {
  return (
    <ContactContainer>
      <Row>
        <Title>Contatos</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      {singular.contacts.map((field, index) => (
        <Row
          gutter={{
            lg: 20,
            sm: 20,
            md: 20,
            xl: 20,
            xs: 20,
            xxl: 20,
          }}
          key={field.email}
        >
          <Col span={6}>
            <InputForm title="Nome" value={field.name} />
          </Col>
          <Col span={3}>
            <InputForm title="Fone" value={maskPhone(field.phone)} />
          </Col>
          <Col span={6}>
            <InputForm title="Email" value={field.email} />
          </Col>
        </Row>
      ))}
    </ContactContainer>
  );
}
