import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 15px 21px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const DetailsContainer = styled.div`
  padding: 24px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const ParamsContainer = styled.div`
  padding: 24px;
  margin-top: 20px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  margin-left: 10px;
`;

export const TitleEnable = styled.span`
  color: ${({ theme }) => theme.palette.grey};
  
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 10pt;

  margin: 0 0 7px;
`;
