import React, {useEffect, useState} from 'react';
import {useGuide} from 'hooks/useGuide';
import {Header} from '../../components/Header';
import {SearchBar} from '../../components/SearchBar';
import {Container, ContainerSearch, TitleSearch} from './styles';
import {Col, Spin} from "antd";
import {TableGuide} from "./Table";
import CustomPagination from 'components/Pagination';
import {SearchSingular} from "../Doctor/styles";
import {guideStatusOptions} from "../../utils/guidesHelpers";

export function Guide() {
  const {fetchAllGuides, allGuides, countAllGuides, tokenNextPage} = useGuide();
  const [procedureSearch, setProcedureSearch] = useState();
  const [limit, setLimit] = useState(10);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    setLoading(true);
    fetchAllGuides(limit, search || '', nextPage || '').then(r => setLoading(false)).catch(e => setLoading(false));
  }, [limit, search, nextPage]);

  return (
    <Container>
      <Spin spinning={loading}>
        <Header
          title="Guias"
          subtitle="Relação de Guias Geradas"
        />
        <ContainerSearch>
          <TitleSearch>Guia</TitleSearch>
          <Col span={10}>
            <SearchBar
              placeholder="Pesquise pelo número da guia"
              value={search}
              onchange={setSearch}
            />
          </Col>
          <TitleSearch>Status</TitleSearch>
          <Col span={10}>
            <SearchSingular
              allowClear
              size="large"
              options={guideStatusOptions}
              filterOption={(input, option) => String((option?.label ?? '')).toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) => optionA.label.localeCompare(optionB.label)}
              optionFilterProp="label"
              onChange={(value: any) => setSearch(value)}
              placeholder="Selecione o Status da guia"
              showSearch
            />
          </Col>
        </ContainerSearch>
        {allGuides && (
          <>
            <TableGuide title="Lista de guias" data={allGuides}/>
            <CustomPagination
              countAllItems={countAllGuides}
              limit={limit}
              tokenNextPage={tokenNextPage}
              setLimit={setLimit}
              setNextPage={setNextPage}
            />
          </>
        )}
      </Spin>
    </Container>
  );
}
