import api from '../api';
import { TransfersByIndexDataResponse } from './types';

export async function getTransfersByIndex(
  pageSize: number,
  page: number,
  doctor: string,
  initialDate: string,
  endDate: string,
) {
  return api.get<TransfersByIndexDataResponse>(`transfers/pagination?pageSize=${pageSize}&page=${page}&doctor=${doctor}&initialDate=${initialDate}&endDate=${endDate}`);
}
