import {
  createContext, useCallback, useMemo, useState,
} from 'react';
import { type ITissContextData, type ITissProviderProps } from './types';
import { type IBiometricsData } from '../../services/tiss/types';
import { getBiometrics } from '../../services/tiss/requestBiometrics';
import { getBiometricsById } from '../../services/tiss/requestBiometricsById';

export const TissContext = createContext({} as ITissContextData);

export function TissProvider({ children }: ITissProviderProps) {
  const [biometricsData, setBiometricsData] = useState<IBiometricsData[]>([]);
  const [countBiometricsData, setCountBiometricsData] = useState(0);

  const getBiometricsData = useCallback(async (
    pageSize: number,
    page: number,
  ) => {
    const { data: { data: { data, total } } } = await getBiometrics(pageSize, page);

    setBiometricsData(data);
    setCountBiometricsData(total);
  }, [setBiometricsData, setCountBiometricsData, getBiometrics]);

  const getBiometricsDataById = useCallback(async (referenceId: string) => {
    const { data } = await getBiometricsById(referenceId);

    return (data.data);
  }, [getBiometricsById]);

  const value = useMemo(() => ({
    getBiometricsDataById,
    getBiometricsData,
    biometricsData,
    countBiometricsData,
  }), [getBiometricsDataById, getBiometricsData, biometricsData, countBiometricsData]);

  return (
    <TissContext.Provider
      value={value}
    >
      {children}
    </TissContext.Provider>
  );
}
