import {
  BrowserRouter,
  Route,
  Routes as Router,
} from 'react-router-dom';

{/* Auth */}
import { Login } from '../views/Login';
import { FirstAccess } from 'views/FirstAcess';
import { AccessDenied } from 'views/AccessDenied/AccessDenied';
// import { NewPassword } from '../views/NewPassword';
// import { ForgotPassword } from '../views/ForgotPassword';

{/* Guias */}
import { Guide } from 'views/Guide';
import { GuideDetails } from 'views/Guide/Details';

{/* Users */}
import { User } from 'views/User';
import { UserForm } from 'views/UserForm';
import { UserDetails } from 'views/User/Details';

{/* Antecipação não liquidada */}
import { UnpadAnticipation } from 'views/UnpadAnticipation';

{/* NotFound */}
import { NotFound } from '../views/NotFound/NotFound';

{/* Medicos */}
import { HeaderDoctor } from 'views/Doctor/Table/components/Header/Header';
import { Doctor } from 'views/Doctor';
import { DoctorForm } from 'views/DoctorForm';

{/* Prestadores */}
import { Provider } from '../views/Provider';
import { ProviderForm } from 'views/ProviderForm';

{/* Singulares */}
import { Singular } from '../views/Singular';
import { SingularForm } from '../views/SingularForm';
import { Details as SingularDetails } from '../views/Singular/Details';

{/* Dashboard */}
import { Home } from 'views/Home/Home';

{/* Layout */}
import { Layout } from '../components/Layout';

{/* Routes */}
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';


export function Routes() {
  return (
    <BrowserRouter>
      <Router>
        <Route
          path="*"
          element={(
            <NotFound />
          )}
        />
        {/* Dashboard */}
        <Route
          path="/"
          element={(
            <PrivateRoute>
              <Layout pathRoute="home" route="home">
                <Home />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Singulares */}
        <Route
          path="/painel/singulares"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/singulares" route="singulares">
                <Singular />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/singulares/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/singulares" route="singulares">
                <SingularForm />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/singulares/detalhes/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/singulares/detalhes" route="singulares">
                <SingularDetails />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Prestadores */}
        <Route
          path="/painel/prestadores"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/prestadores" route="prestadores">
                <Provider />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/prestadores/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/prestadores" route="prestadores">
                <ProviderForm />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Medicos */}
        <Route
          path="/painel/medicos"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/medicos" route="medicos">
                <Doctor />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/medicos/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/medicos" route="medicos">
                <DoctorForm />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/medicos/detalhes/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/medicos" route="medicos">
                <HeaderDoctor />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Antecipação não liquidada */}
        <Route
          path="/painel/antecipacao"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/atencipação" route="antecipacao">
                <UnpadAnticipation />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Users */}
        <Route
          path="/painel/usuarios"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/usuários" route="usuarios">
                <User />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/usuarios/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/usuários/novo usuário" route="usuarios">
                <UserForm />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/usuarios/detalhes/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/usuários/detalhes" route="usuarios">
                <UserDetails />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Guias */}
        <Route
          path="/painel/guias"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/guias" route="guias">
                <Guide />
              </Layout>
            </PrivateRoute>
          )}
        />
        <Route
          path="/painel/guias/:id"
          element={(
            <PrivateRoute >
              <Layout pathRoute="home/guias" route="guias">
                <GuideDetails />
              </Layout>
            </PrivateRoute>
          )}
        />

        {/* Auth */}
        <Route
          path="/login"
          element={(
            <PublicRoute>
              <Login />
            </PublicRoute>
          )}
        />
        <Route
          path="/login/primeiro-acesso"
          element={(
            <PublicRoute>
              <FirstAccess />
            </PublicRoute>
          )}
        />
        <Route
          path="acesso-negado"
          element={(
            <AccessDenied />
          )}
        />

      </Router>
    </BrowserRouter>
  );
}
