import {Container,} from '../style';
import {IFormSingularProps} from "../types";
import {GeneralContainerSingular} from "../ContainerForm/GeneralContainer/GeneralContainerSingular";
import {BankContainerSingular} from "../ContainerForm/BankContainer/BankContainerSingular";
import {MasterContainerSingular} from "../ContainerForm/MasterUserContainer/MasterUserContainerSingular";
import {ContactsContainerSingular} from "../ContainerForm/ContactContainer/ContactContainerSingular";
import {
  AnticipationContainerSingular
} from "../ContainerForm/AnticipationContainer/AnticipationContainerSingular";

export function DetailsSingularForm({singular}: IFormSingularProps) {
  return (
    <Container>
      <GeneralContainerSingular singular={singular}/>
      <AnticipationContainerSingular singular={singular}/>
      <BankContainerSingular singular={singular}/>
      <MasterContainerSingular singular={singular}/>
      <ContactsContainerSingular singular={singular}/>
    </Container>
  );
}
