import styled from 'styled-components';

import LogoUnimed from '../../assets/images/logoUnimedBackOffice.svg';
import LogoUnimedWhite from '../../assets/images/logoUnimedWhite.svg';
import WomanPerson from '../../assets/images/womanPerson.png';

export const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background: ${({ theme }) => theme.background};
  background-position: center;
  background-size: cover;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  width: 50%;
  height: 60%;

  border-radius: ${({ theme }) => theme.borderRadius};

  background-color: ${({ theme }) => theme.palette.white};
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.palette.secondary};
  font-size: 25pt;

  margin: 20px 0;
`;

export const RightContainer = styled.div`
  position: relative;
  display: flex;

  justify-content: flex-end;
  align-items: flex-start;

  width: 50%;
  height: 100%;

  padding-left: 40px;
  padding-right: 40px;

  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};

  background-color: ${({ theme }) => theme.palette.secondary};
  background-image: url(${WomanPerson});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const LeftContainer = styled.div`
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  width: 50%;
  height: 100%;

  padding-left: 40px;
  padding-right: 40px;
`;

export const LoginFormContainer = styled.div`
  display: flex;

  flex-direction: column;
  margin-bottom: 50px;
`;

export const Logo = styled.img.attrs({
  src: LogoUnimed,
  alt: 'Logo',
})`
  margin-top: 20px;
  width: 60%;
`;

export const LogoWhite = styled.img.attrs({
  src: LogoUnimedWhite,
  alt: 'Logo',
})`
  margin-top: 50px;
  width: 50%;
`;
