import React, { useState, useEffect } from "react";
import { Row, Col, Select, DatePicker, Input } from "antd";
import { Dayjs } from 'dayjs';

import { getAllSingular } from "services/singular/requestSingular";
import { ISingularResume } from "services/singular/types";

import { IProps } from './types';

import {
  Container,
  CustomDropdown,
  CustomCheckbox,
  CustomItem,
  CustomLabel,
  SearchIcon,
} from "./styles";
import { maskStringOrCPF } from "utils/masks";

export const Filters: React.FC<IProps> = ({singular, date, value}) => {
  const [optionSingular, setOptionSingular] = useState<ISingularResume[]>([]);


  const allSingular = async () => {
    const { data } = await getAllSingular();
    setOptionSingular(data.data);
  }

  useEffect(() => {
    allSingular()
  }, [])

  const handleChangeSingulares = (value: string[]) => {
    singular.setSelectedSingulares(value);
  };

  const handleChangeDate = (value: Dayjs | null) => {
    date.setSelectedDate(value)
  }

  const handleChangeSearchValue = (params: string) => {
    value.setSearchValue(params)
  }

  const toggleSelection = (value: string) => {
    if (singular.selectedSingulares.includes(value)) {
      singular.setSelectedSingulares(
        singular.selectedSingulares.filter((item) => item !== value)
      );
    } else {
      singular.setSelectedSingulares([...singular.selectedSingulares, value]);
    }
  };

  const customDropdown = (
    <CustomDropdown>
      {optionSingular.map((option) => (
        <CustomItem
          key={option.singular_id}
          onClick={() => toggleSelection(option.name)}
        >
          <CustomCheckbox
            type="checkbox"
            checked={singular.selectedSingulares.includes(option.name)}
          />
          <CustomLabel>{option.name}</CustomLabel>
        </CustomItem>
      ))}
    </CustomDropdown>
  );

  return (
    <Container>
      <Row align="middle">
        <Col span={4} style={{ padding: "0 10px 0 0" }}>
          <DatePicker
            style={{ width: "100%", height: "38px" }}
            format={'DD/MM/YYYY'}
            value={date.selectedDate}
            onChange={(e) => handleChangeDate(e)}
            allowClear
            placeholder="Filtrar Data"
          />
        </Col>
        <Col span={5}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%", height: 38, padding: 0 }}
            placeholder="Filtrar Singulares"
            value={singular.selectedSingulares}
            onChange={handleChangeSingulares}
            dropdownRender={() => customDropdown}
            maxTagCount="responsive"
            labelInValue={true}
          />
        </Col>
        <Col span={15} style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex",
              width: "50%",
            }}
          >
            <Input
              style={{ width: "100%", height: 38 }}
              placeholder="Pesquise por nome ou CPF"
              value={maskStringOrCPF(value.searchValue)}
              maxLength={/^\d+$/.test(value.searchValue) ? 14 : undefined}
              onChange={(e) => handleChangeSearchValue(e.target.value)}
            />
            <SearchIcon />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
