import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const FormStyled = styled.form`
  display: flex;

  flex-direction: column;
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 50px;

  background-color: ${({ theme }) => theme.palette.primary};

  border-radius: 8px;
  border: none;

  color: ${({ theme }) => theme.palette.white};
  font-size: 15pt;

  margin-top: 30px;

  cursor: pointer;
`;

export const StyledLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.grey};

  text-decoration: none;
`;

export const ResetPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0;
`;
