import { Col, Divider, Row } from "antd";
import { InputForm } from 'components/InputDetails';
import { useSingular } from "hooks/useSingular";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDoctorById } from "services/doctors/requestDoctorById";
import { DoctorUniqueDataResponse } from "services/doctors/types";
import { DetailsContainer, Title } from "../../styles";

export function AndressDetails() {
  const { id } = useParams();
  const [doctorData, setDoctorData] = useState<DoctorUniqueDataResponse>();
  
  useEffect(() => {
    async function fetchDoctorData() {
      if (id) {
        try {
          const response = await getDoctorById(id);
          setDoctorData(response.data); 
        } catch (error) {
          console.error('Error fetching doctor data:', error);
        }
      }
    }
    fetchDoctorData();
  }, [id]);

  return (
    <>
      {doctorData && (
        <DetailsContainer>
          <Row>
            <Title>Endereço</Title>
          </Row>
          <Divider style={{ marginTop: 0 }} />
          <Row
            style={{ marginTop: 20 }}
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
          >
            <Col span={7}>
              <InputForm
                title="Logradouro"
                value={doctorData.doctor.address.street}
              />
            </Col>
            <Col span={6}>
              <InputForm
                title="Cidade"
                value={doctorData.doctor.address.city}
              />
            </Col>
            <Col span={5}>
              <InputForm
                title="Estado"
                value={doctorData.doctor.address.state}
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: 20 }}
            gutter={{
              lg: 20, sm: 20, md: 20, xl: 20, xs: 20, xxl: 20,
            }}
          >
            <Col span={3}>
              <InputForm
                title="CEP"
                value={doctorData.doctor.address.zipcode}
              />
            </Col>
            <Col span={4}>
              <InputForm
                title="Número"
                value={doctorData.doctor.address.number}
              />
            </Col>
            <Col span={6}>
              <InputForm
                title="Bairro"
                value={doctorData.doctor.address.neighborhood}
              />
            </Col>
            {doctorData.doctor.address.details && (
              <Col span={5}>
                <InputForm
                  title="Complemento"
                  value={doctorData.doctor.address.details}
                />
              </Col>
            )}
          </Row>
        </DetailsContainer>
      )}
    </>
  );
}
