import { createContext, useMemo, useState } from 'react';
import { requestGetRoleById } from 'services/roles/requestRole';
import { getRolesByIndex } from 'services/roles/requestRoleByIndex';
import { postCreateRole } from 'services/roles/requestCreateRole';
import { patchUpdateRole } from 'services/roles/requestUpdateRole';
import { IRole, ICreateRoleDataRequest } from 'services/roles/types';
import { IRoleContextData, IRoleProviderProps } from './types';

export const RoleContext = createContext({} as IRoleContextData);

export function RoleProvider({ children }: IRoleProviderProps) {
  const [allRoles, setAllRoles] = useState<IRole[]>([]);
  const [countAllRoles, setCountAllRoles] = useState(0);

  const getAllRoles = async (
    pageSize: number,
    page: number,
    searchRoles: string,
  ) => {
    const { data: { data: { data, total } } } = await getRolesByIndex(
      pageSize,
      page,
      searchRoles,
    );

    setAllRoles(data);
    setCountAllRoles(total);
  };

  const getRoleById = async (id: string) => {
    const { data: { data } } = await requestGetRoleById(id);

    return (data);
  };

  const createRole = async (createRoleData: ICreateRoleDataRequest) => {
    const { data } = await postCreateRole(createRoleData);

    return data;
  };

  const updateRole = async (id: string, createRoleData: ICreateRoleDataRequest) => {
    const { data } = await patchUpdateRole(id, createRoleData);

    return data;
  };

  const value = useMemo(() => ({
    allRoles,
    countAllRoles,
    getAllRoles,
    getRoleById,
    createRole,
    updateRole,
  }), [allRoles, countAllRoles]);

  return (
    <RoleContext.Provider
      value={value}
    >
      {children}
    </RoleContext.Provider>
  );
}
