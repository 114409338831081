import React, { useEffect, useState } from "react";
import { useUser } from "hooks/useUsers";
import { Header } from "../../components/Header";
import { SearchBar } from "../../components/SearchBar";
import { Table } from "./Table";
import {
  Container,
  ContainerSearch,
  TitleSearch,
} from "./styles";
import CustomPagination from "components/Pagination";
import { Spin } from "antd";
import { IUserNew } from "services/users/types";

export function User() {
  const { getAllUsersNEW, countAllUsers, tokenNextPage } = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  
  const [allUsers, setAllUsers] = useState<IUserNew[]>([])
  
  const [userSearch, setUserSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [nextPage, setNextPage] = useState<string | null>(null);

  const requestUsers = async () => {
    getAllUsers();
  };

  const getAllUsers = async () => {
    setLoading(true);
    const data = await getAllUsersNEW(userSearch);
    setAllUsers(data.data)
    setLoading(false);
  }

  useEffect(() => {
    getAllUsers()
  }, [userSearch])

  return (
    <Container>
      <Spin spinning={loading}>
      <Header title="Usuários" subtitle="Relação de Usuários Cadastrados" />
      <ContainerSearch>
        <TitleSearch>Usuário</TitleSearch>
        <SearchBar
          placeholder="Pesquise pelo nome ou CPF"
          value={userSearch}
          onchange={setUserSearch}
        />

      </ContainerSearch>
      {allUsers && (
        <>
          <Table
            title="Lista de usuários"
            data={allUsers}
            requestUsers={requestUsers}
          />
          <CustomPagination
            countAllItems={10}
            limit={10}
            tokenNextPage='teste'
            setLimit={setLimit}
            setNextPage={setNextPage}
          />
        </>
      )}
      </Spin>
    </Container>
  );
}
